import { makeAutoObservable } from "mobx";

import { TimeMapEntity, database } from "shared/database";

import { TimeMapsStore } from "./time-maps-store";

export class TimeMapModel {
  get id() {
    return this.timeMap.id;
  }

  get name() {
    return this.timeMap.name;
  }

  get isDefault() {
    return this.timeMap.isDefault;
  }

  get monday() {
    return this.timeMap.monday;
  }

  get tuesday() {
    return this.timeMap.tuesday;
  }

  get wednesday() {
    return this.timeMap.wednesday;
  }

  get thursday() {
    return this.timeMap.thursday;
  }

  get friday() {
    return this.timeMap.friday;
  }

  get saturday() {
    return this.timeMap.saturday;
  }

  get sunday() {
    return this.timeMap.sunday;
  }

  constructor(
    private readonly store: TimeMapsStore,
    private readonly timeMap: TimeMapEntity,
  ) {
    makeAutoObservable(this);
  }

  public *update(changes: Partial<Omit<TimeMapEntity, "id" | "isDefault">>) {
    yield database.timeMaps.update(this.id, changes);

    Object.assign(this.timeMap, changes);
  }

  public *setDefault() {
    if (this.store.defaultTimeMap) {
      yield database.timeMaps.bulkUpdate([
        {
          key: this.store.defaultTimeMap.id,
          changes: { isDefault: false },
        },
        {
          key: this.id,
          changes: { isDefault: true },
        },
      ]);

      this.store.defaultTimeMap.timeMap.isDefault = false;
    } else {
      yield database.timeMaps.update(this.id, { isDefault: true });
    }

    this.timeMap.isDefault = true;
  }
}
