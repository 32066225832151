import { flatMap, groupBy, map, pipe, values } from "remeda";
import { v4 as uuidv4 } from "uuid";

import { TaskModel, TasksStore } from "entities/tasks";

import { database } from "shared/database";

export async function createPriorityGroup() {
  await database.transaction(
    "readwrite",
    [database.priorityGroups],
    async () => {
      const latestPriorityGroup = await database.priorityGroups
        .orderBy("order")
        .reverse()
        .first();

      const groupOrder = Math.max(latestPriorityGroup?.order ?? 0, 0) + 1;

      await database.priorityGroups.add({
        id: uuidv4(),
        name: `Группа ${groupOrder}`,
        order: groupOrder,
      });
    },
  );
}

export async function renamePriorityGroup(groupId: string, name: string) {
  await database.priorityGroups.update(groupId, { name });
}

export async function deletePriorityGroup(groupId: string) {
  await database.transaction(
    "readwrite",
    [database.tasks, database.priorityGroups],
    async () => {
      await database.tasks
        .where("priorityGroupId")
        .equals(groupId)
        .modify({ priorityGroupId: null, priorityInGroup: undefined });

      await database.priorityGroups.delete(groupId);
    },
  );
}

export async function mergePriorityTask(
  tasksStore: TasksStore,
  task: TaskModel,
) {
  if (task.task.priorityInGroup !== undefined) {
    await task.update({
      priorityGroupId: null,
      priorityInGroup: undefined,
    });
    await resetTasksPriorities(tasksStore);
  }
}

export async function prioritizeTask(
  tasksStore: TasksStore,
  task: TaskModel,
  target: TaskModel,
  position: "before" | "after",
) {
  function findClosestParentPriority(task: TaskModel) {
    if (task.task.priorityInGroup !== undefined)
      return task.task.priorityInGroup;
    if (!task.parent) return null;
    return findClosestParentPriority(task.parent);
  }

  const prioritizedTasksCount = tasksStore.tasks.filter(
    (t) => t.task.priorityInGroup !== undefined,
  ).length;

  const targetPriority =
    findClosestParentPriority(target) ?? (prioritizedTasksCount + 1) * 10000;

  await task.update({
    priorityGroupId: target.task.priorityGroupId,
    priorityInGroup:
      position === "before" ? targetPriority - 5000 : targetPriority + 5000,
  });

  await resetTasksPriorities(tasksStore);
}

export async function changeTaskPriorityGroup(
  tasksStore: TasksStore,
  task: TaskModel,
  groupId: string | null,
) {
  await database.transaction(
    "readwrite",
    [database.tasks, database.priorityGroups],
    async () => {
      const tasksInGroup = tasksStore.tasks
        .filter((t) => t.task.priorityGroupId === groupId)
        .sort((a, b) => a.order - b.order);

      await task.update({
        priorityGroupId: groupId,
        priorityInGroup: (tasksInGroup.length + 1) * 10000,
      });

      await resetTasksPriorities(tasksStore);
    },
  );
}

export async function resetTasksPriorities(tasksStore: TasksStore) {
  await database.transaction(
    "readwrite",
    [database.tasks, database.priorityGroups],
    async () => {
      const tasksWithPriority = tasksStore.tasks
        .filter((t) => t.task.priorityInGroup !== undefined)
        .sort(
          (a, b) =>
            (a.task.priorityInGroup ?? 0) - (b.task.priorityInGroup ?? 0),
        );

      await database.tasks.bulkUpdate(
        pipe(
          tasksWithPriority,
          groupBy((t) => t.task.priorityGroupId ?? "default"),
          values(),
          flatMap(
            map((task, index) => ({
              key: task.id,
              changes: {
                priorityInGroup: (index + 1) * 10000,
              },
            })),
          ),
        ),
      );
    },
  );
}
