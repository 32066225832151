import { flowResult } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsPencil, BsTrash } from "react-icons/bs";

import { TimeMapModel, TimeMapsStore } from "entities/time-maps";

import { TimeMapEntity } from "shared/database";
import { useDi } from "shared/di";
import { Button } from "shared/ui/button";

import styles from "./time-maps.module.scss";

import { TimeMapForm } from "./time-map-form";

export const TimeMaps = observer(() => {
  const timeMapsStore = useDi().get(TimeMapsStore);

  const [editingTimeMap, setEditingTimeMap] = useState<TimeMapModel | null>(
    null,
  );
  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = async (
    timeMap: Omit<TimeMapEntity, "id" | "isDefault">,
  ) => {
    await flowResult(timeMapsStore.create(timeMap));

    setIsCreating(false);
  };

  const handleUpdate = async (
    timeMap: TimeMapModel,
    changes: Partial<Omit<TimeMapEntity, "id" | "isDefault">>,
  ) => {
    if (!editingTimeMap) return;

    await flowResult(timeMap.update(changes));

    setEditingTimeMap(null);
  };

  if (isCreating) {
    return (
      <TimeMapForm
        onSubmit={handleCreate}
        onCancel={() => setIsCreating(false)}
      />
    );
  }

  if (editingTimeMap) {
    return (
      <TimeMapForm
        timeMap={editingTimeMap}
        onSubmit={(changes) => handleUpdate(editingTimeMap, changes)}
        onCancel={() => setEditingTimeMap(null)}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button onClick={() => setIsCreating(true)}>
          Создать карту времени
        </Button>
      </div>

      <div className={styles.list}>
        {timeMapsStore.timeMaps.map((timeMap) => (
          <div key={timeMap.id} className={styles.timeMap}>
            <div className={styles.timeMapContent}>
              <div className={styles.timeMapName}>{timeMap.name}</div>
              <div className={styles.timeMapStatus}>
                {timeMap.isDefault && (
                  <span className={styles.defaultLabel}>По умолчанию</span>
                )}
              </div>
            </div>
            <div className={styles.timeMapActions}>
              <Button
                variant="secondary"
                onClick={() => timeMap.setDefault()}
                className={styles.defaultButton}
                title={
                  timeMap.isDefault
                    ? "Сделать не по умолчанию"
                    : "Сделать по умолчанию"
                }
              >
                {timeMap.isDefault ? <AiFillStar /> : <AiOutlineStar />}
              </Button>
              <Button
                variant="secondary"
                onClick={() => setEditingTimeMap(timeMap)}
                title="Редактировать"
              >
                <BsPencil />
              </Button>
              <Button
                variant="danger"
                onClick={() => timeMapsStore.delete(timeMap)}
                title="Удалить"
              >
                <BsTrash />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
