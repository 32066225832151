import { clsx } from "clsx";
import { flowResult } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { BsCheckCircle, BsLightningCharge, BsStopCircle } from "react-icons/bs";

import { Scheduler } from "features/scheduler";

import { ActivitiesStore } from "entities/activities";

import { useDi } from "shared/di";
import { IconButton } from "shared/ui/icon-button";

import styles from "./status-panel.module.scss";

import { AdHocModal } from "./ad-hoc-modal";

interface Props {
  className?: string;
}

export const StatusPanel = observer(function StatusPanel({ className }: Props) {
  const scheduler = useDi().get(Scheduler);
  const activitiesStore = useDi().get(ActivitiesStore);

  const executingActivity = activitiesStore.executingActivity;

  const [isAdHocModalOpen, setIsAdHocModalOpen] = useState(false);
  const [adHocButton, setAdHocButton] = useState<HTMLButtonElement | null>(
    null,
  );

  return (
    <>
      <div className={clsx(styles.status, className)}>
        <div
          className={styles.activityName}
          style={{
            borderColor:
              executingActivity?.type === "focus"
                ? executingActivity?.task.zone?.color?.toHexString()
                : undefined,
          }}
        >
          {executingActivity?.name}
        </div>
        {executingActivity && "remainingDuration" in executingActivity && (
          <span
            className={clsx(styles.remainingTime, {
              [styles.overtime]: executingActivity.isOvertime,
            })}
          >
            {executingActivity.isOvertime
              ? executingActivity.overtimeDuration.toFormat("hh:mm:ss")
              : executingActivity.remainingDuration.toFormat("hh:mm:ss")}
          </span>
        )}
        <div className={styles.actions}>
          <IconButton
            ref={setAdHocButton}
            variant="warning"
            onClick={() => setIsAdHocModalOpen(true)}
            title="Запустить ad-hoc задачу"
            hotkey="Meta+I"
          >
            <BsLightningCharge size={20} />
          </IconButton>
          {executingActivity && "complete" in executingActivity && (
            <IconButton
              variant="primary"
              onClick={async () => {
                await flowResult(executingActivity!.complete());
                await scheduler.schedule();
              }}
              title="Завершить задачу"
            >
              <BsCheckCircle size={20} />
            </IconButton>
          )}
          {executingActivity && "stop" in executingActivity && (
            <IconButton
              variant="danger"
              onClick={async () => {
                await flowResult(executingActivity!.stop());
                await scheduler.schedule();
              }}
              title="Остановить"
            >
              <BsStopCircle size={20} />
            </IconButton>
          )}
        </div>
        {executingActivity && (
          <span className={styles.elapsedTime}>
            {executingActivity.actualDuration.toFormat("hh:mm:ss")}
          </span>
        )}
      </div>
      <AdHocModal
        isOpen={isAdHocModalOpen}
        onClose={() => setIsAdHocModalOpen(false)}
        onSuccess={() => scheduler.schedule()}
        targetElement={adHocButton}
      />
    </>
  );
});
