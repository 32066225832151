import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { clsx } from "clsx";
import { motion } from "framer-motion";
import { hyphenateSync as hyphenateEn } from "hyphen/en-us";
import { hyphenateSync as hyphenateRu } from "hyphen/ru";
import { DateTime } from "luxon";
import { flow, flowResult, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";

import { Scheduler } from "features/scheduler";
import { TaskSessionsPopup } from "features/task-sessions";

import { ActivitiesStore } from "entities/activities";
import { EventModel } from "entities/events";
import { isRecurringTask } from "entities/tasks";
import { ZonesStore } from "entities/zones";

import { getDefaultColor } from "shared/colors";
import { useDi } from "shared/di";
import { roundMinutesToNearestSlot } from "shared/libs/time";
import { ContextMenu, MenuItem } from "shared/ui/context-menu";

import styles from "./event.module.scss";

interface EventProps {
  event: EventModel;
  startOfTheDay: DateTime;
  endOfTheDay: DateTime;
  height: number;
  parallelEvents: number;
  parallelEventIndex: number;
  onShowTaskInOutline?: (taskId: string) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
}

export const Event = observer(function Event({
  startOfTheDay,
  endOfTheDay,
  event,
  height,
  parallelEvents,
  parallelEventIndex,
  onShowTaskInOutline,
  onDragStart,
  onDragEnd,
}: EventProps) {
  const zonesStore = useDi().get(ZonesStore);
  const activitiesStore = useDi().get(ActivitiesStore);
  const scheduler = useDi().get(Scheduler);

  const [expanderEarlierMinutes, setExpanderEarlierMinutes] = useState(0);
  const [expanderLaterMinutes, setExpanderLaterMinutes] = useState(0);
  const [showSessions, setShowSessions] = useState(false);
  const [eventElement, setEventElement] = useState<HTMLDivElement | null>(null);

  const eventStartDateTime = DateTime.max(
    startOfTheDay,
    event.startDateTime,
  ).minus({
    minutes: expanderEarlierMinutes,
  });

  const estimatedDuration = Math.max(
    DateTime.min(endOfTheDay, event.endDateTime)
      .diff(eventStartDateTime)
      .as("minutes") +
      expanderEarlierMinutes +
      expanderLaterMinutes,
    15,
  );

  const eventDay = eventStartDateTime.startOf("day");
  const startMinuteOfTheDay = eventStartDateTime.diff(
    eventDay,
    "minutes",
  ).minutes;
  const endMinuteOfTheDay = startMinuteOfTheDay + estimatedDuration;

  const color = event.task.zoneId
    ? zonesStore.zoneColorById.get(event.task.zoneId)!
    : getDefaultColor();

  useEffect(() => {
    if (!eventElement) return;

    return draggable({
      element: eventElement,
      getInitialData: ({ input }) =>
        runInAction(() => ({
          type: "event",
          id: event.id,
          estimatedDuration,
          previewTopOffset:
            input.clientY - eventElement.getBoundingClientRect().top,
        })),
      onDragStart: () => {
        onDragStart();
      },
      onDrop: () => {
        onDragEnd();
      },
    });
  }, [
    estimatedDuration,
    endMinuteOfTheDay,
    event.id,
    eventElement,
    startMinuteOfTheDay,
  ]);

  return (
    <div
      className={styles.event}
      style={{
        top: `${(startMinuteOfTheDay / 60 / 24) * height}px`,
        left: `${(100.0 * parallelEventIndex) / parallelEvents}%`,
        height: `${(estimatedDuration / 60 / 24) * height}px`,
        width: `${100.0 / parallelEvents}%`,
      }}
    >
      <ContextMenu
        content={
          <>
            <MenuItem
              onClick={() => setShowSessions(true)}
              disabled={!isRecurringTask(event.task.task)}
            >
              Показать сессии
            </MenuItem>
            <MenuItem
              onClick={() =>
                flow(function* () {
                  yield activitiesStore.startFocusActivityOnEvent(event);
                  yield scheduler.schedule();
                })()
              }
            >
              Начать фокус
            </MenuItem>
            {onShowTaskInOutline && (
              <MenuItem onClick={() => onShowTaskInOutline(event.task.id)}>
                Показать в задачах
              </MenuItem>
            )}
          </>
        }
        className={styles.contextMenuWrapper}
      >
        <div
          className={clsx(styles.container, {
            [styles.completed]: event.session.completedAt !== undefined,
            [styles.pinned]: event.isPinned,
          })}
          ref={setEventElement}
          style={{
            backgroundColor: color
              .clone()
              .lighten(20)
              .setAlpha(0.8)
              .toHex8String(),
            color: color.clone().monochromatic(10)[5].toHexString(),
            borderColor: color.toHexString(),
            borderLeft: `4px solid ${color.toHexString()}`,
          }}
        >
          <div className={styles.title}>
            <span className={styles.checkmark}>
              {event.session.completedAt ? (
                <BsCheckSquare
                  size="10px"
                  onClick={(e) => {
                    e.stopPropagation();
                    event.complete(false);
                  }}
                />
              ) : (
                <BsSquare
                  size="10px"
                  onClick={(e) => {
                    e.stopPropagation();
                    event.complete(true);
                  }}
                />
              )}
            </span>
            <span title={event.title}>
              {hyphenateEn(hyphenateRu(event.title))}
            </span>
          </div>
        </div>
        <motion.div
          className={clsx(styles.extender, styles.up)}
          onPan={(_, i) => {
            const offsetMinutes = roundMinutesToNearestSlot(
              (Math.abs(i.offset.y) / height) * 60 * 24,
            );

            setExpanderEarlierMinutes(
              Math.sign(i.offset.y) * offsetMinutes * -1,
            );
          }}
          onPanEnd={async () => {
            await flowResult(event.pin(eventStartDateTime, estimatedDuration));

            setExpanderEarlierMinutes(0);
          }}
        />
        <motion.div
          className={clsx(styles.extender, styles.down)}
          onPan={(_, i) => {
            const offsetMinutes = roundMinutesToNearestSlot(
              (Math.abs(i.offset.y) / height) * 60 * 24,
            );

            setExpanderLaterMinutes(Math.sign(i.offset.y) * offsetMinutes);
          }}
          onPanEnd={async () => {
            await flowResult(event.pin(eventStartDateTime, estimatedDuration));

            setExpanderLaterMinutes(0);
          }}
        />
        <TaskSessionsPopup
          task={event.task}
          targetElement={eventElement}
          onClose={() => setShowSessions(false)}
          isOpen={showSessions}
          highlightedSessionId={event.sessionId}
        />
      </ContextMenu>
    </div>
  );
});
