import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";

import { TaskModel } from "entities/tasks";

import { ContextMenu, MenuItem } from "shared/ui/context-menu";

interface Props {
  task: TaskModel;
  onDelete: () => void;
  children: React.ReactNode;
}

export const TaskContextMenu = observer(function TaskContextMenu({
  task,
  onDelete,
  children,
}: Props) {
  const [, setSearchParams] = useSearchParams();

  return (
    <ContextMenu
      content={
        <>
          <MenuItem
            shortcut="Meta+."
            onClick={() =>
              runInAction(() => {
                task.complete(task.isCompleted ? undefined : new Date());
              })
            }
          >
            {task.isCompleted
              ? "Отметить как незавершенное"
              : "Отметить как завершенное"}
          </MenuItem>
          <MenuItem
            shortcut="Meta+P"
            onClick={() => {
              runInAction(() => {
                if (task.isPaused) {
                  void task.resume();
                } else {
                  void task.pause();
                }
              });
            }}
          >
            {task.isPaused ? "Возобновить" : "Приостановить"}
          </MenuItem>
          {task.isParent && (
            <MenuItem
              onClick={() =>
                runInAction(() => {
                  setSearchParams({ zoomTaskId: task.id.toString() });
                })
              }
            >
              Показать в обзоре
            </MenuItem>
          )}
          {task.isCompletable && (
            <MenuItem onClick={() => task.focus()}>
              Сфокусироваться на задаче
            </MenuItem>
          )}
          <MenuItem onClick={onDelete} shortcut="Meta+Backspace">
            Удалить
          </MenuItem>
        </>
      }
    >
      {children}
    </ContextMenu>
  );
});
