import { DateTime } from "luxon";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import { useMemo } from "react";

import { ActivitiesStore } from "entities/activities";

import { useDi } from "shared/di";

import styles from "./activities.module.scss";

import { Activity } from "./activities/activity";

interface Props {
  startDay: DateTime;
  dayIndex: number;
  height: number;
}

export const Activities = observer(function Activities({
  startDay,
  dayIndex,
  height,
}: Props) {
  const activitiesStore = useDi().get(ActivitiesStore);

  const day = startDay.startOf("day").plus({ day: dayIndex });

  const todayEpoch = computed(() =>
    DateTime.fromMillis(now()).startOf("day").toMillis(),
  ).get();

  const activities =
    day > DateTime.fromMillis(todayEpoch)
      ? []
      : activitiesStore.getActivitiesInRange(
          day.toJSDate(),
          day.endOf("day").toJSDate(),
        );

  const activityComponents = useMemo(
    () =>
      activities.map((activity) => (
        <Activity
          key={activity.id}
          day={day}
          activity={activity}
          height={height}
        />
      )),
    [activities, day, height],
  );

  return <div className={styles.activities}>{activityComponents}</div>;
});
