import clsx from "clsx";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";

import { TaskModel, isRecurringTask } from "entities/tasks";

import { Session } from "shared/database";
import { Button, Popover } from "shared/ui";

import styles from "./task-sessions-popup.module.scss";

interface TaskSessionsPopupProps {
  task: TaskModel;
  targetElement: HTMLElement | null;
  onClose: () => void;
  isOpen: boolean;
  highlightedSessionId?: string;
}

export const TaskSessionsPopup = observer(function TaskSessionsPopup({
  task,
  targetElement,
  onClose,
  isOpen,
  highlightedSessionId,
}: TaskSessionsPopupProps) {
  if (!isRecurringTask(task.task)) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  const now = DateTime.now();
  const endWindow = now.plus({ weeks: 2 }).endOf("day");

  const filteredSessions = task.task.plan.sessions.filter((session) => {
    const sessionStart = DateTime.fromISO(session.startDate);
    return sessionStart <= endWindow;
  });

  function handleSkip(session: Session) {
    void task.skipSession(session);
  }

  return (
    <Popover targetElement={targetElement} onClose={onClose} isOpen={isOpen}>
      <div className={styles.container}>
        <h3 className={styles.title}>Сессии задачи</h3>
        <div className={styles.sessions}>
          {filteredSessions.map((session) => {
            const startDate = DateTime.fromISO(session.startDate);
            const endDate = startDate.plus({ days: session.days - 1 });

            return (
              <div
                key={session.id}
                className={clsx(styles.session, {
                  [styles.highlighted]: session.id === highlightedSessionId,
                })}
              >
                <div className={styles.dates}>
                  <span>
                    {startDate.toFormat("d LLL", { locale: "ru-RU" })}
                  </span>
                  {session.days > 1 && (
                    <>
                      <span className={styles.separator}>/</span>
                      <span>
                        {endDate.toFormat("d LLL", { locale: "ru-RU" })}
                      </span>
                    </>
                  )}
                </div>
                {session.completedAt && (
                  <div className={styles.completed}>Завершено</div>
                )}
                {session.skipped && (
                  <div className={styles.skipped}>Пропущено</div>
                )}
                {!session.completedAt && !session.skipped && (
                  <Button
                    variant="danger"
                    className={styles.skipButton}
                    onClick={() => handleSkip(session)}
                  >
                    Пропустить
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Popover>
  );
});
