import { DateTime, Duration } from "luxon";
import {
  comparer,
  computed,
  makeAutoObservable,
  runInAction,
  toJS,
} from "mobx";
import { RRule } from "rrule";
import invariant from "tiny-invariant";
import { v4 } from "uuid";

import { ActivitiesStore } from "entities/activities";
import { EventsStore } from "entities/events";
import { ZoneModel, ZonesStore } from "entities/zones";

import { RecurringPlan, Session, TaskEntity, database } from "shared/database";

import { TasksStore } from "./tasks-store";

export function isRecurringTask(
  task: TaskEntity,
): task is TaskEntity & { plan: RecurringPlan } {
  return task.plan?.type === "recurring";
}

export class TaskModel {
  public task: TaskEntity;

  constructor(
    private readonly tasksStore: TasksStore,
    private readonly zonesStore: ZonesStore,
    private readonly eventsStore: EventsStore,
    private readonly activitiesStore: ActivitiesStore,
    task: TaskEntity,
  ) {
    makeAutoObservable<TaskModel, "children" | "descendants" | "siblings">(
      this,
      {
        children: computed({ equals: comparer.shallow }),
        descendants: computed({ equals: comparer.shallow }),
        siblings: computed({ equals: comparer.shallow }),
      },
      {
        name: `TaskModel@${task.id}`,
      },
    );

    this.task = task;
    this.tasksStore = tasksStore;
    this.zonesStore = zonesStore;
  }

  public get id() {
    return this.task.id;
  }

  public get title() {
    return this.task.name;
  }

  public get parentId(): string | null {
    return this.task.parentId;
  }

  public get location() {
    return this.task.location;
  }

  private get children(): TaskModel[] {
    return this.tasksStore.getTasksByParentId(this.id);
  }

  public getChildrenByLocation(
    location: "inbox" | "outline" | "journal",
  ): TaskModel[] {
    return this.children.filter((c) => c.location === location);
  }

  public get parent(): TaskModel | null {
    if (this.task.parentId === null) return null;

    const parent = this.tasksStore.taskById.get(this.task.parentId);
    invariant(parent);

    return parent;
  }

  private get descendants(): TaskModel[] {
    return [...this.children, ...this.children.flatMap((c) => c.descendants)];
  }

  public getDescendantsByLocation(
    location: "inbox" | "outline" | "journal",
  ): TaskModel[] {
    return this.descendants.filter((d) => d.location === location);
  }

  private get siblings(): TaskModel[] {
    return this.tasksStore.getTasksByParentId(this.parentId);
  }

  public getSiblingsByLocation(
    location: "inbox" | "outline" | "journal",
  ): TaskModel[] {
    return this.siblings.filter((s) => s.location === location);
  }

  public get zoneId(): string | undefined {
    return this.task.zoneId ?? this.parent?.zoneId;
  }

  public get zone(): ZoneModel | null {
    if (!this.zoneId) return null;

    const zone = this.zonesStore.zones.find((z) => z.id === this.zoneId);
    if (!zone) throw new Error(`Can't find zone with id ${this.zoneId}`);

    return zone;
  }

  public get order(): number {
    return this.task.order;
  }

  public get isCompleted() {
    return (
      this.getIsCompletedForDateTime(DateTime.now()) &&
      this.allChildrenCompleted
    );
  }

  public get isPaused(): boolean {
    const isPaused = this.task.pausedAt !== undefined;
    return isPaused ? true : (this.parent?.isPaused ?? false);
  }

  public get allChildrenCompleted(): boolean {
    return this.children.every((child) => child.isCompleted);
  }

  public get isCompletable(): boolean {
    return !this.isParent || this.allChildrenCompleted;
  }

  public getIsCompletedForDateTime(dateTime: DateTime) {
    if (this.task.location === "journal") return true;

    const session = this.getSessionForDateTime(dateTime);
    switch (this.task.plan?.type) {
      case undefined:
        return this.task.completedAt !== undefined;
      case "flexible":
        invariant(this.task.plan.sessions.length === 1);
        invariant(session);
        return session.completedAt !== undefined;
      case "recurring":
        return session?.completedAt !== undefined;
      default:
        throw new Error("Unknown plan type");
    }
  }

  public get isCollapsed() {
    return this.task.collapsed;
  }

  public get isParent() {
    return this.children.length !== 0;
  }

  public get duration(): Duration {
    if (!this.isParent)
      return Duration.fromObject({ minutes: this.task.duration });

    return this.children.reduce(
      (prev, curr) => prev.plus(curr.duration),
      Duration.fromMillis(0),
    );
  }

  public get effectivePriorityGroupId(): string | null {
    if (this.task.priorityGroupId) return this.task.priorityGroupId;
    if (this.parent) return this.parent.effectivePriorityGroupId;
    return null;
  }

  public get uncompletedSessions() {
    return (
      this.task.plan?.sessions
        .filter((s) => !s.completedAt)
        .map((s) => ({
          task: this,
          ...s,
        })) ?? []
    );
  }

  public async collapse() {
    const children = this.children;
    const taskId = this.task.id;

    return database.transaction("readwrite", database.tasks, async () => {
      await Promise.all(children.map((c) => c.collapse()));

      await database.tasks.update(taskId, { collapsed: true });

      runInAction(() => {
        this.task.collapsed = true;
      });
    });
  }

  public async expand() {
    const taskId = this.task.id;

    await database.tasks.update(taskId, { collapsed: false });

    runInAction(() => {
      this.task.collapsed = false;
    });
  }

  public *complete(completedAt: Date | undefined) {
    if (!this.task.plan) {
      yield this.markTaskAsCompleted(completedAt);
      return;
    }

    if (isRecurringTask(this.task)) {
      const activeSession = this.task.plan.sessions.find(
        (s) =>
          DateTime.now() >= DateTime.fromISO(s.startDate) &&
          DateTime.now() < DateTime.fromISO(s.startDate).plus({ day: s.days }),
      );

      if (!activeSession)
        throw new Error(`Can't find active session for task ${this.task.id}`);

      yield this.completeSession(activeSession, completedAt);
      return;
    }

    if (this.task.plan.type === "flexible") {
      yield this.completeSession(this.task.plan.sessions[0], completedAt);
      return;
    }

    throw new Error("Unknown plan type");
  }

  public *completeSession(
    { id: sessionId }: Session,
    completedAt: Date | undefined,
  ) {
    const session = this.task.plan?.sessions.find((s) => s.id === sessionId);
    if (!session) throw new Error(`Can't find session with id ${sessionId}`);

    yield this.markSessionAsCompleted(session, completedAt);

    if (this.task.plan?.type === "flexible")
      yield this.markTaskAsCompleted(completedAt);
  }

  private async markTaskAsCompleted(completedAt: Date | undefined) {
    if (!this.allChildrenCompleted && completedAt !== undefined) {
      throw new Error(
        "Cannot complete a parent task when children are incomplete",
      );
    }

    await database.tasks.update(this.id, { completedAt });

    runInAction(() => {
      this.task.completedAt = completedAt;
    });
  }

  private async markSessionAsCompleted(
    { id: sessionId }: Session,
    completedAt: Date | undefined,
  ) {
    const session = this.task.plan?.sessions.find((s) => s.id === sessionId);
    if (!session) throw new Error(`Can't find session with id ${sessionId}`);

    session.completedAt = completedAt;

    await database.tasks.update(this.id, { plan: toJS(this.task.plan) });
  }

  public async setPlan(
    repeat:
      | { type: "notPlanned" }
      | { type: "flexible" }
      | {
          type: "recurring";
          rrule: string;
          groupType: RecurringPlan["groupType"];
        },
  ) {
    let plan: TaskEntity["plan"];
    switch (repeat.type) {
      case "notPlanned":
        plan = undefined;
        break;
      case "flexible":
        plan = {
          type: "flexible",
          sessions: [{ id: v4(), type: "flexible", skipped: false }],
        };
        break;
      case "recurring":
        plan = {
          type: "recurring",
          rrule: repeat.rrule,
          groupType: repeat.groupType,
          sessions: [],
        };
        break;
      default:
        throw new Error("Unknown repeat type");
    }

    await database.transaction(
      "readwrite",
      [database.tasks, database.events],
      async () => {
        await this.eventsStore.deleteForTask(this.id);
        await database.tasks.update(this.id, { plan });
        await this.scheduleSessions();
      },
    );

    runInAction(() => {
      this.task.plan = plan;
    });
  }

  public async scheduleSessions() {
    if (!isRecurringTask(this.task)) return;

    const plan = this.task.plan;

    const weekAgo = DateTime.now().minus({ days: 7 });
    const calculatedSessions = plan.sessions.filter((s) => {
      if (s.completedAt) {
        const sessionDate = DateTime.fromISO(s.startDate);
        return sessionDate >= weekAgo;
      }
      return false;
    });

    const rRule = RRule.fromString(plan.rrule.split(";;").join("\n"));
    const today = DateTime.now().startOf("day");

    const sessionsToSchedule = rRule
      .between(today.toJSDate(), today.plus({ days: 60 }).toJSDate(), true)
      .map((date) => {
        const dateTime = DateTime.fromJSDate(date);

        const startDate = dateTime.minus({
          minutes: dateTime.offset,
        });

        if (plan.groupType === "day") return { startDate, range: 1 };
        if (plan.groupType === "week") return { startDate, range: 7 };
        if (plan.groupType === "month")
          return { startDate, range: startDate.daysInMonth! };
        if (plan.groupType === "year")
          return { startDate, range: startDate.daysInYear! };

        throw new Error("Unknown group type");
      });

    for (const sessionToSchedule of sessionsToSchedule) {
      const existingSession = plan.sessions.find(
        (s) =>
          s.startDate === sessionToSchedule.startDate.toISODate() &&
          s.days === sessionToSchedule.range,
      );

      calculatedSessions.push(
        existingSession
          ? existingSession
          : {
              id: v4(),
              type: "timeBound",
              startDate: sessionToSchedule.startDate.toISODate()!,
              days: sessionToSchedule.range,
              skipped: false,
            },
      );
    }

    const updatedPlan = {
      ...plan,
      sessions: calculatedSessions.map((s) => toJS(s)),
    };

    await database.tasks.update(this.id, { plan: updatedPlan });

    runInAction(() => {
      this.task.plan = updatedPlan;
    });
  }

  public *pause() {
    yield database.tasks.update(this.id, { pausedAt: new Date() });

    this.task.pausedAt = new Date();
  }

  public *resume() {
    yield database.tasks.update(this.id, { pausedAt: undefined });

    this.task.pausedAt = undefined;
  }

  public async move(
    newLocation: "inbox" | "outline" | "journal",
    parent: TaskModel | null,
    order: number,
  ) {
    const taskId = this.id;
    const previousParentId = this.parent?.id ?? null;
    const previousLocation = this.location;
    const newParentId = parent?.id ?? null;
    const descendants = this.descendants;
    const store = this.tasksStore;

    if (newParentId === taskId) throw new Error("Can't move task to itself");

    await database.transaction("readwrite", [database.tasks], async () => {
      await this.collapse();

      await database.tasks.update(taskId, {
        parentId: newParentId,
        order,
        location: newLocation,
      });

      if (newLocation !== previousLocation) {
        runInAction(async () => {
          await database.tasks.bulkUpdate(
            descendants
              .filter((d) => d.location !== "journal")
              .map((d) => ({
                key: d.id,
                changes: { location: newLocation },
              })),
          );
        });
      }

      runInAction(() => {
        this.task.parentId = newParentId;
        this.task.order = order;
        this.task.location = newLocation;

        if (newLocation !== previousLocation) {
          descendants
            .filter((d) => d.location !== "journal")
            .forEach((d) => {
              d.task.location = newLocation;
            });
        }
      });

      await store.reorderTaskChildren(newLocation, newParentId, taskId, order);
      await store.reorderTaskChildren(previousLocation, previousParentId);

      if (parent) await parent.expand();
    });
  }

  public *skipSession(session: Session) {
    if (!isRecurringTask(this.task))
      throw new Error(`Task ${this.id} is not a recurring task`);

    const taskId = this.id;
    const sessionId = session.id;
    const plan = toJS(this.task.plan);
    const sessions = plan.sessions.map((s) => ({
      ...s,
      skipped: s.id === sessionId ? true : s.skipped,
    }));

    yield database.transaction(
      "readwrite",
      [database.tasks, database.events],
      async () => {
        await database.events.where("sessionId").equals(sessionId).delete();
        await database.tasks.update(taskId, {
          plan: { ...plan, sessions },
        });
      },
    );

    session.skipped = true;
  }

  public async update(changes: {
    name?: string;
    notes?: string;
    zoneId?: string;
    duration?: number;
    priorityInGroup?: number;
    priorityGroupId?: string | null;
  }) {
    await database.tasks.update(this.id, changes);

    runInAction(() => {
      Object.assign(this.task, changes);
    });
  }

  public *focus() {
    const session = this.getSessionForDateTime(DateTime.now());
    if (!session) throw new Error("Can't focus on task without a session");

    yield this.activitiesStore.startFocusActivityOnTaskSession(this, session);
  }

  private getSessionForDateTime(dateTime: DateTime) {
    if (this.task.location === "journal") return null;

    switch (this.task.plan?.type) {
      case undefined:
        return null;
      case "flexible":
        invariant(this.task.plan.sessions.length === 1);
        return this.task.plan.sessions[0];
      case "recurring":
        return this.task.plan.sessions.find(
          (s) =>
            DateTime.fromISO(s.startDate) <= dateTime &&
            dateTime < DateTime.fromISO(s.startDate).plus({ days: s.days }),
        );
      default:
        throw new Error("Unknown plan type");
    }
  }
}
