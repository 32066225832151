import tinycolor from "tinycolor2";

export function getDefaultColor() {
  return getColor("blue-light");
}

type Color =
  | "blue-light"
  | "purple-light"
  | "red-light"
  | "warning"
  | "success"
  | "purple"
  | "gray-100"
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-700"
  | "gray-800"
  | "red";

export function getColor(color: Color) {
  const colorValue = getComputedStyle(document.documentElement)
    .getPropertyValue(`--color-${color}`)
    .trim();

  if (!colorValue) {
    throw new Error(`Color ${color} not found`);
  }

  return tinycolor(colorValue);
}
