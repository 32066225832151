import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item";
import clsx from "clsx";
import mergeRefs from "merge-refs";
import { computed, runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import invariant from "tiny-invariant";

import { TaskModel, TasksStore } from "entities/tasks";

import { useDi } from "shared/di";
import { useKeyboardNavigation } from "shared/libs/keyboard-navigation";

import styles from "./task-tree.module.scss";

import { Task } from "./task/task";
import { TaskCheckbox } from "./task/task-checkbox";
import { TaskCollapse } from "./task/task-collapse";
import { TaskContextMenu } from "./task/task-context-menu";
import { TaskDetails } from "./task/task-details";
import { TaskNotes } from "./task/task-notes";
import { TaskSettings } from "./task/task-settings";
import { TaskTitle } from "./task/task-title";

import { TaskTreeStore, useTaskDrag } from "../model";

interface TaskTreeProps {
  className?: string;
  location: "inbox" | "outline" | "journal";
  roots: TaskModel[];
  listenForTaskCreation: boolean;
  taskIdToSelect?: string;
  onTaskSelected?: () => void;
}

export const TaskTree = observer(function TaskTree({
  className,
  location,
  roots,
  taskIdToSelect,
  onTaskSelected,
  listenForTaskCreation,
}: TaskTreeProps) {
  const di = useDi();

  const tasksStore = di.get(TasksStore);
  const taskTreeStore = useLocalObservable(() => di.get(TaskTreeStore));

  useEffect(
    () => taskTreeStore.updateTasks(roots, location),
    [roots, location],
  );

  useEffect(
    function selectAndExpandTask() {
      if (!taskIdToSelect) return;

      const taskToSelect = taskTreeStore.findTaskById(taskIdToSelect);
      invariant(taskToSelect, "Task to select not found");

      // Expand all parent tasks
      let currentTask = taskToSelect;
      while (currentTask.parent) {
        if (currentTask.parent.isCollapsed) {
          currentTask.parent.expand();
        }
        currentTask = currentTask.parent;
      }

      taskTreeStore.setSelectedTaskId(taskIdToSelect);
      onTaskSelected?.();
    },
    [taskIdToSelect, onTaskSelected],
  );

  useKeyboardNavigation({
    taskTreeStore,
    enableTaskAddition: listenForTaskCreation,
    onDisableTaskEditing() {
      taskTreeStore.setIsTaskEditing(false);
    },
    async onToggleTaskCompletion() {
      if (
        taskTreeStore.selectedTask &&
        taskTreeStore.selectedTask.isCompletable
      )
        await taskTreeStore.selectedTask.complete(
          taskTreeStore.selectedTask.isCompleted ? undefined : new Date(),
        );
    },
    async onIndent() {
      if (!taskTreeStore.selectedTask) return;

      const parentTaskIndex = taskTreeStore.selectedTaskIndex.slice(0, -1);
      const currentIndex = taskTreeStore.selectedTaskIndex.slice(-1)[0];

      const previousTask = taskTreeStore.tryGetTaskFromIndex([
        ...parentTaskIndex,
        currentIndex - 1,
      ]);
      if (!previousTask) return;

      const previousTaskChildren =
        previousTask.getChildrenByLocation(location).length;
      await taskTreeStore.selectedTask.move(
        location,
        previousTask ?? null,
        previousTaskChildren,
      );

      taskTreeStore.setIsTaskNew(false);
    },
    async onUnindent() {
      if (!taskTreeStore.selectedTask) return;

      const parentParentTaskIndex = taskTreeStore.selectedTaskIndex.slice(
        0,
        -2,
      );
      const parentTaskIndex = taskTreeStore.selectedTaskIndex.slice(-2, -1)[0];

      if (parentTaskIndex === undefined) return;

      const parentParentTask = taskTreeStore.tryGetTaskFromIndex(
        parentParentTaskIndex,
      );
      if (parentParentTask === undefined) return;

      await taskTreeStore.selectedTask.move(
        location,
        parentParentTask ?? null,
        parentTaskIndex + 1,
      );

      taskTreeStore.setIsTaskNew(false);
    },
    onToggleTaskEditing: () => taskTreeStore.toggleIsTaskEditing(),
    async onAddTask() {
      if (location === "journal") return;

      const noSelectedTask = taskTreeStore.selectedTask === null;

      const isExpandedParent =
        !noSelectedTask &&
        !taskTreeStore.selectedTask.isCollapsed &&
        taskTreeStore.selectedTask.getChildrenByLocation(location).length !== 0;

      const parentTask = isExpandedParent
        ? taskTreeStore.selectedTask
        : (taskTreeStore.selectedTask?.parent ?? null);

      const position =
        isExpandedParent || noSelectedTask
          ? 0
          : taskTreeStore.selectedTaskIndex.slice(-1)[0] + 1;

      const task = await tasksStore.createTask(
        location,
        "",
        parentTask?.id ?? null,
        position,
      );

      taskTreeStore.setSelectedTaskId(task.id);
      taskTreeStore.setIsTaskEditing(true);
      taskTreeStore.setIsTaskNew(true);
    },
  });

  const collapseHotkeyRef = useHotkeys<HTMLDivElement>(
    "Left",
    async () => {
      if (taskTreeStore.selectedTask) {
        if (
          taskTreeStore.selectedTask.getChildrenByLocation(location).length ===
            0 ||
          taskTreeStore.selectedTask.isCollapsed
        )
          taskTreeStore.trySelectTaskWithIndex([
            ...taskTreeStore.selectedTaskIndex.slice(0, -1),
          ]);
        else await taskTreeStore.selectedTask.collapse();
      }
    },
    { preventDefault: true },
  );

  const expandHotkeyRef = useHotkeys<HTMLDivElement>(
    "Right",
    async () => {
      if (taskTreeStore.selectedTask) await taskTreeStore.selectedTask.expand();
    },
    { preventDefault: true },
  );

  const pasteHotkeyRef = useHotkeys<HTMLDivElement>(
    ["Meta+V"],
    async () => {
      if (location === "journal") return;

      const clipboardTasks = await navigator.clipboard.readText();

      const tasks = clipboardTasks?.split("\n").map((t) => t.trim());
      if (!tasks || tasks[0] === "") return;

      const parentTask =
        taskTreeStore.selectedTask === null
          ? null
          : taskTreeStore.selectedTask?.parent;
      if (parentTask === undefined) return;

      let taskPosition = taskTreeStore.selectedTaskIndex.slice(-1)[0] + 1;
      for (const taskName of tasks)
        await tasksStore.createTask(
          location,
          taskName,
          parentTask?.id ?? null,
          taskPosition++,
        );
    },
    { preventDefault: true },
  );

  const goUpHotkeyRef = useHotkeys<HTMLDivElement>(
    "Up",
    async () => {
      runInAction(() => {
        const parentTaskIndex = taskTreeStore.selectedTaskIndex.slice(0, -1);
        const childIndex = taskTreeStore.selectedTaskIndex.slice(-1)[0];

        let lastChildInParentIndex = [...parentTaskIndex, childIndex - 1];
        do {
          lastChildInParentIndex = [
            ...lastChildInParentIndex,
            (taskTreeStore
              .tryGetTaskFromIndex(lastChildInParentIndex)
              ?.getChildrenByLocation(location).length ?? 0) - 1,
          ];
        } while (taskTreeStore.trySelectTaskWithIndex(lastChildInParentIndex));
        if (lastChildInParentIndex.length > parentTaskIndex.length + 2) return;

        taskTreeStore.trySelectFirstExistingTaskIndex(
          [...parentTaskIndex, childIndex - 1],
          parentTaskIndex,
        );
      });
    },
    { preventDefault: true },
  );

  const goDownHotkeyRef = useHotkeys<HTMLDivElement>(
    "Down",
    async () => {
      runInAction(() => {
        const parentTaskIndex = taskTreeStore.selectedTaskIndex.slice(0, -1);
        const childIndex = taskTreeStore.selectedTaskIndex.slice(-1)[0];

        if (
          taskTreeStore.trySelectTaskWithIndex([
            ...parentTaskIndex,
            childIndex,
            0,
          ])
        )
          return;
        if (
          taskTreeStore.trySelectTaskWithIndex([
            ...parentTaskIndex,
            childIndex + 1,
          ])
        )
          return;

        while (parentTaskIndex.length > 0)
          if (
            taskTreeStore.trySelectTaskWithIndex([
              ...parentTaskIndex.slice(0, -1),
              parentTaskIndex.splice(-1)[0] + 1,
            ])
          )
            return;
      });
    },
    { preventDefault: true },
  );

  const moveTaskUpHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+Up",
    async () => {
      runInAction(async () => {
        if (!taskTreeStore.selectedTask) return;

        await taskTreeStore.selectedTask.move(
          location,
          taskTreeStore.selectedTask.parent ?? null,
          Math.max(0, taskTreeStore.selectedTaskIndex.slice(-1)[0] - 1),
        );
      });
    },
    { preventDefault: true },
  );

  const moveTaskDownHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+Down",
    async () => {
      runInAction(async () => {
        if (!taskTreeStore.selectedTask) return;

        const currentIndex = taskTreeStore.selectedTaskIndex.slice(-1)[0];

        await taskTreeStore.selectedTask.move(
          location,
          taskTreeStore.selectedTask.parent ?? null,
          Math.min(
            taskTreeStore.selectedTask.getSiblingsByLocation(location).length -
              1,
            currentIndex + 1,
          ),
        );
      });
    },
    { preventDefault: true },
  );

  const flexiblePlanHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+Shift+F",
    function ToggleFlexiblePlan() {
      if (!taskTreeStore.selectedTask) return;

      taskTreeStore.selectedTask.setPlan({
        type:
          taskTreeStore.selectedTask.task.plan?.type === "flexible"
            ? "notPlanned"
            : "flexible",
      });
    },
    { preventDefault: true },
  );

  const pauseHotkeyRef = useHotkeys<HTMLDivElement>(
    "Meta+P",
    () =>
      runInAction(function TogglePause() {
        if (!taskTreeStore.selectedTask) return;

        if (taskTreeStore.selectedTask.isPaused) {
          void taskTreeStore.selectedTask.resume();
        } else {
          void taskTreeStore.selectedTask.pause();
        }
      }),
    { preventDefault: true },
  );

  return (
    <div
      ref={mergeRefs(
        pasteHotkeyRef,
        goUpHotkeyRef,
        goDownHotkeyRef,
        moveTaskUpHotkeyRef,
        moveTaskDownHotkeyRef,
        flexiblePlanHotkeyRef,
        pauseHotkeyRef,
        collapseHotkeyRef,
        expandHotkeyRef,
      )}
      className={clsx(styles.tree, className)}
    >
      {drawTasksForParent(taskTreeStore, taskTreeStore.roots, "", location)}
    </div>
  );
});

function drawTasksForParent(
  taskTreeStore: TaskTreeStore,
  parentTasks: TaskModel[],
  indexPrefix: string,
  location: "inbox" | "outline" | "journal",
) {
  return parentTasks
    .filter((t) => t.location === location)
    .map((task, index) => (
      <TaskComponent
        key={task.id}
        task={task}
        isLast={index === parentTasks.length - 1}
        taskTreeStore={taskTreeStore}
        index={indexPrefix ? `${indexPrefix}-${index}` : `${index}`}
        location={location}
      />
    ));
}

interface TaskComponentProps {
  taskTreeStore: TaskTreeStore;
  task: TaskModel;
  isLast: boolean;
  index: string;
  location: "inbox" | "outline" | "journal";
}

const TaskComponent = observer(function TaskComponent({
  taskTreeStore,
  task,
  isLast,
  index,
  location,
}: TaskComponentProps) {
  const isSelected = computed(
    () => taskTreeStore.selectedTaskId === task.id,
  ).get();

  const isEditing = computed(
    () => taskTreeStore.isTaskEditing && isSelected,
  ).get();

  const { instruction, setTaskElement } = useTaskDrag({
    task,
    index: index.split("-").map(Number),
    isLast,
  });

  return (
    <>
      <TaskContextMenu
        task={task}
        onDelete={() => taskTreeStore.deleteSelectedTask()}
      >
        <Task
          key={task.id}
          task={task}
          level={index.split("-").length - 1}
          className={styles.task}
          isSelected={isSelected}
        >
          <TaskDetails
            ref={setTaskElement}
            className={styles.taskDetails}
            store={taskTreeStore}
            isSelected={isSelected}
            isEditing={isEditing}
            onEditToggle={() => taskTreeStore.toggleIsTaskEditing()}
            onSelect={() => taskTreeStore.setSelectedTaskId(task.id)}
            onDeselect={() => taskTreeStore.setSelectedTaskId(null)}
            isDragging={instruction !== undefined}
            onDelete={() => taskTreeStore.deleteSelectedTask()}
          >
            <TaskCheckbox task={task} />
            <TaskCollapse task={task} />
            <TaskTitle task={task} isEditing={isEditing} />
            <TaskSettings task={task} />
            <TaskNotes
              className={styles.notes}
              task={task}
              isEditing={isEditing}
            />
            {instruction && <DropIndicator instruction={instruction} />}
          </TaskDetails>
        </Task>
      </TaskContextMenu>
      {(!task.isCollapsed || task.allChildrenCompleted) &&
        drawTasksForParent(
          taskTreeStore,
          task.getChildrenByLocation(location),
          index,
          location,
        )}
    </>
  );
});
