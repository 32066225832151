import { makeAutoObservable } from "mobx";
import tinycolor from "tinycolor2";

import { ZoneEntity, database } from "shared/database";

export class ZoneModel {
  public constructor(public zone: ZoneEntity) {
    makeAutoObservable(this);
  }

  public get id() {
    return this.zone.id;
  }

  public get name() {
    return this.zone.name;
  }

  public get color() {
    return tinycolor(this.zone.color);
  }

  public get dayMinutesLimit() {
    return this.zone.dayMinutesLimit;
  }

  public get weekMinutesLimit() {
    return this.zone.weekMinutesLimit;
  }

  public get mondayMinutesLimit() {
    return this.zone.mondayMinutesLimit;
  }

  public get tuesdayMinutesLimit() {
    return this.zone.tuesdayMinutesLimit;
  }

  public get wednesdayMinutesLimit() {
    return this.zone.wednesdayMinutesLimit;
  }

  public get thursdayMinutesLimit() {
    return this.zone.thursdayMinutesLimit;
  }

  public get fridayMinutesLimit() {
    return this.zone.fridayMinutesLimit;
  }

  public get saturdayMinutesLimit() {
    return this.zone.saturdayMinutesLimit;
  }

  public get sundayMinutesLimit() {
    return this.zone.sundayMinutesLimit;
  }

  public *update(changes: Partial<Omit<ZoneEntity, "id">>) {
    yield database.zones.update(this.zone.id, changes);

    this.zone = { ...this.zone, ...changes };
  }
}
