import { observer } from "mobx-react-lite";

import { TaskTree } from "features/task-tree";

import { TasksStore } from "entities/tasks";

import { useDi } from "shared/di";

import styles from "./inbox-panel.module.scss";

export const InboxPanel = observer(function InboxPanel() {
  const tasksStore = useDi().get(TasksStore);

  const roots = tasksStore.getRootsInLocation("inbox");

  return (
    <div className={styles.inbox}>
      <TaskTree location="inbox" roots={roots} listenForTaskCreation />
    </div>
  );
});
