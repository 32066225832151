import IntervalTree from "@flatten-js/interval-tree";
import { inject, injectable } from "inversify";
import { DateTime, Duration } from "luxon";
import { flowResult, makeAutoObservable } from "mobx";

import { EventModel, EventsStore } from "entities/events";
import { Notifications } from "entities/notifications";
import { TaskModel, TasksStore } from "entities/tasks";

import {
  ActivityEntity,
  AdHocActivity,
  AwayActivity,
  FocusActivity,
  Session,
  database,
} from "shared/database";

import {
  ActivityModel,
  AdHocActivityModel,
  AwayActivityModel,
  FocusActivityModel,
} from "./activity-model";
import { scheduleNotifications } from "./notifications";

@injectable()
export class ActivitiesStore {
  public activities: ActivityModel[] = [];

  public get executingActivitiesBySessionId() {
    const result = new Map<string, ActivityModel[]>();

    for (const activity of this.activities) {
      if (activity.type !== "focus") continue;

      if (!result.has(activity.sessionId)) result.set(activity.sessionId, []);

      result.get(activity.sessionId)!.push(activity);
    }

    return result;
  }

  public get executingActivity() {
    return this.activities.find((activity) => activity.isExecuting) ?? null;
  }

  public getTotalDurationBySessionId(sessionId: string) {
    return (
      this.executingActivitiesBySessionId
        .get(sessionId)
        ?.reduce(
          (acc, activity) => acc.plus(activity.actualDuration),
          Duration.fromMillis(0),
        ) ?? Duration.fromMillis(0)
    );
  }

  private get activitiesIntervalTree() {
    const intervalTree = new IntervalTree<ActivityModel>();

    for (const activity of this.activities) {
      intervalTree.insert(
        [
          activity.startDateTime.toMillis(),
          activity.actualEndDateTime?.toMillis() ?? Infinity,
        ],
        activity,
      );
    }

    return intervalTree;
  }

  public getActivitiesInRange(start: Date, end: Date) {
    return this.activitiesIntervalTree.search([
      start.getTime(),
      end.getTime(),
    ]) as ActivityModel[];
  }

  constructor(
    @inject(TasksStore) private readonly tasksStore: TasksStore,
    @inject(EventsStore) private readonly eventsStore: EventsStore,
    @inject(Notifications) private readonly notifications: Notifications,
  ) {
    makeAutoObservable(this);
  }

  public *loadActivities() {
    const activities = (yield database.activities
      .orderBy("startDate")
      .toArray()) as ActivityEntity[];

    this.activities = activities.map((activity) => {
      switch (activity.type) {
        case "focus":
          return new FocusActivityModel(
            this,
            this.tasksStore,
            this.eventsStore,
            this.notifications,
            activity,
          );
        case "away":
          return new AwayActivityModel(
            this,
            this.tasksStore,
            this.notifications,
            activity,
          );
        case "ad-hoc":
          return new AdHocActivityModel(
            this,
            this.tasksStore,
            this.notifications,
            activity,
          );
      }
    });
  }

  public *startAwayActivity() {
    const activity: Omit<AwayActivity, "id"> = {
      startDate: new Date(),
      actualEndDate: null,
      type: "away",
    };

    const executingActivity = this.executingActivity;

    yield database.transaction("readwrite", database.activities, async () => {
      await flowResult(executingActivity?.interrupt());
      await database.activities.add(activity);
    });

    this.activities.push(
      new AwayActivityModel(
        this,
        this.tasksStore,
        this.notifications,
        activity as AwayActivity,
      ),
    );
  }

  public *startFocusActivityOnEvent(event: EventModel) {
    yield this.startFocusActivity(event.task, event.session, event);
    yield event.pin(DateTime.now(), event.duration.as("minutes"));
  }

  public *startFocusActivityOnTaskSession(task: TaskModel, session: Session) {
    yield this.startFocusActivity(task, session);
  }

  private *startFocusActivity(
    task: TaskModel,
    session: Session,
    event?: EventModel,
  ) {
    const startDate = DateTime.now();

    const spentTime = this.getTotalDurationBySessionId(session.id);

    const activity: Omit<FocusActivity, "id"> = {
      type: "focus",
      startDate: startDate.toJSDate(),
      actualEndDate: null,
      sessionId: session.id,
      taskId: task.id,
      eventId: event?.id,
      estimatedEndDate: startDate
        .plus({ minutes: task.duration.minus(spentTime).as("minutes") })
        .toJSDate(),
    };

    const executingActivity = this.executingActivity;

    yield database.transaction("readwrite", database.activities, async () => {
      await flowResult(executingActivity?.interrupt());
      await database.activities.add(activity);
    });

    const focusActivity = new FocusActivityModel(
      this,
      this.tasksStore,
      this.eventsStore,
      this.notifications,
      activity as FocusActivity,
    );

    this.activities.push(focusActivity);
    scheduleNotifications(this.notifications, focusActivity);
  }

  public *startAdHocActivity(reason: string, estimatedMinutes: number) {
    const startDate = DateTime.now();

    const activity = {
      type: "ad-hoc" as const,
      startDate: startDate.toJSDate(),
      actualEndDate: null,
      reason: reason.trim(),
      estimatedEndDate: startDate
        .plus({ minutes: estimatedMinutes })
        .toJSDate(),
    };

    const executingActivity = this.executingActivity;

    yield database.transaction("readwrite", database.activities, async () => {
      await flowResult(executingActivity?.interrupt());
      await database.activities.add(activity);
    });

    const adHocActivity = new AdHocActivityModel(
      this,
      this.tasksStore,
      this.notifications,
      activity as AdHocActivity,
    );

    this.activities.push(adHocActivity);
    scheduleNotifications(this.notifications, adHocActivity);
  }

  public *clearActivitiesWithoutSessions() {
    const taskSessionIds = new Set(
      this.tasksStore.tasks.flatMap(
        (t) => t.task.plan?.sessions.map((s) => s.id) ?? [],
      ),
    );

    const activityIdsToDelete = new Set(
      this.activities
        .filter((a) => a.type === "focus" && !taskSessionIds.has(a.sessionId))
        .map((a) => a.id),
    );

    yield database.activities.bulkDelete(Array.from(activityIdsToDelete));

    this.activities = this.activities.filter(
      (a) => !activityIdsToDelete.has(a.id),
    );
  }
}
