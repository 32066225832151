import { runInAction } from "mobx";
import { useCallback, useEffect } from "react";

import { TaskTreeStore } from "features/task-tree/model/task-tree-store";

interface UseKeyboardNavigationProps {
  taskTreeStore: TaskTreeStore;
  enableTaskAddition: boolean;
  onToggleTaskEditing?: () => void;
  onDisableTaskEditing?: () => void;
  onToggleTaskCompletion: () => void;
  onAddTask?: () => void;
  onIndent?: () => void;
  onUnindent?: () => void;
}

export function useKeyboardNavigation({
  taskTreeStore,
  enableTaskAddition,
  onAddTask,
  onIndent,
  onUnindent,
  onToggleTaskCompletion,
  onToggleTaskEditing,
  onDisableTaskEditing,
}: UseKeyboardNavigationProps) {
  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      runInAction(() => {
        if (e.key === "." && e.metaKey) {
          e.preventDefault();
          onToggleTaskCompletion();
        } else if (
          e.key === " " &&
          enableTaskAddition &&
          !taskTreeStore.isTaskEditing
        ) {
          e.preventDefault();
          onAddTask?.();
        } else if (e.metaKey && (e.key === "[" || e.key === "]")) {
          e.preventDefault();
          if (e.key === "[") onUnindent?.();
          else onIndent?.();
        } else if (e.key === "Enter") {
          e.preventDefault();
          onToggleTaskEditing?.();
        } else if (e.key === "Escape") {
          e.preventDefault();
          onDisableTaskEditing?.();
        }
      });
    },
    [
      taskTreeStore,
      onToggleTaskCompletion,
      onAddTask,
      onUnindent,
      onIndent,
      onToggleTaskEditing,
      onDisableTaskEditing,
    ],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
}
