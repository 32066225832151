import { DateTime } from "luxon";

import { database } from "../database";

database
  .version(61)
  .stores({
    tasks:
      "++id,name,order,parentId,priorityInGroup,priorityGroupId,zoneId,location,plan.type,loggedAt,completedAt",
    events: "id,taskId,sessionId,startDate,completedAt",
    zones: "id,name,color",
    priorityGroups: "id,order",
    calorieIntakes: "id,creationDateTimeUtc",
    timeMaps: "id,name",
    suggestions: "id,taskId,creationDateTimeUtc",
    activities: "++id,type,sessionId,startDate,estimatedEndDate,actualEndDate",
  })
  .upgrade((tx) => {
    tx.table("events")
      .toCollection()
      .modify((event) => {
        event.endDate = DateTime.fromJSDate(event.startDate)
          .plus({
            minutes: event.duration,
          })
          .toJSDate();

        delete event.duration;
      });
  });
