import { LRUCache } from "lru-cache";
import { DateTime } from "luxon";

import { TimeMapEntity } from "shared/database";

import { getWeekdayName } from "./get-weekday-name";

const dayFromDatetimeCache = new LRUCache<string, DateTime>({
  max: 1000,
});

export function getDayFromDatetime(timeMap: TimeMapEntity, dateTime: DateTime) {
  const cacheKey = `${timeMap.id}-${dateTime.toISO()}`;
  if (dayFromDatetimeCache.has(cacheKey))
    return dayFromDatetimeCache.get(cacheKey)!;

  const yesterdayTimeSlot = timeMap[getWeekdayName(dateTime.minus({ day: 1 }))];

  const isMultipleDays =
    yesterdayTimeSlot.endTimeSlot < yesterdayTimeSlot.startTimeSlot;

  const isDateTimeInYesterdayTimeSlot =
    dateTime <
    dateTime
      .startOf("day")
      .plus({ minutes: yesterdayTimeSlot.endTimeSlot * 15 });

  const day =
    isMultipleDays && isDateTimeInYesterdayTimeSlot
      ? dateTime.minus({ day: 1 }).startOf("day")
      : dateTime.startOf("day");

  dayFromDatetimeCache.set(cacheKey, day);

  return day;
}
