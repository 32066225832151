import { DateTime } from "luxon";

import styles from "./event-preview.module.scss";

interface EventPreviewProps {
  startDateTime: DateTime;
  duration: number;
  height: number;
}

export function EventPreview({
  startDateTime,
  duration,
  height,
}: EventPreviewProps) {
  const day = startDateTime.startOf("day");
  const startMinuteOfTheDay = startDateTime.diff(day, "minutes").minutes;
  const endMinuteOfTheDay = startMinuteOfTheDay + duration;

  return (
    <div
      className={styles.eventPreview}
      style={{
        top: `${(startMinuteOfTheDay / 60 / 24) * height}px`,
        height: `${((endMinuteOfTheDay - startMinuteOfTheDay) / 60 / 24) * height}px`,
      }}
    >
      <div className={styles.container}>
        <div className={styles.duration}>
          {day
            .plus({ minute: startMinuteOfTheDay })
            .toLocaleString(DateTime.TIME_24_SIMPLE)}
          -
          {day
            .plus({ minute: endMinuteOfTheDay })
            .toLocaleString(DateTime.TIME_24_SIMPLE)}
        </div>
      </div>
    </div>
  );
}
