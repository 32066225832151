import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { forwardRef, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./date-picker.module.scss";

interface Props {
  label: string;
  value: DateTime | null;
  onChange: (value: DateTime) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
}

export const DatePicker = forwardRef<HTMLInputElement, Props>(
  ({ autoFocus, label, value, onChange, onBlur }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    function formatDate(date: DateTime | null): string {
      if (!date) return "";
      return date.toFormat("dd.MM.yyyy");
    }

    function handleInputClick() {
      setIsOpen(true);
    }

    function handleDateSelect(date: DateTime) {
      onChange(date);
      setIsOpen(false);
    }

    function handleClose() {
      setIsOpen(false);
      if (onBlur) onBlur();
    }

    const { refs, floatingStyles, context } = useFloating({
      elements: {
        reference: inputRef.current,
      },
      open: isOpen,
      onOpenChange: (open) => {
        if (!open) handleClose();
      },
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(4),
        shift({ padding: 8 }),
        flip({ padding: 8 }),
        size({
          apply({ availableHeight, elements }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight - 8}px`,
            });
          },
          padding: 8,
        }),
      ],
    });

    const dismiss = useDismiss(context);
    const { getFloatingProps } = useInteractions([dismiss]);

    return (
      <div className={styles.datePicker} ref={inputRef}>
        <div className={styles.input}>
          <input
            ref={ref}
            tabIndex={0}
            className={styles.control}
            value={formatDate(value)}
            onClick={handleInputClick}
            placeholder=" "
            readOnly
            autoFocus={autoFocus}
          />
          <label className={styles.label}>{label}</label>
        </div>

        {isOpen &&
          createPortal(
            <div
              ref={refs.setFloating}
              className={styles.floating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <motion.div
                className={styles.calendar}
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.7 }}
                transition={{ duration: 0.2 }}
              >
                <Calendar selectedDate={value} onSelect={handleDateSelect} />
              </motion.div>
            </div>,
            document.body,
          )}
      </div>
    );
  },
);

interface CalendarProps {
  selectedDate: DateTime | null;
  onSelect: (date: DateTime) => void;
}

function Calendar({ selectedDate, onSelect }: CalendarProps) {
  const [currentMonth, setCurrentMonth] = useState(
    selectedDate
      ? selectedDate.startOf("month")
      : DateTime.now().startOf("month"),
  );

  const month = currentMonth.month - 1; // Luxon months are 1-indexed
  const year = currentMonth.year;

  function getDaysInMonth(year: number, month: number): number {
    return DateTime.local(year, month + 1).daysInMonth || 30;
  }

  function getFirstDayOfMonth(year: number, month: number): number {
    // Get weekday where Monday is 1 and Sunday is 7
    const weekday = DateTime.local(year, month + 1, 1).weekday;
    return weekday;
  }

  function handlePrevMonth() {
    setCurrentMonth(currentMonth.minus({ months: 1 }));
  }

  function handleNextMonth() {
    setCurrentMonth(currentMonth.plus({ months: 1 }));
  }

  function isToday(date: DateTime): boolean {
    const today = DateTime.now().startOf("day");
    return date.hasSame(today, "day");
  }

  function isSelected(date: DateTime): boolean {
    if (!selectedDate) return false;
    return date.hasSame(selectedDate, "day");
  }

  const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  const daysInMonth = getDaysInMonth(year, month);
  const firstDay = getFirstDayOfMonth(year, month);

  // Generate calendar days
  const days = [];

  // Add empty cells for days before the first day of the month
  for (let i = 1; i < firstDay; i++) {
    days.push(<div key={`empty-${i}`} className={styles.day}></div>);
  }

  // Add days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    const date = DateTime.local(year, month + 1, i);
    const isCurrentDay = isToday(date);
    const isSelectedDay = isSelected(date);

    days.push(
      <div
        key={i}
        className={`${styles.day} ${isCurrentDay ? styles.today : ""} ${
          isSelectedDay ? styles.selected : ""
        }`}
        onClick={() => onSelect(date)}
      >
        {i}
      </div>,
    );
  }

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.header}>
        <button className={styles.navButton} onClick={handlePrevMonth}>
          &lt;
        </button>
        <div className={styles.monthYear}>
          {monthNames[month]} {year}
        </div>
        <button className={styles.navButton} onClick={handleNextMonth}>
          &gt;
        </button>
      </div>

      <div className={styles.weekDays}>
        {weekDays.map((day) => (
          <div key={day} className={styles.weekDay}>
            {day}
          </div>
        ))}
      </div>

      <div className={styles.days}>{days}</div>
    </div>
  );
}
