import React, { useState } from "react";
import { BiCopy, BiPaste } from "react-icons/bi";

import { TimeMapModel } from "entities/time-maps";

import { TimeMapEntity } from "shared/database";
import { Button } from "shared/ui/button";
import { TextInput } from "shared/ui/text-input";

import styles from "./time-map-form.module.scss";

import {
  generateTimeOptions,
  timeSlotToTimeString,
  timeStringToTimeSlot,
} from "../lib/time-slots";

interface Props {
  timeMap?: TimeMapModel;
  onSubmit: (timeMap: Omit<TimeMapEntity, "id" | "isDefault">) => void;
  onCancel: () => void;
}

interface TimeSlot {
  startTimeSlot: number;
  endTimeSlot: number;
}

const weekDays = [
  { key: "monday", label: "Понедельник" },
  { key: "tuesday", label: "Вторник" },
  { key: "wednesday", label: "Среда" },
  { key: "thursday", label: "Четверг" },
  { key: "friday", label: "Пятница" },
  { key: "saturday", label: "Суббота" },
  { key: "sunday", label: "Воскресенье" },
] as const;

const defaultTimeSlots = {
  startTimeSlot: 32, // 8:00
  endTimeSlot: 72, // 18:00
};

export function TimeMapForm({ timeMap, onSubmit, onCancel }: Props) {
  const [name, setName] = useState(timeMap?.name ?? "");
  const [timeSlots, setTimeSlots] = useState(() => {
    if (timeMap) {
      return Object.fromEntries(
        weekDays.map(({ key }) => [
          key,
          {
            startTimeSlot: timeMap[key].startTimeSlot,
            endTimeSlot: timeMap[key].endTimeSlot,
          },
        ]),
      );
    }

    return Object.fromEntries(
      weekDays.map(({ key }) => [key, { ...defaultTimeSlots }]),
    );
  });
  const [copiedTimeSlot, setCopiedTimeSlot] = useState<TimeSlot | null>(null);

  const handleCopyTimeSlot = (key: string) => {
    setCopiedTimeSlot(timeSlots[key]);
  };

  const handlePasteTimeSlot = (key: string) => {
    if (!copiedTimeSlot) return;

    setTimeSlots((prev) => ({
      ...prev,
      [key]: { ...copiedTimeSlot },
    }));
  };

  const timeOptions = generateTimeOptions();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name,
      monday: timeSlots.monday,
      tuesday: timeSlots.tuesday,
      wednesday: timeSlots.wednesday,
      thursday: timeSlots.thursday,
      friday: timeSlots.friday,
      saturday: timeSlots.saturday,
      sunday: timeSlots.sunday,
    });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formHeader}>
        {timeMap ? "Редактирование карты времени" : "Создание карты времени"}
      </div>

      <div className={styles.formField}>
        <TextInput label="Название" value={name} onChange={setName} />
      </div>

      <div className={styles.formDays}>
        <div className={styles.formDaysHeader}>Расписание по дням</div>
        {weekDays.map(({ key, label }) => (
          <div key={key} className={styles.formDay}>
            <div className={styles.formDayName}>{label}</div>
            <div className={styles.formDayTimes}>
              <div className={styles.timeSelects}>
                <select
                  className={styles.select}
                  value={timeSlotToTimeString(timeSlots[key].startTimeSlot)}
                  onChange={(e) =>
                    setTimeSlots((prev) => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        startTimeSlot: timeStringToTimeSlot(e.target.value),
                      },
                    }))
                  }
                >
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <span>—</span>
                <select
                  className={styles.select}
                  value={timeSlotToTimeString(timeSlots[key].endTimeSlot)}
                  onChange={(e) =>
                    setTimeSlots((prev) => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        endTimeSlot: timeStringToTimeSlot(e.target.value),
                      },
                    }))
                  }
                >
                  {timeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.timeActions}>
                <Button
                  type="button"
                  variant="secondary"
                  className={styles.copyButton}
                  onClick={() => handleCopyTimeSlot(key)}
                  title="Копировать время"
                >
                  <BiCopy />
                </Button>
                {copiedTimeSlot && (
                  <Button
                    type="button"
                    variant="secondary"
                    className={styles.pasteButton}
                    onClick={() => handlePasteTimeSlot(key)}
                    title="Вставить время"
                  >
                    <BiPaste />
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.formActions}>
        <Button type="button" variant="secondary" onClick={onCancel}>
          Отмена
        </Button>
        <Button type="submit">{timeMap ? "Сохранить" : "Создать"}</Button>
      </div>
    </form>
  );
}
