import { DateTime } from "luxon";
import { makeAutoObservable } from "mobx";
import { now } from "mobx-utils";
import { v4 } from "uuid";

export interface SerializedNotificationModel {
  id: string;
  title: string;
  message: string;
  notifyAfter: string;
}

export class NotificationModel {
  constructor(
    public readonly title: string,
    public readonly message: string,
    public readonly notifyAfter: DateTime,
    public readonly id: string = v4(),
  ) {
    makeAutoObservable<NotificationModel>(this, {
      shouldNotify: false,
    });
  }

  public get shouldNotify(): boolean {
    return DateTime.fromMillis(now()) >= this.notifyAfter;
  }

  public serialize(): SerializedNotificationModel {
    return {
      id: this.id,
      title: this.title,
      message: this.message,
      notifyAfter: this.notifyAfter.toISO()!,
    };
  }

  public static deserialize(
    data: SerializedNotificationModel,
  ): NotificationModel {
    return new NotificationModel(
      data.title,
      data.message,
      DateTime.fromISO(data.notifyAfter),
      data.id,
    );
  }
}
