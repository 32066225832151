import { DateTime } from "luxon";
import { CSSProperties } from "react";

import styles from "./week-view.module.scss";

import { Schedule } from "./schedule";

import { DAY_HEADER_HEIGHT } from "../../model";

interface WeekViewProps {
  startDay: DateTime;
  days: number;
  mode: "events" | "activities";
  onShowTaskInOutline?: (taskId: string) => void;
}

export function WeekView({
  startDay,
  days,
  mode,
  onShowTaskInOutline,
}: WeekViewProps) {
  return (
    <div
      className={styles.week}
      style={{ "--weekday-height": `${DAY_HEADER_HEIGHT}px` } as CSSProperties}
    >
      <Schedule
        startDay={startDay}
        days={days}
        mode={mode}
        onShowTaskInOutline={onShowTaskInOutline}
      />
    </div>
  );
}
