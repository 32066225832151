import {
  Instruction,
  attachInstruction,
  extractInstruction,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { $rootTextContent } from "@lexical/text";
import { clsx } from "clsx";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import { database } from "shared/database";
import { OnExitPlugin, PreventEnterPlugin } from "shared/libs/lexical";

import styles from "./group.module.scss";

import { useGroupCollapse } from "../model/use-group-collapse";

interface Props {
  id: string | null;
  name: string;
  order: number;
  onRename?: (name: string) => void;
  onDelete?: () => void;
  children: React.ReactNode;
}

export function Group({
  id,
  name,
  order,
  onRename,
  onDelete,
  children,
}: Props) {
  const [groupElement, setGroupElement] = useState<HTMLDivElement | null>(null);
  const [groupTitleElement, setGroupHeaderElement] =
    useState<HTMLDivElement | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [instruction, setInstruction] = useState<Instruction | null>(null);

  const { isCollapsed, toggleCollapse } = useGroupCollapse(id);

  useEffect(() => {
    if (!groupTitleElement || !groupElement) return;

    return combine(
      draggable({
        element: groupTitleElement,
        canDrag: () => id !== null,
        getInitialData: () => ({
          type: "group",
          groupId: id,
          order,
        }),
        async onDrop({ location }) {
          if (!id) return;

          const target = location.current.dropTargets[0];
          if (!target) return;

          if (target.data.type === "group") {
            const targetOrder = target.data.order as number;

            const targetId = target.data.groupId;

            if (targetId === id) return;

            await database.transaction(
              "readwrite",
              [database.priorityGroups],
              async () => {
                const instruction = extractInstruction(target.data);

                if (instruction?.type === "reorder-above") {
                  await database.priorityGroups.update(id, {
                    order: targetOrder - 0.5,
                  });
                } else if (instruction?.type === "reorder-below") {
                  await database.priorityGroups.update(id, {
                    order: targetOrder + 0.5,
                  });
                }

                const negativeOrderGroupsCount = await database.priorityGroups
                  .where("order")
                  .below(0)
                  .count();

                let startingOrder = negativeOrderGroupsCount * -1;
                await database.priorityGroups
                  .orderBy("order")
                  .modify((group) => {
                    if (startingOrder === 0) startingOrder = 1;

                    group.order = startingOrder++;
                  });
              },
            );
          }
        },
      }),
      dropTargetForElements({
        element: groupTitleElement,
        canDrop: ({ source }) =>
          source.data.type === "task" || source.data.type === "group",
        getIsSticky: () => true,
        getData: ({ input, element, source }) =>
          attachInstruction(
            {
              type: "group",
              groupId: id,
              sourceType: source.data.type,
              targetType: "group",
              order,
            },
            {
              input,
              element,
              currentLevel: 0,
              indentPerLevel: 20,
              mode: "standard",
              block:
                source.data.type === "task"
                  ? ["reorder-above", "reorder-below"]
                  : ["make-child"],
            },
          ),
        onDrag: ({ self }) => {
          const extractedInstruction = extractInstruction(self.data);
          setInstruction(extractedInstruction);
        },
        onDragEnter: ({ self }) => {
          const extractedInstruction = extractInstruction(self.data);
          setInstruction(extractedInstruction);
        },
        onDragLeave: () => setInstruction(null),
        onDrop: () => {
          setInstruction(null);
        },
      }),
    );
  }, [groupTitleElement, groupElement, id, order]);

  return (
    <div ref={setGroupElement} className={styles.group}>
      <div
        ref={setGroupHeaderElement}
        className={styles.header}
        onClick={(e) => {
          if (e.metaKey && onRename) setIsEditing(true);
          else toggleCollapse();
        }}
      >
        {isCollapsed ? (
          <IoIosArrowForward className={styles.arrow} />
        ) : (
          <IoIosArrowDown className={styles.arrow} />
        )}
        <div className={clsx(styles.title, { [styles.isEmpty]: !name })}>
          {isEditing ? (
            <LexicalComposer
              initialConfig={{
                namespace: "Group title",
                onError: console.error,
                editorState: () => {
                  const paragraph = $createParagraphNode();
                  paragraph.append($createTextNode(name));
                  $getRoot().append(paragraph);
                  $getRoot().selectEnd();
                },
              }}
            >
              <PlainTextPlugin
                contentEditable={
                  <ContentEditable
                    className={styles.content}
                    onBlur={() => setIsEditing(false)}
                  />
                }
                placeholder={
                  <div className={styles.placeholder}>Название группы</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <OnExitPlugin onExit={() => setIsEditing(false)} />
              <PreventEnterPlugin />
              <OnChangePlugin
                onChange={(editorState) => {
                  editorState.read(() => {
                    onRename?.($rootTextContent());
                  });
                }}
              />
              <HistoryPlugin />
            </LexicalComposer>
          ) : name ? (
            name
          ) : (
            "Группа без названия"
          )}
        </div>
        <div className={styles.trashCounter}>
          <span className={styles.counter}>
            {React.Children.count(children)}
          </span>
          {onDelete && (
            <FaTrash
              className={styles.trash}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            />
          )}
        </div>
      </div>
      {!isCollapsed && <div className={styles.tasks}>{children}</div>}
      {instruction && <DropIndicator instruction={instruction} />}
    </div>
  );
}
