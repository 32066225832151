import clsx from "clsx";
import mergeRefs from "merge-refs";
import { forwardRef, useEffect, useRef, useState } from "react";
import { IoChevronDownOutline } from "react-icons/io5";

import styles from "./dropdown.module.scss";

interface Option {
  value: string;
  label: string;
}

interface Props {
  label: string;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
}

export const Dropdown = forwardRef<HTMLDivElement, Props>(
  ({ autoFocus, label, value, options, onChange, onBlur }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const selectedOption = options.find((option) => option.value === value);

    function handleOptionClick(optionValue: string) {
      onChange(optionValue);
      setIsOpen(false);
    }

    function handleBlur(e: React.FocusEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.relatedTarget as Node)
      ) {
        setIsOpen(false);
        onBlur?.();
      }
    }

    function handleKeyDown(e: React.KeyboardEvent) {
      if (e.key === "Escape") {
        setIsOpen(false);
      } else if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        setIsOpen((prev) => !prev);
      } else if (e.key === "ArrowDown" && isOpen && options.length > 0) {
        e.preventDefault();
        const currentIndex = options.findIndex(
          (option) => option.value === value,
        );
        const nextIndex = (currentIndex + 1) % options.length;
        onChange(options[nextIndex].value);
      } else if (e.key === "ArrowUp" && isOpen && options.length > 0) {
        e.preventDefault();
        const currentIndex = options.findIndex(
          (option) => option.value === value,
        );
        const prevIndex = (currentIndex - 1 + options.length) % options.length;
        onChange(options[prevIndex].value);
      }
    }

    useEffect(() => {
      if (autoFocus && dropdownRef.current) {
        dropdownRef.current.focus();
      }
    }, [autoFocus]);

    return (
      <div
        ref={mergeRefs(ref, dropdownRef)}
        className={styles.dropdown}
        tabIndex={0}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      >
        <div
          className={styles.control}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <span
            className={clsx(styles.value, {
              [styles.placeholder]: !selectedOption,
            })}
          >
            {selectedOption?.label || " "}
          </span>
          <label className={styles.label}>{label}</label>
          <div className={styles.arrow}>
            <IoChevronDownOutline
              size={16}
              style={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
          </div>
        </div>
        {isOpen && (
          <div className={styles.options}>
            {options.map((option) => (
              <div
                key={option.value}
                className={clsx(styles.option, {
                  [styles.selected]: option.value === value,
                })}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);
