import { isObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { TaskTree } from "features/task-tree";

import { TaskModel, TasksStore } from "entities/tasks";

import { useDi } from "shared/di";

import styles from "./outline.module.scss";

import { TaskSearch } from "./task-search";

function getBreadcrumbs(task: TaskModel | null | undefined): TaskModel[] {
  if (!task) return [];
  const breadcrumbs = getBreadcrumbs(task.parent);
  return [...breadcrumbs, task];
}

interface OutlineProps {
  taskIdToSelect?: string;
  onTaskSelected?: () => void;
}

export const Outline = observer(function Outline({
  taskIdToSelect,
  onTaskSelected,
}: OutlineProps) {
  const tasksStore = useDi().get(TasksStore);

  const [searchParams, setSearchParams] = useSearchParams();
  const zoomTaskId = searchParams.get("zoomTaskId");

  const [searchQuery, setSearchQuery] = useState("");

  const inboxRoots = tasksStore.getRootsInLocation("inbox");
  const outlineRoots = tasksStore.getRootsInLocation("outline");

  const filteredOutlineRoots = useMemo(
    () =>
      outlineRoots.filter((root) => {
        if (
          searchQuery &&
          ![root, ...root.getDescendantsByLocation("outline")].some((t) =>
            hasSearchQuery(t, searchQuery),
          )
        )
          return false;

        if (zoomTaskId !== null) return root.task.parentId === zoomTaskId;

        return root.task.parentId === null;
      }),
    [searchQuery, zoomTaskId, outlineRoots],
  );

  const zoomedTask = zoomTaskId ? tasksStore.taskById.get(zoomTaskId) : null;
  const breadcrumbs = getBreadcrumbs(zoomedTask);

  return (
    <div className={styles.outline}>
      <div className={styles.header}>
        {breadcrumbs.length > 0 && (
          <div className={styles.breadcrumbs}>
            <span className={styles.crumb} onClick={() => setSearchParams({})}>
              🏠
            </span>
            {breadcrumbs.map((task, index) => (
              <React.Fragment key={task.id}>
                <span className={styles.separator}>/</span>
                <span
                  className={styles.crumb}
                  onClick={() =>
                    index === breadcrumbs.length - 1
                      ? undefined
                      : setSearchParams({ zoomTaskId: task.id.toString() })
                  }
                >
                  {task.title}
                </span>
              </React.Fragment>
            ))}
          </div>
        )}
        <TaskSearch value={searchQuery} onValueChange={setSearchQuery} />
      </div>
      {inboxRoots.length > 0 && (
        <>
          <TaskTree
            location="inbox"
            roots={inboxRoots}
            listenForTaskCreation={false}
          />
          <div className={styles.delimiter} />
        </>
      )}
      <TaskTree
        key={`${zoomTaskId}-${searchQuery}`}
        location="outline"
        roots={filteredOutlineRoots}
        listenForTaskCreation
        taskIdToSelect={taskIdToSelect}
        onTaskSelected={onTaskSelected}
      />
    </div>
  );
});

function hasSearchQuery(task: TaskModel, searchQuery: string) {
  return (
    task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.task.notes?.toLowerCase().includes(searchQuery.toLowerCase())
  );
}
