import { LayoutGroup } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useHotkeys } from "react-hotkeys-hook";

import { Group } from "features/priorities/ui/group";

import { TaskModel, TasksStore } from "entities/tasks";

import { PriorityGroupEntity } from "shared/database";
import { useDi } from "shared/di";
import { Button } from "shared/ui";

import styles from "./priorities.module.scss";

import { Task } from "./task";

import {
  changeTaskPriorityGroup,
  createPriorityGroup,
  deletePriorityGroup,
  prioritizeTask,
  renamePriorityGroup,
} from "../model";
import { usePriorityGroups } from "../model/use-priority-groups";
import { usePriorityTasks } from "../model/use-priority-tasks";

export const Priorities = observer(function Priorities() {
  const tasksStore = useDi().get(TasksStore);

  const { expandedPrioritizedTasks, splitPriorityTask, mergePriorityTask } =
    usePriorityTasks();

  const priorityGroups = usePriorityGroups();

  useHotkeys(
    "Meta+N",
    async () => {
      await createPriorityGroup();
    },
    { preventDefault: true },
  );

  if (!priorityGroups) return null;

  return (
    <LayoutGroup>
      <div className={styles.priorities}>
        {priorityGroups
          .filter((pg) => pg.order < 0)
          .map((pg) => createGroupComponent(pg))}
        <Group key="default" id={null} name="Стандартная" order={0}>
          {expandedPrioritizedTasks
            .filter((t) => !t.effectivePriorityGroupId)
            .map(generateTask)}
        </Group>
        {priorityGroups
          .filter((pg) => pg.order > 0)
          .map((pg) => createGroupComponent(pg))}
        <Button
          className={styles.addGroupButton}
          variant="secondary"
          onClick={() => createPriorityGroup()}
        >
          Добавить группу
        </Button>
      </div>
    </LayoutGroup>
  );

  function createGroupComponent(pg: PriorityGroupEntity) {
    return (
      <Group
        key={pg.id}
        id={pg.id}
        name={pg.name}
        order={pg.order}
        onRename={async (name) => await renamePriorityGroup(pg.id, name)}
        onDelete={async () => await deletePriorityGroup(pg.id)}
      >
        {expandedPrioritizedTasks
          .filter((t) => t.effectivePriorityGroupId === pg.id)
          .map(generateTask)}
      </Group>
    );
  }

  function generateTask(task: TaskModel) {
    return (
      <Task
        key={task.id}
        task={task}
        onSplit={() => splitPriorityTask(task.id)}
        onMerge={() => {
          mergePriorityTask(task);
        }}
        onPrioritize={(target, position) =>
          prioritizeTask(tasksStore, task, target, position)
        }
        onGroupChange={(groupId) =>
          changeTaskPriorityGroup(tasksStore, task, groupId)
        }
      />
    );
  }
});
