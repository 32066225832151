import React, { ReactNode, useEffect } from "react";

import styles from "./tags.module.scss";

import { DynamicTag, StaticTag } from "./tag";

import { TagsProvider, useTagsContext } from "../model";

interface TagsProps<T> {
  value: T;
  onChange: (value: T) => void;
  onAdd?: () => void;
  children: ReactNode;
}

const TagsComponent = <T,>({
  value,
  onChange,
  onAdd,
  children,
}: TagsProps<T>) => {
  const { value: storeValue, setValue } = useTagsContext<T>();

  useEffect(() => {
    setValue(value as T);
  }, [value, setValue]);

  useEffect(() => {
    if (storeValue !== value && storeValue !== null) {
      onChange(storeValue);
    }
  }, [storeValue, onChange, value]);

  return (
    <div className={styles.buttons}>
      {children}
      {onAdd && <StaticTag onClick={onAdd}>+</StaticTag>}
    </div>
  );
};

const Tags = <T,>(props: TagsProps<T>) => {
  return (
    <TagsProvider initialValue={props.value}>
      <TagsComponent {...props} />
    </TagsProvider>
  );
};

export default Object.assign(Tags, {
  Tag: DynamicTag,
});
