import { database } from "../database";

database
  .version(76)
  .stores({
    tasks:
      "++id,name,order,parentId,priorityInGroup,priorityGroupId,zoneId,location,plan.type,loggedAt,completedAt",
    events: "id,taskId,sessionId,startDate,completedAt",
    zones: "id,name,color",
    priorityGroups: "id,order",
    calorieIntakes: "id,creationDateTimeUtc",
    timeMaps: "id,name",
    suggestions: "id,taskId,creationDateTimeUtc",
    activities: "++id,type,sessionId,startDate,estimatedEndDate,actualEndDate",
  })
  .upgrade(async (tx) => {
    await tx
      .table("tasks")
      .toCollection()
      .filter((t) => t.parentId === t.id)
      .modify((t) => {
        t.parentId = null;
        t.order = 0;
      });
  });
