import { injectable } from "inversify";
import ls from "localstorage-slim";
import { makeAutoObservable, runInAction } from "mobx";

import {
  NotificationModel,
  SerializedNotificationModel,
} from "./notification-model";

@injectable()
export class Notifications {
  private notifications: NotificationModel[] = [];

  private get areNotificationsSupported() {
    return typeof window !== "undefined" && "Notification" in window;
  }

  private get isPermissionGranted() {
    return Notification.permission === "granted";
  }

  constructor() {
    makeAutoObservable<
      Notifications,
      "areNotificationsSupported" | "isPermissionGranted"
    >(this, {
      areNotificationsSupported: false,
      isPermissionGranted: false,
    });

    this.notifications = (
      ls.get<SerializedNotificationModel[]>("notifications") || []
    ).map((n) => NotificationModel.deserialize(n));
  }

  public schedule(notification: NotificationModel): void {
    this.notifications.push(notification);

    this.saveNotifications();
  }

  public unscheduleById(id: string): void {
    this.notifications = this.notifications.filter((n) => n.id !== id);

    this.saveNotifications();
  }

  private saveNotifications(): void {
    ls.set(
      `notifications`,
      this.notifications.map((n) => n.serialize()),
    );
  }

  public async observe(): Promise<void> {
    while (true) {
      runInAction(() => {
        this.notifications.forEach((notification) => {
          if (notification.shouldNotify) {
            this.notify(notification);
            this.unscheduleById(notification.id);
          }
        });
      });

      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  private async notify(notificationModel: NotificationModel): Promise<void> {
    if (!this.areNotificationsSupported || !this.isPermissionGranted) return;

    new Notification(notificationModel.title, {
      body: notificationModel.message,
      icon: "/favicon.png",
    });
  }
}
