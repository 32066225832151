import { useLocalStorageValue } from "@react-hookz/web";
import { clsx } from "clsx";
import { motion, useSpring, useTransform } from "framer-motion";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./control-panel.module.scss";

import { Button } from "./button";
import { Divider } from "./divider";

interface Props {
  children: React.ReactNode;
}

function ControlPanel({ children }: Props) {
  const [isOpen, setIsOpen] = useState(true);
  const { value: savedPosition, set: setSavedPosition } = useLocalStorageValue(
    "ControlPanelPositionProportion",
    { defaultValue: 0.5 },
  );

  const [isDragging, setIsDragging] = useState(false);

  const startPosition = (savedPosition! - 0.5) * window.innerWidth;

  const position = useSpring(startPosition, { stiffness: 200, damping: 30 });
  const x = useTransform(position, (p) => `${p}px`);

  useHotkeys("h", () => setIsOpen((prev) => !prev));

  return (
    <div className={styles.container}>
      <motion.div
        className={clsx(styles.panel, { [styles.open]: isOpen })}
        style={{ x, pointerEvents: isDragging ? "none" : "auto" }}
        animate={{
          y: isOpen ? 0 : 10,
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? "visible" : "hidden",
        }}
        onPanStart={() => setIsDragging(true)}
        onPan={(_, info) => {
          position.set(startPosition + info.offset.x);
        }}
        onPanEnd={(_, info) => {
          setIsDragging(false);
          setSavedPosition(
            (startPosition + info.offset.x) / window.innerWidth + 0.5,
          );
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Object.assign(ControlPanel, { Button, Divider });
