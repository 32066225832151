import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import styles from "./current-time-week-bar.module.scss";

import { estimateCurrentTimeBarHeight } from "../../model";

interface CurrentTimeWeekBarProps {
  height: number;
}

export const CurrentTimeWeekBar = observer(function CurrentTimeWeekBar({
  height,
}: CurrentTimeWeekBarProps) {
  const currentHeight = estimateCurrentTimeBarHeight(height);
  const lineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    lineRef.current?.scrollIntoView({
      block: "center",
      behavior: "instant",
    });
  }, [lineRef]);

  return (
    <motion.div
      ref={lineRef}
      className={styles.weekNow}
      style={{ y: currentHeight }}
    />
  );
});
