import { database } from "../database";
import { FocusActivity } from "../entities/activity";
import { TaskEntity } from "../entities/task";

database
  .version(69)
  .stores({
    tasks:
      "++id,name,order,parentId,priorityInGroup,priorityGroupId,zoneId,location,plan.type,loggedAt,completedAt",
    events: "id,taskId,sessionId,startDate,completedAt",
    zones: "id,name,color",
    priorityGroups: "id,order",
    calorieIntakes: "id,creationDateTimeUtc",
    timeMaps: "id,name",
    suggestions: "id,taskId,creationDateTimeUtc",
    activities: "++id,type,sessionId,startDate,estimatedEndDate,actualEndDate",
  })
  .upgrade(async (tx) => {
    const activities = await tx
      .table<FocusActivity>("activities")
      .where("type")
      .equals("focus")
      .toArray();

    for (const activity of activities) {
      const task = await tx
        .table<TaskEntity>("tasks")
        .filter(
          (t) =>
            t.plan?.sessions.some((s) => s.id === activity.sessionId) ?? false,
        )
        .first();

      if (!task) throw new Error("Task not found");

      await tx
        .table<FocusActivity>("activities")
        .update(activity.id, { taskId: task.id });
    }
  });
