import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import { useMemo } from "react";

import { EventsStore } from "entities/events";
import { TimeMapsStore } from "entities/time-maps";
import { getDayBoundaries, getDayFromDatetime } from "entities/zones";

import { useDi } from "shared/di";

import styles from "./uncompleted-events.module.scss";

import { UncompletedEvent } from "./uncompleted-event";

interface Props {
  onShowTaskInOutline: (taskId: string) => void;
}

export const UncompletedEvents = observer(function UncompletedEvents({
  onShowTaskInOutline,
}: Props) {
  const eventsStore = useDi().get(EventsStore);
  const timeMapsStore = useDi().get(TimeMapsStore);

  const defaultTimeMap = timeMapsStore.defaultTimeMap;
  if (!defaultTimeMap) return null;

  const nowDate = DateTime.fromMillis(now());
  const today = getDayFromDatetime(defaultTimeMap, nowDate);

  const yesterdayBoundaries = getDayBoundaries(
    defaultTimeMap,
    today.minus({ days: 1 }),
    today.minus({ days: 1 }),
  );
  const todayBoundaries = getDayBoundaries(defaultTimeMap, today, today);

  const todayEvents = eventsStore.getEventsInRange(
    yesterdayBoundaries.end.toJSDate(),
    todayBoundaries.end.toJSDate(),
  );

  const uncompletedEvents = todayEvents.filter((event) => !event.isCompleted);

  return (
    uncompletedEvents.length > 0 && (
      <div className={styles.uncompletedEvents}>
        {uncompletedEvents.map((event) => (
          <UncompletedEvent
            key={event.sessionId}
            className={styles.event}
            event={event}
            onShowTaskInOutline={onShowTaskInOutline}
          />
        ))}
      </div>
    )
  );
});
