import { useSet } from "@react-hookz/web";

import { TaskModel, TasksStore } from "entities/tasks";
import { sortByPriorityAndGroup } from "entities/tasks/model/sort";

import { useDi } from "shared/di";

import { mergePriorityTask as mergePriorityTaskMutator } from "./mutators";
import { usePriorityGroups } from "./use-priority-groups";

export function usePriorityTasks() {
  const tasksStore = useDi().get(TasksStore);

  const forcefullySplitTasks = useSet<string>();

  const priorityGroups = usePriorityGroups();

  const outlineTasks = tasksStore.getTasksInLocation("outline");

  const prioritizedTasks = outlineTasks
    .filter((t) => t.task.plan !== undefined || t.isParent)
    .filter((t) => t.task.priorityInGroup !== undefined)
    .sort((a, b) => sortByPriorityAndGroup(a, b, priorityGroups ?? []));

  const expandedPrioritizedTasks = (
    prioritizedTasks?.flatMap(expandTaskTree) ?? []
  ).concat(
    outlineTasks
      .filter((t) => t.parent === null && t.task.priorityInGroup === undefined)
      .flatMap(expandTaskTree) ?? [],
  );

  function expandTaskTree(task: TaskModel): TaskModel[] {
    if (
      !forcefullySplitTasks.has(task.id) ||
      task.getChildrenByLocation("outline").length === 0
    )
      return [task];

    return task
      .getChildrenByLocation("outline")
      .sort((a, b) => a.order - b.order)
      .filter((c) => c.task.priorityInGroup === undefined)
      .flatMap(expandTaskTree);
  }

  function splitPriorityTask(taskId: string) {
    forcefullySplitTasks.add(taskId);
  }

  async function mergePriorityTask(task: TaskModel) {
    await mergePriorityTaskMutator(tasksStore, task);

    if (task.parent) forcefullySplitTasks.delete(task.parent.id);
  }

  return {
    prioritizedTasks,
    expandedPrioritizedTasks,
    forcefullySplitTasks,
    splitPriorityTask,
    mergePriorityTask,
  };
}
