import { DateTime } from "luxon";
import { makeAutoObservable } from "mobx";

import { TasksStore } from "entities/tasks";

import { EventEntity, database } from "shared/database";

export class EventModel {
  public get id() {
    return this.event.id;
  }

  public get taskId() {
    return this.event.taskId;
  }

  public get sessionId() {
    return this.event.sessionId;
  }

  public get isPinned() {
    return this.event.isPinned;
  }

  public get startDateTime() {
    return DateTime.fromJSDate(this.event.startDate);
  }

  public get endDateTime() {
    return DateTime.fromJSDate(this.event.endDate);
  }

  public get task() {
    const task = this.tasksStore.taskById.get(this.taskId);
    if (!task) throw new Error(`Can't find task with id ${this.taskId}`);

    return task;
  }

  public get session() {
    const session = this.task.task.plan?.sessions.find(
      (s) => s.id === this.sessionId,
    );
    if (!session)
      throw new Error(`Can't find session with id ${this.sessionId}`);

    return session;
  }

  public get title() {
    if (!("startDate" in this.session)) return this.task.title;

    const sessionStartDate = DateTime.fromISO(this.session.startDate);
    const sessionEndDate = sessionStartDate.plus({
      days: this.session.days - 1,
    });

    const localeOptions = { locale: "ru-RU" };
    const formattedStartDate = sessionStartDate.toFormat(
      "d LLL",
      localeOptions,
    );
    const formattedEndDate = sessionEndDate.toFormat("d LLL", localeOptions);

    return (
      this.task.title +
      " – " +
      formattedStartDate +
      (this.session.days > 1 ? " / " + formattedEndDate : "")
    );
  }

  public get isCompleted() {
    return this.session.completedAt !== undefined;
  }

  public get duration() {
    return this.endDateTime.diff(this.startDateTime);
  }

  public constructor(
    private readonly tasksStore: TasksStore,
    private readonly event: EventEntity,
  ) {
    makeAutoObservable(this);
  }

  public *complete(isCompleted: boolean) {
    const task = this.tasksStore.taskById.get(this.taskId);
    if (!task) throw new Error(`Can't find task with id ${this.taskId}`);

    const session = task.task.plan?.sessions.find(
      (s) => s.id === this.sessionId,
    );
    if (!session)
      throw new Error(`Cannot find session with id ${this.sessionId}`);

    yield task.completeSession(session, isCompleted ? new Date() : undefined);
  }

  public *pin(startDateTime: DateTime, duration: number) {
    const originalStartDate = this.event.startDate;
    const originalEndDate = this.event.endDate;

    const update: Partial<EventEntity> = {
      isPinned: true,
      startDate: startDateTime.toJSDate(),
      endDate: startDateTime.plus({ minutes: duration }).toJSDate(),
    };

    try {
      Object.assign(this.event, update);

      yield database.events.update(this.id, update);
    } catch (error) {
      this.event.isPinned = false;
      this.event.startDate = originalStartDate;
      this.event.endDate = originalEndDate;
      throw error;
    }
  }

  public *unpin() {
    yield database.events.update(this.id, { isPinned: false });

    this.event.isPinned = false;
  }
}
