import { DateTime } from "luxon";

import { ZoneEntity } from "shared/database";

import { getWeekdayName } from "./get-weekday-name";

export function getWeekdayZoneMinutesLimit(
  day: DateTime,
  zone: ZoneEntity,
): number | null {
  return zone[`${getWeekdayName(day)}MinutesLimit` as const];
}
