import {
  ElementDragPayload,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/types";
import { DateTime } from "luxon";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { merge } from "remeda";

import { EventsStore } from "entities/events";

import { useDi } from "shared/di";
import { roundMinutesToNearestSlot } from "shared/libs/time";

import styles from "./events.module.scss";

import { Event, EventPreview } from "./events/index";

import { DAY_HEADER_HEIGHT, evaluateEventLines } from "../../model";

interface Props {
  startDay: DateTime;
  dayIndex: number;
  height: number;
  onShowTaskInOutline?: (taskId: string) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
}

export const Events = observer(function Events({
  startDay,
  dayIndex,
  height,
  onShowTaskInOutline,
  onDragStart,
  onDragEnd,
}: Props) {
  const eventsStore = useDi().get(EventsStore);

  const day = startDay.startOf("day").plus({ day: dayIndex });

  const [eventsElement, setEventsElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [previewDetails, setPreviewDetails] = useState<{
    startDateTime: DateTime;
    estimatedDuration: number;
  } | null>(null);
  const lastPreviewDetails = useRef<{
    startDateTime: DateTime;
    estimatedDuration: number;
  } | null>(null);

  const estimatePreviewDetails = useCallback(
    (location: DragLocationHistory, source: ElementDragPayload) => {
      const data: {
        calendarTop: number;
        calendarScrollTop: number;
        eventPreviewTopOffset: number;
        cursorY: number;
      } = location.current.dropTargets
        .map((t) => t.data)
        .reduce((p, c) => merge(p, c)) as never;

      const dayTop =
        data.calendarScrollTop +
        (data.cursorY - data.calendarTop) +
        -data.eventPreviewTopOffset +
        -DAY_HEADER_HEIGHT;

      const dayMinutes = Math.min(
        (dayTop / height) * 60 * 24,
        60 * 24 - (source.data.estimatedDuration as number),
      );
      const roundedDayMinutes = roundMinutesToNearestSlot(dayMinutes);

      return {
        startDateTime: day.plus({ minute: roundedDayMinutes }),
        estimatedDuration: source.data.estimatedDuration as number,
      };
    },
    [day, height],
  );

  useEffect(() => {
    if (!eventsElement) return;

    return dropTargetForElements({
      element: eventsElement,
      getData: ({ input }) => ({ cursorY: input.clientY }),
      onDrag: ({ location, source }) => {
        const estimatedPreviewDetails = estimatePreviewDetails(
          location,
          source,
        );

        if (
          estimatedPreviewDetails.estimatedDuration !==
            lastPreviewDetails.current?.estimatedDuration ||
          estimatedPreviewDetails.startDateTime.toISO() !==
            lastPreviewDetails.current?.startDateTime?.toISO()
        ) {
          setPreviewDetails(estimatedPreviewDetails);
          lastPreviewDetails.current = estimatedPreviewDetails;
        }
      },
      onDragLeave: () => setPreviewDetails(null),
      onDrop: ({ location, source }) => {
        const previewDetails = estimatePreviewDetails(location, source);

        runInAction(() => {
          const eventId = source.data.id as string;
          const event = eventsStore.eventById.get(eventId);

          if (!event)
            throw new Error(`Unable to find event with id ${eventId}`);

          event.pin(
            previewDetails.startDateTime,
            previewDetails.estimatedDuration,
          );
        });

        setPreviewDetails(null);
      },
    });
  }, [day, eventsElement, estimatePreviewDetails, height]);

  const events = useMemo(
    () =>
      eventsStore.getEventsInRange(
        day.startOf("day").toJSDate(),
        day.endOf("day").toJSDate(),
      ),
    [day],
  );

  const eventLines = useMemo(() => evaluateEventLines(events), [events]);

  const eventComponents = useMemo(
    () =>
      events.map((event) => {
        const startOfTheDay = day.startOf("day");
        const endOfTheDay = day.endOf("day");

        return (
          <Event
            key={event.id}
            startOfTheDay={startOfTheDay}
            endOfTheDay={endOfTheDay}
            event={event}
            height={height}
            parallelEvents={eventLines.get(event)?.total ?? 0}
            parallelEventIndex={eventLines.get(event)?.line ?? 0}
            onShowTaskInOutline={onShowTaskInOutline}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        );
      }),
    [events, eventLines, height, onShowTaskInOutline],
  );

  return (
    <div ref={setEventsElement} className={styles.events}>
      {eventComponents}
      {previewDetails && (
        <EventPreview
          startDateTime={previewDetails.startDateTime}
          duration={previewDetails.estimatedDuration}
          height={height}
        />
      )}
    </div>
  );
});
