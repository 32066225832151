import clsx from "clsx";
import { DateTime } from "luxon";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { range } from "remeda";

import styles from "./calendar.module.scss";

import { WeekView } from "./week-view";

interface CalendarProps {
  mode: "events" | "activities";
  onShowTaskInOutline?: (taskId: string) => void;
}

export function Calendar({ mode, onShowTaskInOutline }: CalendarProps) {
  const [startDay, setStartDay] = useState(DateTime.now());
  const [visibleDays, setVisibleDays] = useState(5);

  useHotkeys("r", () => setStartDay(DateTime.now()));

  useHotkeys(
    range(0, 10).map((i) => `d+${i}`),
    (e) => {
      if (e.key === "0") setVisibleDays(14);
      else setVisibleDays(Number.parseInt(e.key, 10));
    },
  );

  return (
    <div className={styles.calendar}>
      <h2
        className={clsx(styles.date, {
          [styles.inTestEnvironment]: import.meta.env.DEV,
        })}
      >
        <div
          className={styles.dateText}
          onClick={() => setStartDay(DateTime.now())}
        >
          <span className={styles.month}>
            {startDay.toLocaleString({ month: "long" }, { locale: "ru" })}
          </span>
          <span>{` ${startDay.year}`}</span>
        </div>
      </h2>
      <WeekView
        key={startDay.toISODate()}
        startDay={startDay.startOf("day")}
        days={visibleDays}
        mode={mode}
        onShowTaskInOutline={onShowTaskInOutline}
      />
    </div>
  );
}
