import { motion } from "framer-motion";

import styles from "./execution-panel.module.scss";

import { StatusPanel } from "./status-panel";
import { UncompletedEvents } from "./uncompleted-events";

interface Props {
  onShowTaskInOutline: (taskId: string) => void;
}

export function ExecutionPanel({ onShowTaskInOutline }: Props) {
  return (
    <>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <UncompletedEvents onShowTaskInOutline={onShowTaskInOutline} />
      </motion.div>
      <StatusPanel className={styles.status} />
    </>
  );
}
