import Dexie, { EntityTable } from "dexie";

import { ActivityEntity } from "./entities/activity";
import { CalorieIntakeEntity } from "./entities/calorie-intake";
import { EventEntity } from "./entities/event";
import { PriorityGroupEntity } from "./entities/priority-group";
import { SuggestionEntity } from "./entities/suggestion";
import { TaskEntity } from "./entities/task";
import { TimeMapEntity } from "./entities/time-map";
import { ZoneEntity } from "./entities/zone";

export const database = new Dexie("PlanBalanceDatabase") as Dexie & {
  tasks: EntityTable<TaskEntity, "id">;
  events: EntityTable<EventEntity, "id">;
  zones: EntityTable<ZoneEntity, "id">;
  priorityGroups: EntityTable<PriorityGroupEntity, "id">;
  timeMaps: EntityTable<TimeMapEntity, "id">;
  suggestions: EntityTable<SuggestionEntity, "id">;
  activities: EntityTable<ActivityEntity, "id">;
};
