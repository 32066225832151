import { clsx } from "clsx";
import { ReactNode, useEffect, useRef } from "react";

import styles from "./tag.module.scss";

import { useTagsContext } from "../model";

interface DynamicTagProps<T> {
  activeColor?: string;
  value: T;
  children: ReactNode;
}

export function DynamicTag<T>({
  activeColor,
  value,
  children,
}: DynamicTagProps<T>) {
  const { value: storeValue, setValue } = useTagsContext<T>();

  return (
    <StaticTag
      onClick={() => setValue(value)}
      isActive={storeValue === value}
      activeColor={activeColor}
    >
      {children}
    </StaticTag>
  );
}

interface StaticTagProps {
  isActive?: boolean;
  activeColor?: string;
  onClick: () => void;
  children: ReactNode;
}

export function StaticTag({
  isActive,
  activeColor,
  onClick,
  children,
}: StaticTagProps) {
  const ref = useRef<HTMLDivElement>(null);

  const primaryColor = isActive ? activeColor : undefined;

  useEffect(() => {
    if (isActive && ref.current)
      ref.current.scrollIntoView({
        behavior: "instant",
        inline: "center",
        block: "nearest",
      });
  }, [isActive]);

  return (
    <div
      ref={ref}
      className={clsx(styles.button, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      {isActive && (
        <div
          className={styles.active}
          style={{
            backgroundColor: primaryColor,
            border: `1px solid ${primaryColor}`,
          }}
        />
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
