import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useResizeObserver, useWindowSize } from "@react-hookz/web";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./schedule.module.scss";

import { CurrentTimeWeekBar } from "./current-time-week-bar";
import { Day } from "./day";
import { TimeColumn } from "./time-column";

import { DAY_HEADER_HEIGHT, useToday } from "../../model";

interface ScheduleProps {
  startDay: DateTime;
  days: number;
  mode: "events" | "activities";
  onShowTaskInOutline?: (taskId: string) => void;
}

export function Schedule({
  startDay,
  days,
  mode,
  onShowTaskInOutline,
}: ScheduleProps) {
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);

  const virtualizer = useVirtualizer({
    getScrollElement: () => containerElement,
    estimateSize: () => ((containerElement?.clientWidth ?? 1000) - 60) / days,
    count: 7 + 60,
    horizontal: true,
    overscan: 4,
    scrollPaddingStart: mode === "events" ? -1 : -30.5,
  });

  useResizeObserver(containerElement, () => {
    virtualizer.measure();
  });

  useEffect(() => {
    virtualizer.measure();
    virtualizer.scrollToIndex(7, {
      align: mode === "events" ? "start" : "center",
    });
  }, [mode, virtualizer.getTotalSize(), days, mode]);

  const today = useToday();

  useEffect(() => {
    if (!containerElement) return;

    return combine(
      autoScrollForElements({
        element: containerElement,
      }),
      dropTargetForElements({
        element: containerElement,
        getData: ({ source }) => ({
          calendarTop: containerElement.getBoundingClientRect().top,
          calendarScrollTop: containerElement.scrollTop,
          eventPreviewTopOffset: source.data.previewTopOffset,
        }),
      }),
    );
  }, [containerElement]);

  const dragStartHandler = useCallback(() => {
    setIsDragging(true);
  }, []);

  const dragEndHandler = useCallback(() => {
    setIsDragging(false);
  }, []);

  const height = 2000;

  return (
    <div className={styles.container} ref={setContainerElement}>
      <div
        className={styles.schedule}
        style={{
          height: `${height + DAY_HEADER_HEIGHT}px`,
          width: `${virtualizer.getTotalSize() + 60}px`,
        }}
      >
        <div className={styles.weekDays}>
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const day = startDay.plus({ day: virtualItem.index - 7 });

            return (
              <div
                key={day.toISODate()}
                className={styles.dayBlock}
                style={{
                  position: "absolute",
                  width: `${virtualItem.size}px`,
                  left: `${virtualItem.start}px`,
                }}
              >
                <div
                  className={clsx(styles.day, {
                    [styles.isCurrent]: today.equals(day),
                  })}
                >
                  <span className={styles.weekday}>
                    {day.toLocaleString({ weekday: "short" }, { locale: "ru" })}
                  </span>
                  <span className={styles.date}>
                    {day.toLocaleString({ day: "2-digit" }, { locale: "ru" })}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <TimeColumn className={styles.timeColumn} />
        <div className={styles.days} style={{ height: "100%" }}>
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const dayIndex = virtualItem.index - 7;
            const day = startDay.startOf("day").plus({ day: dayIndex });
            return (
              <div
                key={day.toISODate()}
                style={{
                  position: "absolute",
                  top: 0,
                  left: `${virtualItem.start}px`,
                  height: "100%",
                  width: `${virtualItem.size}px`,
                  display: "flex",
                  borderLeft:
                    virtualItem.index > 0
                      ? "1px solid var(--color-border)"
                      : "none",
                  scrollSnapAlign: isDragging ? "none" : "start",
                  scrollMarginLeft: "var(--time-column-width)",
                }}
              >
                <Day
                  key={day.toISODate()}
                  className={styles.day}
                  startDay={startDay}
                  dayIndex={dayIndex}
                  height={height}
                  mode={mode}
                  onShowTaskInOutline={onShowTaskInOutline}
                  onDragStart={dragStartHandler}
                  onDragEnd={dragEndHandler}
                />
              </div>
            );
          })}
          <CurrentTimeWeekBar key={mode} height={height} />
        </div>
        <div className={styles.intersection} />
      </div>
    </div>
  );
}
