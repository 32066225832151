import { EventModel } from "entities/events";

/**
 * Calculates the event lines for parallel events display in calendar
 *
 * @param events - List of events to arrange
 * @returns Map of events with their line position and total count in their time slot
 */
export function evaluateEventLines(
  events: EventModel[],
): Map<EventModel, { line: number; total: number }> {
  const eventLines = new Map<EventModel, { line: number; total: number }>();
  const activeEvents = new Set<EventModel>();

  const sortedEvents = events.sort((a, b) => {
    if (!a.startDateTime.equals(b.startDateTime))
      return a.startDateTime.diff(b.startDateTime).toMillis();

    if (!a.endDateTime.equals(b.endDateTime))
      return a.endDateTime.diff(b.endDateTime).toMillis();

    return a.task.title.localeCompare(b.task.title);
  });

  for (const event of sortedEvents) {
    const eventStartDateTime = event.startDateTime;

    // Remove events that end before current event starts
    for (const activeEvent of activeEvents) {
      const activeEventEndDateTime = activeEvent.endDateTime;

      if (activeEventEndDateTime <= eventStartDateTime) {
        activeEvents.delete(activeEvent);
      }
    }

    // Find first available line
    let line = 0;
    const usedLines = new Set<number>();

    for (const activeEvent of activeEvents) {
      const activeLine = eventLines.get(activeEvent)?.line ?? 0;
      usedLines.add(activeLine);
    }

    while (usedLines.has(line)) {
      line++;
    }

    activeEvents.add(event);
    const total = activeEvents.size;

    // Update all active events with the new total
    for (const activeEvent of activeEvents) {
      const currentLine =
        activeEvent === event ? line : (eventLines.get(activeEvent)?.line ?? 0);
      eventLines.set(activeEvent, {
        line: currentLine,
        total: Math.max(total, eventLines.get(activeEvent)?.total ?? 0),
      });
    }
  }

  return eventLines;
}
