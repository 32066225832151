import { observer } from "mobx-react-lite";

import { ZoneModel, ZonesStore } from "entities/zones";

import { useDi } from "shared/di";
import { Button } from "shared/ui";
import { ColorInput } from "shared/ui/color-input";
import { TextInput } from "shared/ui/text-input";

import styles from "./zone-info.module.scss";

interface Props {
  selectedZone: ZoneModel;
}

const weekdays = [
  { key: "monday", label: "Понедельник" },
  { key: "tuesday", label: "Вторник" },
  { key: "wednesday", label: "Среда" },
  { key: "thursday", label: "Четверг" },
  { key: "friday", label: "Пятница" },
  { key: "saturday", label: "Суббота" },
  { key: "sunday", label: "Воскресенье" },
] as const;

export const ZoneInfo = observer(function ZoneInfo({ selectedZone }: Props) {
  const zonesStore = useDi().get(ZonesStore);

  return (
    <div className={styles.panel}>
      <div className={styles.info}>
        <TextInput
          label="Название"
          value={selectedZone.name}
          onChange={(name) => selectedZone.update({ name })}
        />
        <ColorInput
          label="Цвет"
          color={selectedZone.color.toHexString()}
          onChange={(color) => selectedZone.update({ color })}
        />
        <TextInput
          label="Минуты в день"
          value={selectedZone.dayMinutesLimit?.toString() ?? ""}
          onChange={(dayMinutesLimit) => {
            if (dayMinutesLimit === "") {
              selectedZone.update({ dayMinutesLimit: null });
              return;
            }

            const parsedValue = Number.parseInt(dayMinutesLimit, 10);
            if (!Number.isFinite(parsedValue)) return;

            selectedZone.update({ dayMinutesLimit: parsedValue });
          }}
        />
        <TextInput
          label="Минуты в неделю"
          value={selectedZone.weekMinutesLimit?.toString() ?? ""}
          onChange={(weekMinutesLimit) => {
            if (weekMinutesLimit === "") {
              selectedZone.update({ weekMinutesLimit: null });
              return;
            }

            const parsedValue = Number.parseInt(weekMinutesLimit, 10);
            if (!Number.isFinite(parsedValue)) return;

            selectedZone.update({ weekMinutesLimit: parsedValue });
          }}
        />
        <div className={styles.weekdayLimits}>
          {weekdays.map(({ key, label }) => (
            <TextInput
              key={key}
              label={`Минуты в ${label.toLowerCase()}`}
              value={selectedZone[`${key}MinutesLimit`]?.toString() ?? ""}
              onChange={(value) => {
                if (value === "") {
                  selectedZone.update({ [`${key}MinutesLimit`]: null });
                  return;
                }

                const parsedMinutes = Number.parseInt(value, 10);
                if (!Number.isFinite(parsedMinutes)) return;

                selectedZone.update({ [`${key}MinutesLimit`]: parsedMinutes });
              }}
            />
          ))}
        </div>
        <Button
          className={styles.deleteButton}
          variant="danger"
          onClick={() => zonesStore.remove(selectedZone)}
        >
          Удалить
        </Button>
      </div>
    </div>
  );
});
