import IntervalTree from "@flatten-js/interval-tree";
import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { v4 } from "uuid";

import { TasksStore } from "entities/tasks";

import { EventEntity, database } from "shared/database";

import { EventModel } from "./event-model";

@injectable()
export class EventsStore {
  public events: EventModel[] = [];

  private get eventsIntervalTree() {
    const intervalTree = new IntervalTree<EventModel>();

    for (const event of this.events) {
      intervalTree.insert(
        [event.startDateTime.toMillis(), event.endDateTime.toMillis() - 1],
        event,
      );
    }

    return intervalTree;
  }

  public getEventsInRange(start: Date, end: Date) {
    return this.eventsIntervalTree.search([
      start.getTime(),
      end.getTime(),
    ]) as EventModel[];
  }

  public constructor(
    @inject(TasksStore) private readonly tasksStore: TasksStore,
  ) {
    makeAutoObservable(this);
  }

  public get eventById() {
    return new Map<string, EventModel>(this.events.map((e) => [e.id, e]));
  }

  public get eventBySessionId() {
    return new Map<string, EventModel>(
      this.events.map((e) => [e.sessionId, e]),
    );
  }

  public *loadEvents() {
    const events = (yield database.events
      .toCollection()
      .sortBy("startDate")) as EventEntity[];

    this.events = events.map((e) => new EventModel(this.tasksStore, e));
  }

  public async clearEventsWithoutSessions() {
    await database.transaction(
      "readwrite",
      [database.events, database.tasks],
      async () => {
        const tasks = await database.tasks.toArray();
        const taskIds = tasks.map((t) => t.id);
        const taskSessionIds = tasks.flatMap(
          (t) => t.plan?.sessions.map((s) => s.id) ?? [],
        );

        const eventsToDelete = await database.events
          .filter(
            (e) =>
              !taskIds.includes(e.taskId) ||
              !taskSessionIds.includes(e.sessionId),
          )
          .toArray();

        await database.events.bulkDelete(eventsToDelete.map((e) => e.id));
      },
    );

    await this.loadEvents();
  }

  public *replaceEvents(updatedEvents: EventEntity[]) {
    const completedTaskSessionIds = new Set(
      this.tasksStore.tasks.flatMap(
        (t) =>
          t.task.plan?.sessions.filter((s) => s.completedAt).map((s) => s.id) ??
          [],
      ),
    );

    const oldEvents = toJS(this.events);
    const newEvents = this.events
      .filter((e) => completedTaskSessionIds.has(e.sessionId))
      .concat(updatedEvents.map((e) => new EventModel(this.tasksStore, e)));

    try {
      const bulkAdd = newEvents.map((e) => ({
        id: e.id,
        taskId: e.taskId,
        sessionId: e.sessionId,
        startDate: e.startDateTime.toJSDate(),
        endDate: e.endDateTime.toJSDate(),
        isPinned: e.isPinned,
      }));

      yield database.transaction("readwrite", database.events, async () => {
        await database.events.clear();
        await database.events.bulkAdd(bulkAdd);
      });

      this.events = newEvents;
    } catch (error) {
      this.events = oldEvents;
      throw error;
    }
  }

  public async deleteForTask(taskId: string) {
    await database.events.where("taskId").equals(taskId).delete();

    runInAction(() => {
      this.events = this.events.filter((e) => e.taskId !== taskId);
    });
  }
}
