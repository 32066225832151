import { inject, injectable } from "inversify";
import { comparer, computed, makeAutoObservable } from "mobx";

import { TaskModel, TasksStore } from "entities/tasks";

@injectable()
export class TaskTreeStore {
  public isTaskEditing = false;

  public isTaskNew = false;

  public selectedTaskId: string | null = null;

  public roots: TaskModel[] = [];

  public location: "inbox" | "outline" | "journal" = "outline";

  public get selectedTask() {
    return this.selectedTaskId ? this.findTaskById(this.selectedTaskId) : null;
  }

  public get selectedTaskIndex() {
    if (!this.selectedTask) throw new Error("No selected task");

    const index = this.getTaskIndex(this.selectedTask);
    if (!index) throw new Error("No index for selected task");

    return index;
  }

  public constructor(
    @inject(TasksStore) private readonly tasksStore: TasksStore,
  ) {
    makeAutoObservable(this, {
      selectedTaskIndex: computed({ equals: comparer.shallow }),
    });
  }

  public updateTasks(
    roots: TaskModel[],
    location: "inbox" | "outline" | "journal",
  ) {
    this.roots = roots;
    this.location = location;
  }

  public findTaskById(
    id: string,
    tasks: TaskModel[] = this.roots,
  ): TaskModel | null {
    for (const task of tasks) {
      if (task.id === id) return task;

      const result = this.findTaskById(
        id,
        task.getChildrenByLocation(this.location),
      );
      if (result) return result;
    }
    return null;
  }

  public toggleIsTaskEditing() {
    this.setIsTaskEditing(!this.isTaskEditing);
  }

  public setIsTaskEditing(isEditing: boolean) {
    this.isTaskEditing = isEditing;
    if (!isEditing) this.setIsTaskNew(false);
  }

  public setIsTaskNew(isNew: boolean) {
    this.isTaskNew = isNew;
  }

  public setSelectedTaskId(taskId: string | null) {
    this.selectedTaskId = taskId;
  }

  public async deleteSelectedTask() {
    if (!this.selectedTask) return;

    const parentIndex = this.selectedTaskIndex.slice(0, -1);
    const currentIndex = this.selectedTaskIndex.slice(-1)[0];

    await this.tasksStore.remove(this.selectedTask);

    this.trySelectFirstExistingTaskIndex(
      [...parentIndex, currentIndex],
      [...parentIndex, currentIndex - 1],
      parentIndex,
    );
  }

  public trySelectFirstExistingTaskIndex(...indexes: number[][]) {
    for (const index of indexes) if (this.trySelectTaskWithIndex(index)) break;
  }

  public trySelectTaskWithIndex(index: number[]) {
    const task = this.tryGetTaskFromIndex(index);
    if (!task) return false;

    this.setSelectedTaskId(task.id);
    return true;
  }

  public tryGetTaskFromIndex(
    index: number[],
    tasks: TaskModel[] | undefined = this.roots,
  ): TaskModel | undefined {
    if (!tasks) return tasks;
    if (index.length === 0) return undefined;

    const currentIndex = index[0];
    if (currentIndex < 0 || currentIndex >= tasks.length) return undefined;

    if (index.length === 1) return tasks[currentIndex];

    if (tasks[currentIndex].isCollapsed) return undefined;

    const childTasks = tasks[currentIndex]?.getChildrenByLocation(
      this.location,
    );
    if (!childTasks) return undefined;

    return this.tryGetTaskFromIndex(index.slice(1), childTasks);
  }

  public getTaskIndex(
    task: TaskModel | null,
    tasks: TaskModel[] = this.roots,
  ): number[] | undefined {
    if (task == null) return undefined;

    const taskIndex = tasks.findIndex((t) => t.id === task.id);
    if (taskIndex !== -1) return [taskIndex];

    const subtaskWithTask = tasks
      .map((t, index) => ({
        index,
        subIndex: this.getTaskIndex(
          task,
          t.getChildrenByLocation(this.location),
        ),
      }))
      .find((t) => t.subIndex !== undefined);

    if (!subtaskWithTask || !subtaskWithTask.subIndex) return undefined;

    return [subtaskWithTask.index, ...subtaskWithTask.subIndex];
  }
}
