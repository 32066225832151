import { Container } from "inversify";
import { createContext, useContext } from "react";

export const DiContext = createContext<Container | null>(null);

export function useDi() {
  const di = useContext(DiContext);
  if (!di) throw new Error("DiContext not found");

  return di;
}
