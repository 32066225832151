import clsx from "clsx";
import { motion } from "framer-motion";
import { flow } from "mobx";
import { observer } from "mobx-react-lite";
import {
  BsCheckCircle,
  BsListUl,
  BsPlayCircle,
  BsSkipEndCircle,
  BsStopCircle,
} from "react-icons/bs";

import { Scheduler } from "features/scheduler";

import { ActivitiesStore } from "entities/activities";
import { EventModel } from "entities/events";
import { isRecurringTask } from "entities/tasks";

import { getDefaultColor } from "shared/colors";
import { useDi } from "shared/di";
import { ContextMenu, MenuItem } from "shared/ui/context-menu";
import { IconButton } from "shared/ui/icon-button";

import styles from "./uncompleted-event.module.scss";

interface Props {
  className?: string;
  event: EventModel;
  onShowTaskInOutline: (taskId: string) => void;
}

export const UncompletedEvent = observer(function UncompletedEvent({
  className,
  event,
  onShowTaskInOutline,
}: Props) {
  const scheduler = useDi().get(Scheduler);
  const activitiesStore = useDi().get(ActivitiesStore);

  const executingActivity =
    activitiesStore.executingActivity?.type === "focus" &&
    activitiesStore.executingActivity.sessionId === event.sessionId
      ? activitiesStore.executingActivity
      : null;

  const zoneColor = event.task.zone?.color ?? getDefaultColor();

  const timeSpent = activitiesStore.getTotalDurationBySessionId(
    event.sessionId,
  );

  const estimatedDuration = executingActivity
    ? executingActivity.task.duration
    : event.duration;

  const remainingTime =
    estimatedDuration < timeSpent
      ? timeSpent.minus(estimatedDuration)
      : estimatedDuration.minus(timeSpent);

  const progressPercent = Math.min(
    100,
    (timeSpent.toMillis() / estimatedDuration.toMillis()) * 100,
  );

  const contextMenuItems = (
    <>
      <MenuItem onClick={() => onShowTaskInOutline?.(event.task.id)}>
        Показать в списке
      </MenuItem>
    </>
  );

  return (
    <ContextMenu content={contextMenuItems}>
      <motion.div
        className={clsx(styles.event, className)}
        style={{
          backgroundColor: zoneColor
            .clone()
            .lighten(20)
            .setAlpha(0.8)
            .toHex8String(),
          color: zoneColor.clone().monochromatic(10)[5].toHexString(),
          borderColor: zoneColor.toHexString(),
          borderLeft: `4px solid ${zoneColor.toHexString()}`,
        }}
        animate={
          executingActivity?.type === "focus" &&
          executingActivity.sessionId === event.sessionId
            ? {
                boxShadow: [
                  `0 0 8px 2px ${zoneColor.setAlpha(0).toRgbString()}`,
                  `0 0 8px 2px ${zoneColor.setAlpha(0.5).toRgbString()}`,
                  `0 0 8px 2px ${zoneColor.setAlpha(0).toRgbString()}`,
                ],
              }
            : {}
        }
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <div className={styles.timeInfo}>
          <span>{event.startDateTime.toFormat("HH:mm")}</span>
          <span>{event.endDateTime.toFormat("HH:mm")}</span>
        </div>
        <div className={styles.eventInfo}>
          <span className={styles.taskName} title={event.task.title}>
            {event.task.title}
          </span>
          <div className={styles.progressInfo}>
            <div className={styles.progressBar}>
              <div
                className={styles.progressFill}
                style={{
                  width: `${progressPercent}%`,
                  backgroundColor: zoneColor.monochromatic(10)[8].toHexString(),
                }}
              />
            </div>
            <div className={styles.timeStats}>
              <span className={styles.timeSpent}>
                {timeSpent.toFormat("hh:mm:ss")}
              </span>
              <span className={styles.timeRemaining}>
                {remainingTime.toFormat("hh:mm:ss")}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          {executingActivity?.type === "focus" &&
          executingActivity.sessionId === event.sessionId ? (
            <>
              <IconButton
                variant="primary"
                onClick={() =>
                  flow(async function* () {
                    yield executingActivity!.complete();
                    yield scheduler.schedule();
                  })()
                }
                title="Завершить задачу"
              >
                <BsCheckCircle size={20} />
              </IconButton>
              <IconButton
                variant="danger"
                onClick={() =>
                  flow(async function* () {
                    yield executingActivity!.stop();
                    yield scheduler.schedule();
                  })()
                }
                title="Остановить"
              >
                <BsStopCircle size={20} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                variant="primary"
                onClick={() =>
                  flow(function* () {
                    yield activitiesStore.startFocusActivityOnEvent(event);
                    yield scheduler.schedule();
                  })()
                }
                title="Начать фокус"
              >
                <BsPlayCircle size={20} />
              </IconButton>
              {isRecurringTask(event.task.task) && (
                <IconButton
                  variant="secondary"
                  onClick={() =>
                    flow(async function* () {
                      yield event.task.skipSession(event.session);
                      yield scheduler.schedule();
                    })()
                  }
                  title="Пропустить задачу"
                >
                  <BsSkipEndCircle size={20} />
                </IconButton>
              )}
            </>
          )}
        </div>
      </motion.div>
    </ContextMenu>
  );
});
