import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { useRenderCount } from "@react-hookz/web";
import { clsx } from "clsx";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { MdCallMerge, MdCallSplit } from "react-icons/md";

import { TaskModel } from "entities/tasks/model/task-model";
import { ZonesStore } from "entities/zones";

import { useDi } from "shared/di";

import styles from "./task.module.scss";

import { useTaskDrag } from "../model/use-task-drag";

interface PrioritizedTaskProps {
  task: TaskModel;
  onSplit: () => void;
  onMerge?: () => void;
  onPrioritize: (target: TaskModel, position: "before" | "after") => void;
  onGroupChange?: (groupId: string | null) => void;
}

export const Task = observer(function Task({
  task,
  onPrioritize,
  onSplit,
  onMerge,
  onGroupChange,
}: PrioritizedTaskProps) {
  const zonesStore = useDi().get(ZonesStore);

  const ref = useRef<HTMLDivElement>(null);
  const renderCount = useRenderCount();
  const { closestEdge } = useTaskDrag({
    ref,
    task,
    onPrioritize,
    onGroupChange,
  });

  return (
    <motion.div
      ref={ref}
      layout
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { delay: renderCount === 1 ? 0 : 0.3 },
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <div className={styles.task}>
        <div className={styles.controls}>
          <MdCallSplit
            onClick={onSplit}
            className={clsx(styles.split, {
              [styles.hidden]:
                task.getChildrenByLocation("outline").length === 0,
            })}
          />
          <MdCallMerge
            onClick={onMerge}
            className={clsx(styles.merge, {
              [styles.hidden]: !task.task.priorityInGroup && !task.parent,
            })}
          />
        </div>
        <div
          className={styles.zone}
          style={{
            backgroundColor: !task.zoneId
              ? "transparent"
              : zonesStore.zoneColorById.get(task.zoneId)?.toHexString(),
          }}
        />
        <div className={styles.details}>
          <span
            className={clsx(styles.name, {
              [styles.completed]: task.isCompleted,
            })}
          >
            {task.task.name}
          </span>
          {task.parent && (
            <div
              className={clsx(styles.parent, {
                [styles.completed]: task.isCompleted,
              })}
            >
              {task.parent.title}
            </div>
          )}
          <BsArrowRepeat
            className={clsx(styles.repeat, {
              [styles.available]:
                task.getChildrenByLocation("outline").length === 0 &&
                task.task.plan?.type === "recurring",
            })}
          />
        </div>
      </div>
      {closestEdge && <DropIndicator edge={closestEdge} />}
    </motion.div>
  );
});
