import { inject, injectable } from "inversify";
import { flowResult, makeAutoObservable } from "mobx";
import tinycolor from "tinycolor2";
import { v4 as uuid } from "uuid";

import { TasksStore } from "entities/tasks";

import { getDefaultColor } from "shared/colors";
import { ZoneEntity, database } from "shared/database";

import { ZoneModel } from "./zone-model";

@injectable()
export class ZonesStore {
  public zoneById: Map<string, ZoneModel> = new Map();

  public get zones() {
    return Array.from(this.zoneById.values());
  }

  public get zoneColorById() {
    return new Map(
      this.zones
        .filter((zone) => zone.color)
        .map((zone) => [zone.id, tinycolor(zone.color)]),
    );
  }

  public tryFindZoneByName(name: string) {
    return this.zones.find((z) =>
      z.name.toLowerCase().includes(name.toLowerCase()),
    );
  }

  public constructor(
    @inject(TasksStore) private readonly tasksStore: TasksStore,
  ) {
    makeAutoObservable(this);
  }

  public *loadZones() {
    const zones = (yield database.zones.toArray()) as ZoneEntity[];
    this.zoneById = new Map(
      zones.map((zone) => [zone.id, new ZoneModel(zone)]),
    );
  }

  public *createEmptyZone() {
    const newZone = {
      id: uuid(),
      name: "Новая зона",
      color: getDefaultColor().toHexString(),
      dayMinutesLimit: null,
      weekMinutesLimit: null,
      mondayMinutesLimit: null,
      tuesdayMinutesLimit: null,
      wednesdayMinutesLimit: null,
      thursdayMinutesLimit: null,
      fridayMinutesLimit: null,
      saturdayMinutesLimit: null,
      sundayMinutesLimit: null,
    };

    yield database.zones.add(newZone);

    this.zoneById.set(newZone.id, new ZoneModel(newZone));
  }

  public *remove(zone: ZoneModel) {
    try {
      this.zoneById.delete(zone.id);

      yield database.transaction(
        "readwrite",
        [database.zones, database.tasks],
        async () => {
          await database.zones.delete(zone.id);
          await flowResult(this.tasksStore.resetZoneIdsForTasksInZone(zone.id));
        },
      );
    } catch (error) {
      this.zoneById.set(zone.id, zone);
      throw error;
    }
  }
}
