import ls from "localstorage-slim";

import { NotificationModel, Notifications } from "entities/notifications";

import { EstimatedActivityModel } from "./activity-model";

export function scheduleNotifications(
  notifications: Notifications,
  activity: EstimatedActivityModel,
) {
  unscheduleNotifications(notifications);

  const warningNotification = new NotificationModel(
    "Время почти истекло!",
    `Осталась одна минута до завершения "${activity.name}"`,
    activity.estimatedEndDateTime.minus({ minutes: 1 }),
  );

  notifications.schedule(warningNotification);

  const timeUpNotification = new NotificationModel(
    "Время истекло!",
    `Вышло время на "${activity.name}" (${activity.estimatedDuration.toFormat("mm:ss")})`,
    activity.estimatedEndDateTime,
  );

  notifications.schedule(timeUpNotification);

  ls.set(`notifications:executing-activity`, [
    warningNotification.id,
    timeUpNotification.id,
  ]);
}

export function unscheduleNotifications(notifications: Notifications) {
  const [warningNotificationId, timeUpNotificationId] =
    ls.get<[string, string]>(`notifications:executing-activity`) ?? [];

  if (warningNotificationId)
    notifications.unscheduleById(warningNotificationId);

  if (timeUpNotificationId) notifications.unscheduleById(timeUpNotificationId);

  ls.set(`notifications:executing-activity`, []);
}
