import { DateTime, Duration } from "luxon";
import { computed, flow, makeObservable, observable, override } from "mobx";
import { now } from "mobx-utils";

import { EventsStore } from "entities/events";
import { Notifications } from "entities/notifications";
import { TasksStore } from "entities/tasks";

import {
  AdHocActivity,
  AwayActivity,
  BaseActivity,
  FocusActivity,
  database,
} from "shared/database";

import { ActivitiesStore } from "./";
import { unscheduleNotifications } from "./notifications";

export abstract class BaseActivityModel {
  public abstract readonly type: string;

  public get id() {
    return this.activity.id;
  }

  public abstract get name(): string;

  public get startDateTime() {
    return DateTime.fromJSDate(this.activity.startDate);
  }

  public get actualEndDateTime() {
    return this.activity.actualEndDate
      ? DateTime.fromJSDate(this.activity.actualEndDate)
      : null;
  }

  public get isExecuting() {
    return this.actualEndDateTime === null;
  }

  public get actualDuration() {
    if (this.actualEndDateTime) {
      return this.actualEndDateTime.diff(this.startDateTime);
    }

    return DateTime.fromMillis(now()).diff(this.startDateTime);
  }

  public getActualDurationInDay(day: DateTime) {
    const start = DateTime.max(day.startOf("day"), this.startDateTime);
    const end = DateTime.min(
      day.endOf("day"),
      this.actualEndDateTime ?? DateTime.fromMillis(now()),
    );

    if (end < start) return Duration.fromMillis(0);
    return end.diff(start);
  }

  constructor(
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly tasksStore: TasksStore,
    protected readonly notifications: Notifications,
    protected readonly activity: BaseActivity,
  ) {
    makeObservable<BaseActivityModel>(this, {
      id: computed,
      startDateTime: computed,
      actualEndDateTime: computed,
      actualDuration: computed,
      isExecuting: computed,
      interrupt: flow,
    });
  }

  public *interrupt(): Generator<unknown, void, unknown> {
    if (!this.isExecuting) throw new Error("Активность не выполняется");
    if (this.actualEndDateTime) throw new Error("Активность уже завершена");

    const nowDate = new Date(now());

    yield database.activities.update(this.id, {
      actualEndDate: nowDate,
    });

    this.activity.actualEndDate = nowDate;
  }
}

export abstract class EstimatedActivityModel extends BaseActivityModel {
  public get estimatedEndDateTime() {
    return DateTime.fromJSDate(this.activity.estimatedEndDate);
  }

  public get estimatedDuration() {
    return this.estimatedEndDateTime.diff(this.startDateTime);
  }

  public get isOvertime() {
    return (
      this.estimatedEndDateTime.diff(DateTime.fromMillis(now())).toMillis() < 0
    );
  }

  public get remainingDuration() {
    const diffNow = this.estimatedEndDateTime.diff(DateTime.fromMillis(now()));

    return diffNow.toMillis() < 0 ? Duration.fromMillis(0) : diffNow;
  }

  public get overtimeDuration() {
    if (this.actualEndDateTime) {
      return this.actualEndDateTime > this.estimatedEndDateTime
        ? this.actualEndDateTime.diff(this.estimatedEndDateTime)
        : Duration.fromMillis(0);
    }

    const nowDateTime = DateTime.fromMillis(now());
    if (nowDateTime > this.estimatedEndDateTime)
      return this.estimatedEndDateTime.diff(nowDateTime).negate();

    return Duration.fromMillis(0);
  }

  constructor(
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly tasksStore: TasksStore,
    protected readonly notifications: Notifications,
    protected readonly activity: FocusActivity | AdHocActivity,
  ) {
    super(activitiesStore, tasksStore, notifications, activity);

    makeObservable<EstimatedActivityModel>(this, {
      estimatedEndDateTime: computed,
      estimatedDuration: computed,
      isOvertime: computed,
      remainingDuration: computed,
      overtimeDuration: computed,
      stop: flow,
    });
  }

  public *stop(): Generator<unknown, void, unknown> {
    yield this.interrupt();
    yield this.activitiesStore.startAwayActivity();

    unscheduleNotifications(this.notifications);
  }
}

export class FocusActivityModel extends EstimatedActivityModel {
  public readonly type = "focus" as const;

  public get name() {
    return this.task.title;
  }

  public get sessionId() {
    return this.activity.sessionId;
  }

  public get session() {
    const session = this.task.task.plan?.sessions.find(
      (s) => s.id === this.sessionId,
    );

    if (!session)
      throw new Error(`Session with id ${this.sessionId} not found`);

    return session;
  }

  public get taskId() {
    return this.activity.taskId;
  }

  public get task() {
    const task = this.tasksStore.taskById.get(this.taskId);
    if (!task) throw new Error("Task not found");

    return task;
  }

  public get eventId() {
    return this.activity.eventId;
  }

  public get event() {
    if (!this.eventId) return null;

    const event = this.eventsStore.eventById.get(this.eventId);
    if (!event) throw new Error("Event not found");

    return event;
  }

  constructor(
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly tasksStore: TasksStore,
    protected readonly eventsStore: EventsStore,
    protected readonly notifications: Notifications,
    protected readonly activity: FocusActivity,
  ) {
    super(activitiesStore, tasksStore, notifications, activity);

    makeObservable<FocusActivityModel, "activity">(this, {
      activity: observable,
      type: observable.ref,
      name: computed,
      sessionId: computed,
      session: computed,
      taskId: computed,
      task: computed,
      complete: flow,
      interrupt: override,
    });
  }

  public *interrupt() {
    yield super.interrupt();

    if (this.event) {
      yield this.event.unpin();
    }
  }

  public *complete() {
    yield this.stop();
    yield this.task.completeSession(this.session, new Date());

    unscheduleNotifications(this.notifications);
  }
}

export class AwayActivityModel extends BaseActivityModel {
  public readonly type = "away" as const;

  public get name() {
    return "Перерыв";
  }

  constructor(
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly tasksStore: TasksStore,
    protected readonly notifications: Notifications,
    protected readonly activity: AwayActivity,
  ) {
    super(activitiesStore, tasksStore, notifications, activity);

    makeObservable<AwayActivityModel, "activity">(this, {
      activity: observable,
      type: observable.ref,
    });
  }
}

export class AdHocActivityModel extends EstimatedActivityModel {
  public readonly type = "ad-hoc" as const;

  public get name() {
    return this.activity.reason;
  }

  public get reason() {
    return this.activity.reason;
  }

  constructor(
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly tasksStore: TasksStore,
    protected readonly notifications: Notifications,
    protected readonly activity: AdHocActivity,
  ) {
    super(activitiesStore, tasksStore, notifications, activity);

    makeObservable<AdHocActivityModel, "activity">(this, {
      activity: observable,
      type: observable.ref,
      reason: computed,
    });
  }
}

export type ActivityModel =
  | FocusActivityModel
  | AwayActivityModel
  | AdHocActivityModel;
