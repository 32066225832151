import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { $rootTextContent } from "@lexical/text";
import { clsx } from "clsx";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { observer } from "mobx-react-lite";
import pLimit from "p-limit";
import { useCallback, useEffect, useState } from "react";

import { TaskModel } from "entities/tasks";
import { ZonesStore } from "entities/zones";

import { useDi } from "shared/di";
import {
  DurationNode,
  DurationsPlugin,
  PreventEnterPlugin,
} from "shared/libs/lexical";

import styles from "./task-title.module.scss";

const fullTimeRegexp = /(\d+)ч\s*(\d+)м\s/i;
const hoursRegexp = /(\d+)ч\s/i;
const minutesRegexp = /(\d+)м\s/i;
const zoneRegexp = /\/(.+)\s/i;

const updateTaskLimit = pLimit(1);

async function throttledUpdateTask(
  task: TaskModel,
  name: string,
  duration: number,
  zoneId?: string,
) {
  updateTaskLimit.clearQueue();
  await updateTaskLimit(() => task.update({ name, zoneId, duration }));
}

interface EditableTaskTitleProps {
  task: TaskModel;
}

const EditableTaskTitle = observer(function EditableTaskTitle({
  task,
}: EditableTaskTitleProps) {
  const zonesStore = useDi().get(ZonesStore);

  const [title, setTitle] = useState<string>(task?.title ?? "");
  const [duration, setDuration] = useState<number>(task.task.duration);
  const [zoneId, setZoneId] = useState<string | undefined>(task.task.zoneId);

  useEffect(() => {
    void throttledUpdateTask(task, title, duration, zoneId);
  }, [duration, task, title, zoneId]);

  const handleChangedTitle = useCallback(
    async (newTitle: string) => {
      if (!task) return;

      const fullTimeParsed = fullTimeRegexp.exec(newTitle);
      if (fullTimeParsed) {
        const hours = Number.parseInt(fullTimeParsed[1], 10);
        const minutes = Number.parseInt(fullTimeParsed[2], 10);

        setDuration(hours * 60 + minutes);
        newTitle = newTitle.replace(fullTimeRegexp, "");
      }

      const hoursParsed = hoursRegexp.exec(newTitle);
      if (hoursParsed) {
        const hours = Number.parseInt(hoursParsed[1], 10);

        setDuration(hours * 60);
        newTitle = newTitle.replace(hoursRegexp, "");
      }

      const minutesParsed = minutesRegexp.exec(newTitle);
      if (minutesParsed) {
        const minutes = Number.parseInt(minutesParsed[1], 10);

        setDuration(minutes);
        newTitle = newTitle.replace(minutesRegexp, "");
      }

      const zoneParsed = zoneRegexp.exec(newTitle);
      if (zoneParsed) {
        const zone = zonesStore.tryFindZoneByName(zoneParsed[1]);

        if (zone) {
          setZoneId(zone.id);
          newTitle = newTitle.replace(zoneRegexp, "");
        } else if (zoneParsed[1] === "null") {
          setZoneId(undefined);
          newTitle = newTitle.replace(zoneRegexp, "");
        }
      }

      setTitle(newTitle.trim());
    },
    [task],
  );

  const taskTitle = task.title;

  return (
    <LexicalComposer
      initialConfig={{
        namespace: "Task title",
        onError: console.error,
        nodes: [DurationNode],
        editorState: () => {
          const paragraph = $createParagraphNode();
          paragraph.append($createTextNode(taskTitle));
          $getRoot().append(paragraph);
          $getRoot().selectEnd();
        },
      }}
    >
      <PlainTextPlugin
        contentEditable={
          <ContentEditable className={clsx(styles.title, styles.editing)} />
        }
        placeholder={<div className={styles.placeholder}>Название задачи</div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <DurationsPlugin
        fullTimeRegexp={fullTimeRegexp}
        hoursRegexp={hoursRegexp}
        minutesRegexp={minutesRegexp}
      />
      <PreventEnterPlugin />
      <OnChangePlugin
        onChange={(editorState) => {
          editorState.read(() => {
            void handleChangedTitle($rootTextContent());
          });
        }}
      />
      <HistoryPlugin />
    </LexicalComposer>
  );
});

interface TaskTitleProps {
  task: TaskModel;
  isEditing?: boolean;
  className?: string;
}

export const TaskTitle = observer(function TaskTitle({
  task,
  isEditing,
  className,
}: TaskTitleProps) {
  const isTitleEmpty = task.title === "";

  return (
    <div
      className={clsx(styles.title, className, {
        [styles.empty]: isTitleEmpty,
        [styles.parent]: task.getChildrenByLocation(task.location).length > 0,
      })}
    >
      {isEditing ? (
        <EditableTaskTitle task={task} />
      ) : (
        <span title={task.title}>
          {isTitleEmpty ? "Задача без названия" : task.title}
        </span>
      )}
    </div>
  );
});
