import { DateTime } from "luxon";
import { Valid } from "luxon/src/_util";
import { v4 } from "uuid";

import { getDayBoundaries, getWeekdayZoneMinutesLimit } from "entities/zones";

import { Session, TimeMapEntity, ZoneEntity } from "shared/database";

import { Event } from "./event";
import { EventsStore } from "./events-store";
import { TimeGridEvent } from "./time-grid-event";
import { UnableToScheduleError } from "./unable-to-schedule-error";

interface PlanningContext {
  session: Session;
  taskName: string;
  taskId: string;
  zoneId?: string;
}

export class TimeGrid {
  private eventsStore: EventsStore;

  constructor(
    private readonly now: DateTime,
    private readonly timeMap: TimeMapEntity,
    private readonly zones: (ZoneEntity & {
      actualDayMinutes: Map<string, number>;
    })[],
    pinnedEvents: Omit<Event, "isPinned">[],
  ) {
    this.eventsStore = new EventsStore(this.timeMap);

    this.eventsStore.addEvents(
      pinnedEvents.map((event) => ({
        ...event,
        isPinned: true,
        duration: event.endDate.diff(event.startDate).as("minutes"),
      })),
    );
  }

  public hasScheduledSession(session: Session) {
    return this.eventsStore.hasScheduledSession(session);
  }

  public getScheduledEvents() {
    return this.eventsStore.getAll();
  }

  public tryRescheduleSessionLater(
    context: PlanningContext,
    startDay: DateTime,
    endDay: DateTime,
  ): Event | UnableToScheduleError {
    if (!this.eventsStore.hasScheduledSession(context.session)) {
      return {
        reason: `Не найдено свободное время в диапазоне ${startDay?.toISODate()} - ${endDay?.toISODate()}`,
        taskId: context.taskId,
      };
    }

    const eventWithSession = this.eventsStore.removeForSession(context.session);

    return this.tryReserveLatestEvent(
      context,
      endDay,
      eventWithSession.duration,
      startDay,
    );
  }

  public tryRescheduleSessionEarlier(
    context: PlanningContext,
    startDay: DateTime,
    endDay: DateTime,
  ): Event | UnableToScheduleError {
    if (!this.eventsStore.hasScheduledSession(context.session)) {
      return {
        reason: `Не найдено свободное время в диапазоне ${startDay?.toISODate() ?? "сегодня"} - ${endDay?.toISODate() ?? "следующих 60 дней"}`,
        taskId: context.taskId,
      };
    }

    const eventWithSession = this.eventsStore.removeForSession(context.session);

    return this.tryReserveEarliestEvent(
      context,
      eventWithSession.duration,
      startDay,
      endDay,
    );
  }

  public tryReserveEarliestEvent(
    context: PlanningContext,
    minutes: number,
    startDay: DateTime,
    endDay: DateTime,
  ): Event | UnableToScheduleError {
    const innerErrors: UnableToScheduleError[] = [];

    for (
      let currentDay = startDay;
      currentDay <= endDay;
      currentDay = currentDay.plus({ day: 1 })
    ) {
      const reservedEventOrError = this.tryReserveEarliestEventInDay(
        context,
        currentDay,
        minutes,
      );

      if ("reason" in reservedEventOrError) {
        innerErrors.push(reservedEventOrError);
      } else {
        return reservedEventOrError;
      }
    }

    return {
      reason: `Не найдено свободное время в диапазоне ${startDay?.toISODate() ?? "сегодня"} - ${endDay?.toISODate() ?? "следующих 60 дней"}`,
      taskId: context.taskId,
      innerErrors,
    };
  }

  private tryReserveEarliestEventInDay(
    context: PlanningContext,
    day: DateTime<Valid>,
    minutes: number,
  ): Event | UnableToScheduleError {
    if (context.zoneId && !this.isTaskFitsZoneLimit(context, day, minutes)) {
      return {
        reason: `Задаче не хватило времени в рамках дня ${day.toISODate()} в зоне ${this.zones.find((z) => z.id === context.zoneId)?.name}`,
        taskId: context.taskId,
      };
    }

    const { start: dayStart, end: dayEnd } = getDayBoundaries(
      this.timeMap,
      day,
      this.now,
    );

    const dayEvents = this.eventsStore.getForDay(day);

    const reservedDatePoints = [
      dayStart,
      ...dayEvents
        .filter(
          (e) =>
            !e.isPinned || (e.endDate >= dayStart && e.startDate <= dayEnd),
        )
        .sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis())
        .flatMap((tb) => [tb.startDate, tb.endDate]),
      dayEnd,
    ];

    for (
      let datePointPairIndex = 0;
      datePointPairIndex < reservedDatePoints.length;
      datePointPairIndex += 2
    ) {
      const [startingFreeDate, endingFreeDate] = [
        reservedDatePoints[datePointPairIndex],
        reservedDatePoints[datePointPairIndex + 1],
      ];

      if (endingFreeDate.diff(startingFreeDate).as("minutes") >= minutes) {
        const event: TimeGridEvent = {
          id: v4(),
          startDate: startingFreeDate,
          endDate: startingFreeDate.plus({ minutes }),
          sessionId: context.session.id,
          taskId: context.taskId,
          zoneId: context.zoneId,
          isPinned: false,
          duration: minutes,
        };

        this.eventsStore.add(event);

        return event;
      }
    }

    return {
      reason: `Не найдено свободное время в рамках дня ${day.toISODate()}`,
      taskId: context.taskId,
    };
  }

  public tryReserveLatestEvent(
    context: PlanningContext,
    endDay: DateTime,
    minutes: number,
    startDay: DateTime,
  ): Event | UnableToScheduleError {
    if (endDay < startDay) {
      throw new Error(
        `End day ${endDay} is earlier than start day ${startDay}`,
      );
    }

    if (minutes < 1) throw new Error(`Minutes are less than 1: ${minutes}`);

    const innerErrors: UnableToScheduleError[] = [];

    for (
      let currentDay = endDay;
      currentDay >= startDay;
      currentDay = currentDay.minus({ day: 1 })
    ) {
      const reservedEventOrError = this.tryReserveLatestEventInDay(
        context,
        currentDay,
        minutes,
      );

      if ("reason" in reservedEventOrError) {
        innerErrors.push(reservedEventOrError);
      } else {
        return reservedEventOrError;
      }
    }

    return {
      reason: `Не найдено время в диапазоне ${startDay.toISODate()} - ${endDay.toISODate()}`,
      taskId: context.taskId,
      innerErrors,
    };
  }

  private tryReserveLatestEventInDay(
    context: PlanningContext,
    day: DateTime<Valid>,
    minutes: number,
  ): Event | UnableToScheduleError {
    if (context.zoneId && !this.isTaskFitsZoneLimit(context, day, minutes)) {
      return {
        reason: `Задаче не хватило времени в рамках дня ${day.toISODate()} в зоне ${this.zones.find((z) => z.id === context.zoneId)?.name}`,
        taskId: context.taskId,
      };
    }

    const { start: dayStart, end: dayEnd } = getDayBoundaries(
      this.timeMap,
      day,
      this.now,
    );

    const dayEvents = this.eventsStore.getForDay(day);

    const reservedDatePoints = [
      dayEnd,
      ...dayEvents
        .filter(
          (e) =>
            !e.isPinned || (e.endDate >= dayStart && e.startDate <= dayEnd),
        )
        .sort((a, b) => b.endDate.toMillis() - a.endDate.toMillis())
        .flatMap((tb) => [tb.endDate, tb.startDate]),
      dayStart,
    ];

    for (
      let datePointPairIndex = 0;
      datePointPairIndex < reservedDatePoints.length;
      datePointPairIndex += 2
    ) {
      const [endingFreeDate, startingFreeDate] = [
        reservedDatePoints[datePointPairIndex],
        reservedDatePoints[datePointPairIndex + 1],
      ];

      if (endingFreeDate.diff(startingFreeDate).as("minutes") >= minutes) {
        const event: TimeGridEvent = {
          id: v4(),
          startDate: endingFreeDate.minus({ minutes }),
          endDate: endingFreeDate,
          sessionId: context.session.id,
          taskId: context.taskId,
          zoneId: context.zoneId,
          isPinned: false,
          duration: minutes,
        };

        this.eventsStore.add(event);

        return event;
      }
    }

    return {
      reason: `Не найдено свободное время в рамках дня ${day.toISODate()}`,
      taskId: context.taskId,
    };
  }

  private isTaskFitsZoneLimit(
    context: PlanningContext,
    day: DateTime,
    duration: number,
  ) {
    const zone = this.zones.find((z) => z.id === context.zoneId);
    if (!zone) throw new Error(`Can't find zone with id ${context.zoneId}`);

    const reservedDayMinutes = this.calculateDayZoneMinutes(day, zone.id);

    const weekdayMinutesLimit = getWeekdayZoneMinutesLimit(day, zone);
    if (
      weekdayMinutesLimit !== null &&
      reservedDayMinutes + duration > weekdayMinutesLimit
    ) {
      return false;
    }

    const dayMinutesLimit = zone.dayMinutesLimit;
    if (
      dayMinutesLimit !== null &&
      reservedDayMinutes + duration > dayMinutesLimit
    ) {
      return false;
    }

    const weekMinutesLimit = zone.weekMinutesLimit;
    if (weekMinutesLimit !== null) {
      const weekStart = day.startOf("week");
      const weekEnd = day.endOf("week");

      let reservedWeekMinutes = 0;
      for (
        let currentDay = weekStart;
        currentDay <= weekEnd;
        currentDay = currentDay.plus({ days: 1 })
      ) {
        reservedWeekMinutes += this.calculateDayZoneMinutes(
          currentDay,
          zone.id,
        );
      }

      if (reservedWeekMinutes + duration > weekMinutesLimit) {
        return false;
      }
    }

    return true;
  }

  private calculateDayZoneMinutes(day: DateTime<Valid>, zoneId: string) {
    const zoneActualMinutes =
      this.zones
        .find((z) => z.id === zoneId)
        ?.actualDayMinutes.get(day.toISODate()) ?? 0;

    const plannedEventsMinutes =
      this.eventsStore
        .getForDay(day)
        .filter((e) => e.zoneId === zoneId)
        .reduce((p, e) => p + e.duration, 0) ?? 0;

    return plannedEventsMinutes + zoneActualMinutes;
  }
}
