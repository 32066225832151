import { IoIosRefresh } from "react-icons/io";

import { useDi } from "shared/di";
import { ControlPanel } from "shared/ui/control-panel";

import { Scheduler } from "../model";

export function ScheduleButton() {
  const scheduler = useDi().get(Scheduler);

  return (
    <ControlPanel.Button
      icon={IoIosRefresh}
      onClick={() => scheduler.schedule()}
      hotkey="Meta+S"
      label="Пересчитать расписание"
    />
  );
}
