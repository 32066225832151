import { injectable } from "inversify";
import { makeAutoObservable } from "mobx";
import { v4 } from "uuid";

import { TimeMapEntity, database } from "shared/database";

import { TimeMapModel } from "./time-map-model";

@injectable()
export class TimeMapsStore {
  public timeMaps: TimeMapModel[] = [];

  public constructor() {
    makeAutoObservable(this);
  }

  public *loadTimeMaps() {
    const timeMaps = (yield database.timeMaps.toArray()) as TimeMapEntity[];

    this.timeMaps = timeMaps.map((t) => new TimeMapModel(this, t));
  }

  public get defaultTimeMap() {
    return this.timeMaps.find((t) => t.isDefault) ?? null;
  }

  public *create(timeMap: Omit<TimeMapEntity, "id" | "isDefault">) {
    const newTimeMap = {
      ...timeMap,
      id: v4(),
      isDefault: !this.timeMaps.length,
    };

    yield database.timeMaps.add(newTimeMap);

    this.timeMaps.push(new TimeMapModel(this, newTimeMap));
  }

  public *delete(timeMap: TimeMapModel) {
    if (this.defaultTimeMap?.id === timeMap.id) {
      const newDefaultTimeMap = this.timeMaps.find((t) => t.id !== timeMap.id);

      if (newDefaultTimeMap) yield newDefaultTimeMap.setDefault();
    }

    yield database.timeMaps.delete(timeMap.id);

    this.timeMaps = this.timeMaps.filter((t) => t.id !== timeMap.id);
  }
}
