import { flowResult } from "mobx";
import { useState } from "react";

import { ActivitiesStore } from "entities/activities";

import { useDi } from "shared/di";
import { Popover } from "shared/ui";
import { Button } from "shared/ui/button";
import { TextInput } from "shared/ui/text-input";

import styles from "./ad-hoc-modal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  targetElement: HTMLElement | null;
}

export function AdHocModal({
  isOpen,
  onClose,
  onSuccess,
  targetElement,
}: Props) {
  const activitiesStore = useDi().get(ActivitiesStore);

  const [reason, setReason] = useState("");
  const [duration, setDuration] = useState("30");

  async function handleSubmit() {
    if (!reason.trim()) return;

    const durationMinutes = parseInt(duration, 10);
    if (isNaN(durationMinutes) || durationMinutes <= 0) return;

    await flowResult(
      activitiesStore.startAdHocActivity(reason.trim(), durationMinutes),
    );

    setReason("");
    setDuration("30");
    onClose();
    if (onSuccess) onSuccess();
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Escape") {
      onClose();
    } else if (e.key === "Enter") {
      handleSubmit();
      e.stopPropagation();
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      targetElement={targetElement}
      className={styles.adHocPopover}
    >
      <div className={styles.content} onKeyDown={handleKeyDown}>
        <TextInput
          label="Причина"
          value={reason}
          onChange={setReason}
          autoFocus
        />
        <TextInput
          label="Длительность (минуты)"
          value={duration}
          onChange={(value) => {
            // Allow only numbers
            if (/^\d*$/.test(value)) {
              setDuration(value);
            }
          }}
        />
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={onClose}>
            Отменить
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Запустить
          </Button>
        </div>
      </div>
    </Popover>
  );
}
