import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export function useToday() {
  const [today, setToday] = useState(DateTime.now().startOf("day"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (DateTime.now().startOf("day").equals(today)) return;

      setToday(DateTime.now().startOf("day"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [today]);

  return today;
}
