import { clsx } from "clsx";
import { ReactNode } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./context-menu.module.scss";

import { useContextMenu } from "../model";

interface Props {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  shortcut?: string;
}

export function ContextMenuItem({
  children,
  onClick,
  disabled,
  shortcut,
}: Props) {
  const { close: onClose } = useContextMenu();

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
      onClose();
    }
  };

  useHotkeys(shortcut ?? "", handleClick, {
    enabled: !disabled && !!shortcut,
    preventDefault: true,
  });

  return (
    <div
      className={clsx(styles.menuItem, {
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
    >
      <span className={styles.title}>{children}</span>
      {shortcut && <span className={styles.shortcut}>{shortcut}</span>}
    </div>
  );
}

export function ContextMenuDivider() {
  return <div className={styles.divider} />;
}
