import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { flowResult, makeAutoObservable, runInAction } from "mobx";
import { entries, groupBy, mapValues, pipe, sum } from "remeda";
import { v4 } from "uuid";

import { ActivitiesStore, FocusActivityModel } from "entities/activities";
import { EventModel, EventsStore } from "entities/events";
import { TasksStore } from "entities/tasks";
import { sortByPriorityAndGroup } from "entities/tasks/model/sort";
import { TaskModel } from "entities/tasks/model/task-model";
import { TimeMapsStore } from "entities/time-maps";
import { ZonesStore, getDayFromDatetime } from "entities/zones";

import {
  PriorityGroupEntity,
  Session,
  ZoneEntity,
  database,
} from "shared/database";
import { roundMinutesToCeilingSlot } from "shared/libs/time";

import { Event } from "./event";
import { SchedulerSession, TaskPlanner } from "./task-planner";
import {
  UnableToScheduleError,
  stringifyUnableToScheduleError,
} from "./unable-to-schedule-error";

@injectable()
export class Scheduler {
  public isScheduling = false;

  constructor(
    @inject(TasksStore) private readonly tasksStore: TasksStore,
    @inject(ZonesStore) private readonly zonesStore: ZonesStore,
    @inject(EventsStore) private readonly eventsStore: EventsStore,
    @inject(ActivitiesStore) private readonly activitiesStore: ActivitiesStore,
    @inject(TimeMapsStore) private readonly timeMapsStore: TimeMapsStore,
  ) {
    makeAutoObservable(this);
  }

  public *schedule() {
    this.isScheduling = true;

    yield database.transaction(
      "readwrite",
      [
        database.tasks,
        database.events,
        database.timeMaps,
        database.suggestions,
        database.priorityGroups,
        database.activities,
      ],
      async () => {
        await flowResult(this.recalculateSessions());

        const uncompletedSessions = await flowResult(
          this.collectUncompletedSessions(),
        );

        const zones = await flowResult(this.collectZones(uncompletedSessions));

        const pinnedEvents = await flowResult(
          this.collectPinnedEvents(uncompletedSessions),
        );

        const { scheduledEvents, errors } = await flowResult(
          this.scheduleEvents(uncompletedSessions, zones, pinnedEvents),
        );

        await flowResult(this.updateSuggestions(errors));
        await flowResult(this.replaceEvents(scheduledEvents));

        runInAction(() => {
          this.isScheduling = false;
        });
      },
    );
  }

  private *recalculateSessions() {
    const tasksToUpdateSessions = this.tasksStore.getTasksInLocation("outline");

    yield Promise.all(tasksToUpdateSessions.map((t) => t.scheduleSessions()));

    yield this.eventsStore.clearEventsWithoutSessions();
    yield this.activitiesStore.clearActivitiesWithoutSessions();
  }

  private *collectUncompletedSessions() {
    const priorityGroups =
      (yield database.priorityGroups.toArray()) as PriorityGroupEntity[];

    const outlineTasks = this.tasksStore.getTasksInLocation("outline");

    const prioritizedTasks = outlineTasks
      .filter((t) => t.task.priorityInGroup !== undefined && !t.isPaused)
      .sort((a, b) => sortByPriorityAndGroup(a, b, priorityGroups))
      .flatMap(expandTaskTree);

    const unprioritizedTasks = outlineTasks
      .filter(
        (t) =>
          t.parent === null &&
          t.task.priorityInGroup === undefined &&
          !t.isPaused,
      )
      .flatMap(expandTaskTree);

    const taskQueue = prioritizedTasks.concat(unprioritizedTasks);

    const uncompletedSessions = taskQueue
      .flatMap((t) => t.uncompletedSessions)
      .filter((session) => !session.skipped);

    return uncompletedSessions;

    function expandTaskTree(task: TaskModel): TaskModel[] {
      const children = task.getChildrenByLocation("outline");
      if (children.length === 0) return [task];

      return children
        .filter((c) => c.task.priorityInGroup === undefined && !c.isPaused)
        .sort((a, b) => a.order - b.order)
        .flatMap(expandTaskTree);
    }
  }

  private *collectZones(uncompletedSessions: Session[]) {
    const uncompletedSessionIds = new Set(uncompletedSessions.map((s) => s.id));

    const focusActivities = this.activitiesStore.activities.filter(
      (a) => a.type === "focus",
    ) as FocusActivityModel[];

    const timeMap = this.timeMapsStore.defaultTimeMap;
    if (!timeMap) throw new Error("Time map not found");

    return this.zonesStore.zones.map((z) => {
      const zoneTaskIds = new Set(
        this.tasksStore
          .getTasksInLocation("outline")
          .filter((t) => t.zoneId === z.id)
          .map((t) => t.id),
      );

      return {
        ...z.zone,
        actualDayMinutes: new Map<string, number>(
          pipe(
            focusActivities.filter(
              (a) =>
                !uncompletedSessionIds.has(a.sessionId) &&
                zoneTaskIds.has(a.taskId),
            ),
            groupBy(
              (e) => getDayFromDatetime(timeMap, e.startDateTime).toISODate()!,
            ),
            mapValues((g) =>
              sum(
                g.map((a) =>
                  (a.actualEndDateTime ?? DateTime.now())
                    .diff(a.startDateTime)
                    .as("minutes"),
                ),
              ),
            ),
            entries(),
          ),
        ),
      };
    });
  }

  private *collectPinnedEvents(uncompletedSessions: Session[]) {
    const uncompletedSessionIds = new Set(uncompletedSessions.map((s) => s.id));

    return this.eventsStore.events.filter((e) => {
      const duration = e.endDateTime.diff(e.startDateTime).as("minutes");

      return (
        e.isPinned &&
        uncompletedSessionIds.has(e.sessionId) &&
        e.startDateTime.plus({
          minutes: roundMinutesToCeilingSlot(duration),
        }) > DateTime.now()
      );
    });
  }

  private *scheduleEvents(
    uncompletedSessions: SchedulerSession[],
    zones: (ZoneEntity & {
      actualDayMinutes: Map<string, number>;
    })[],
    pinnedEvents: EventModel[],
  ) {
    const scheduler = new TaskPlanner(uncompletedSessions, zones, pinnedEvents);

    const defaultTimeMap = this.timeMapsStore.defaultTimeMap;
    if (!defaultTimeMap) throw new Error("Default time map not found");

    const { events: scheduledEvents, errors } = scheduler.schedule(
      DateTime.now(),
      defaultTimeMap,
    );

    return { scheduledEvents, errors };
  }

  private *updateSuggestions(errors: UnableToScheduleError[]) {
    const suggestions = errors.map((e) => ({
      id: v4(),
      reason: e.reason,
      taskId: e.taskId,
      description: stringifyUnableToScheduleError(e),
      creationDateTimeUtc: DateTime.now().toJSDate(),
    }));

    yield database.transaction(
      "readwrite",
      [database.suggestions],
      async () => {
        await database.suggestions.clear();
        await database.suggestions.bulkAdd(suggestions);
      },
    );
  }

  private *replaceEvents(events: Event[]) {
    yield this.eventsStore.replaceEvents(
      events.map((e) => ({
        id: e.id,
        taskId: e.taskId,
        sessionId: e.sessionId,
        startDate: e.startDate.toJSDate(),
        endDate: e.endDate.toJSDate(),
        isPinned: e.isPinned,
      })),
    );
  }
}
