import { ReactNode, createContext, useContext, useMemo } from "react";

interface ContextMenuState {
  close: () => void;
}

const initialState: ContextMenuState = {
  close: () => {},
};

export const ContextMenuContext = createContext<ContextMenuState>(initialState);

interface Props {
  children: ReactNode;
  onClose: () => void;
}

export function ContextMenuProvider({ children, onClose }: Props) {
  const context = useMemo(() => ({ close: onClose }), [onClose]);

  return (
    <ContextMenuContext.Provider value={context}>
      {children}
    </ContextMenuContext.Provider>
  );
}

export function useContextMenu() {
  return useContext(ContextMenuContext);
}
