import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";

import { ActivityModel } from "entities/activities";

import styles from "./activity-popup.module.scss";

interface Props {
  activity: ActivityModel;
}

export const ActivityPopup = observer(function ActivityPopup({
  activity,
}: Props) {
  function getActivityTypeText() {
    switch (activity.type) {
      case "focus":
        return "Фокус";
      case "away":
        return "Перерыв";
      case "ad-hoc":
        return "Внеплановая активность";
      default:
        return "Неизвестно";
    }
  }

  function formatDateTime(dateTime: DateTime) {
    return dateTime.toFormat("HH:mm");
  }

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{activity.name}</h3>
      <div className={styles.info}>
        <div className={styles.infoItem}>
          <span className={styles.label}>Тип:</span>
          <span>{getActivityTypeText()}</span>
        </div>
        <div className={styles.infoItem}>
          <span className={styles.label}>Время:</span>
          <span>
            {formatDateTime(activity.startDateTime)} -{" "}
            {activity.actualEndDateTime
              ? formatDateTime(activity.actualEndDateTime)
              : "сейчас"}
          </span>
        </div>
      </div>
    </div>
  );
});
