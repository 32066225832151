import { ZonesStore } from "entities/zones";

import { getColor, getDefaultColor } from "shared/colors";
import { useDi } from "shared/di";

import { ActivityModel } from "./";

interface UseActivityColorProps {
  activity: ActivityModel;
}

export function useActivityColor({ activity }: UseActivityColorProps) {
  const zonesStore = useDi().get(ZonesStore);

  const zoneColor =
    activity.type === "focus" && activity.task.zoneId
      ? zonesStore.zoneColorById.get(activity.task.zoneId)
      : undefined;

  if (activity.type === "focus") return zoneColor ?? getColor("purple");
  if (activity.type === "away") return getColor("gray-300");
  if (activity.type === "ad-hoc") return getColor("warning");

  return getDefaultColor();
}
