import { clsx } from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import {
  BsArrowRepeat,
  BsCalendar2Check,
  BsCalendar2Minus,
} from "react-icons/bs";
import { IoMdPause } from "react-icons/io";
import { TbSum } from "react-icons/tb";

import { TaskModel } from "entities/tasks";
import { ZonesStore } from "entities/zones";

import { useDi } from "shared/di";
import { Popover } from "shared/ui";
import { RadioInput } from "shared/ui/radio-input";

import styles from "./task-settings.module.scss";

import { TaskPlanRadioInput } from "./task-plan-radio-input";

interface Props {
  task: TaskModel;
  className?: string;
}

export const TaskSettings = observer(function TaskSettings({
  task,
  className,
}: Props) {
  const zonesStore = useDi().get(ZonesStore);

  const [showPopover, setShowPopover] = useState(false);
  const [buttonElement, setButtonElement] = useState<HTMLDivElement | null>(
    null,
  );

  const hasChildren = task.getChildrenByLocation(task.location).length !== 0;

  return (
    <span className={clsx(styles.settings, className)}>
      <div
        ref={setButtonElement}
        className={styles.info}
        onClick={() => setShowPopover((prev) => !prev)}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <div className={styles.duration}>
          {hasChildren && <TbSum className={styles.sum} />}
          {formatDuration(task.duration.as("minutes"))}
        </div>
        <div
          className={clsx(styles.status, { [styles.isParent]: hasChildren })}
        >
          {task.isPaused ? (
            <IoMdPause className={styles.pause} />
          ) : task.task.plan === undefined ? (
            <BsCalendar2Minus className={styles.notPlanned} />
          ) : task.task.plan?.type === "flexible" ? (
            <BsCalendar2Check />
          ) : task.task.plan?.type === "recurring" ? (
            <BsArrowRepeat className={styles.repeat} />
          ) : null}
        </div>
        <div
          className={styles.zone}
          style={{
            backgroundColor: task.zoneId
              ? zonesStore.zoneColorById.get(task.zoneId)?.toHexString()
              : undefined,
          }}
        ></div>
      </div>
      <Popover
        targetElement={buttonElement}
        onClose={() => setShowPopover(false)}
        className={styles.popover}
        isOpen={showPopover}
      >
        <div
          className={styles.container}
          onPaste={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
        >
          <div className={styles.zone}>
            <RadioInput
              label="Зона"
              value={task.task.zoneId}
              onChange={(value) => task.update({ zoneId: value })}
            >
              <RadioInput.Option value={undefined}>
                Родительская
              </RadioInput.Option>
              {zonesStore.zones.map((zone) => (
                <RadioInput.Option
                  key={zone.id}
                  value={zone.id}
                  activeColor={zone.color.toHexString()}
                >
                  {zone.name}
                </RadioInput.Option>
              ))}
            </RadioInput>
          </div>
          <TaskPlanRadioInput key={task.task.plan?.type} task={task} />
        </div>
      </Popover>
    </span>
  );
});

function formatDuration(minutes: number) {
  if (minutes % 60 === 0) return `${minutes / 60}ч`;

  return minutes >= 60
    ? `${Math.floor(minutes / 60)}ч ${minutes % 60}м`
    : `${minutes}м`;
}
