import { motion } from "framer-motion";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { HiChevronRight } from "react-icons/hi";

import { TaskModel } from "entities/tasks";

import styles from "./task-collapse.module.scss";

interface Props {
  task: TaskModel;
}

export const TaskCollapse = observer(function TaskCollapse({ task }: Props) {
  if (task.isCompletable) return null;

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();

    if (task.isCollapsed) {
      void task.expand();
    } else {
      void task.collapse();
    }
  }

  return (
    <motion.div
      className={styles.collapse}
      initial={false}
      animate={{ rotate: task.isCollapsed ? 0 : 90 }}
      transition={{ duration: 0.1 }}
      onClick={(e) => runInAction(() => handleClick(e))}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <HiChevronRight className={styles.icon} />
    </motion.div>
  );
});
