import clsx from "clsx";
import { DateTime } from "luxon";
import { range } from "remeda";

import styles from "./day.module.scss";

import { Activities } from "./activities";
import { CurrentTimeDayBar } from "./current-time-day-bar";
import { Events } from "./events";

import { useToday } from "../../model";

interface Props {
  className?: string;
  startDay: DateTime;
  dayIndex: number;
  height: number;
  mode: "events" | "activities";
  onShowTaskInOutline: ((taskId: string) => void) | undefined;
  onDragStart: () => void;
  onDragEnd: () => void;
}

export function Day({
  className,
  startDay,
  dayIndex,
  height,
  mode,
  onShowTaskInOutline,
  onDragStart,
  onDragEnd,
}: Props) {
  const today = useToday();

  return (
    <div className={clsx(styles.day, className)}>
      <div className={styles.hoursGrid}>
        {range(0, 24).map((hourIndex) => (
          <div key={hourIndex} className={styles.hour} />
        ))}
      </div>
      <div className={styles.children}>
        {mode === "events" ? (
          <Events
            startDay={startDay}
            dayIndex={dayIndex}
            height={height}
            onShowTaskInOutline={onShowTaskInOutline}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        ) : (
          <Activities startDay={startDay} dayIndex={dayIndex} height={height} />
        )}
      </div>
      {today.equals(startDay.plus({ day: dayIndex })) && (
        <CurrentTimeDayBar height={height} />
      )}
    </div>
  );
}
