import { DateTime } from "luxon";
import { runInAction } from "mobx";
import { useCallback, useMemo } from "react";
import { Frequency, Options, RRule } from "rrule";

import { TaskModel, isRecurringTask } from "entities/tasks";

import { RecurringPlan } from "shared/database";
import { DatePicker } from "shared/ui/date-picker";
import { Dropdown } from "shared/ui/dropdown";
import { RadioInput } from "shared/ui/radio-input";
import { TextInput } from "shared/ui/text-input";

import styles from "./task-settings.module.scss";

interface Props {
  task: TaskModel;
}

export function TaskPlanRadioInput({ task }: Props) {
  const parsedOptions = useMemo(() => {
    if (!isRecurringTask(task.task)) return null;
    if (!task.task.plan?.rrule) return null;

    return RRule.parseString(task.task.plan.rrule.split(";;").join("\n"));
  }, [task.task.plan]);

  const futureEvents = useMemo(() => {
    if (!isRecurringTask(task.task)) return [];
    if (!parsedOptions) return [];

    const results = [];

    const rRule = new RRule(parsedOptions);

    let currentDay = parsedOptions.dtstart ?? new Date();
    while (results.length < 10) {
      const nextDate = rRule.after(currentDay, results.length === 0);
      if (!nextDate) break;

      results.push(nextDate);
      currentDay = nextDate;
    }

    return results.map((date) => {
      const dateTime = DateTime.fromJSDate(date);
      return dateTime.minus({ minutes: dateTime.offset });
    });
  }, [task.task, parsedOptions]);

  const duration = useMemo(() => {
    if (!isRecurringTask(task.task)) return null;

    if (task.task.plan.groupType === "day") return { days: 0 };
    if (task.task.plan.groupType === "week") return { days: -1, weeks: 1 };
    if (task.task.plan.groupType === "month") return { days: -1, months: 1 };
    if (task.task.plan.groupType === "year") return { days: -1, years: 1 };

    return null;
  }, [task.task]);

  const updatePlan = useCallback(
    async ({
      groupType,
      options,
    }: {
      groupType?: RecurringPlan["groupType"];
      options?: Partial<Options>;
    }) => {
      runInAction(async () => {
        if (!isRecurringTask(task.task))
          throw new Error("Task must be recurring");

        if (!task.task.plan) throw new Error("Task must have a plan");

        await task.setPlan({
          type: "recurring",
          groupType: groupType ?? task.task.plan.groupType,
          rrule: options ? new RRule(options).toString() : task.task.plan.rrule,
        });
      });
    },
    [task.task],
  );

  return (
    <>
      <RadioInput<"notPlanned" | "flexible" | "recurring">
        label="Планирование"
        value={task.task.plan?.type ?? "notPlanned"}
        onChange={async (value) => {
          if (value === "notPlanned" || value === "flexible") {
            await task.setPlan({ type: value });
          } else {
            await task.setPlan({
              type: value,
              rrule: "RRULE:FREQ=DAILY;WKST=MO",
              groupType: "day",
            });
          }
        }}
      >
        <RadioInput.Option value="notPlanned">Не планировать</RadioInput.Option>
        <RadioInput.Option value="flexible">Гибкое</RadioInput.Option>
        <RadioInput.Option value="recurring">Повторяющееся</RadioInput.Option>
      </RadioInput>
      {isRecurringTask(task.task) && parsedOptions !== null && (
        <div className={styles.recurringOptions}>
          <DatePicker
            label="Начиная с"
            value={
              parsedOptions.dtstart
                ? DateTime.fromJSDate(parsedOptions.dtstart)
                : null
            }
            onChange={(value) => {
              const options = {
                ...parsedOptions,
                dtstart: value
                  .plus({ minutes: value.offset })
                  .toUTC()
                  .toJSDate(),
              };

              updatePlan({ options });
            }}
          />
          <Dropdown
            label="Выполнять каждый"
            value={
              parsedOptions?.freq?.toString() ?? Frequency.DAILY.toString()
            }
            options={[
              { value: Frequency.DAILY.toString(), label: "День" },
              { value: Frequency.WEEKLY.toString(), label: "Неделю" },
              { value: Frequency.MONTHLY.toString(), label: "Месяц" },
              { value: Frequency.YEARLY.toString(), label: "Год" },
            ]}
            onChange={(value) => {
              const parsedValue = parseInt(value);

              const options = {
                ...parsedOptions,
                freq: Number.isFinite(parsedValue) ? parsedValue : undefined,
              };

              updatePlan({ options });
            }}
          />
          <TextInput
            label="Каждые # (каждые 2 дня, каждую 3 неделю)"
            value={parsedOptions?.interval?.toString() ?? ""}
            onChange={(value) => {
              const parsedValue = parseInt(value);

              const options = {
                ...parsedOptions,
                interval: Number.isFinite(parsedValue)
                  ? parsedValue
                  : undefined,
              };

              updatePlan({ options });
            }}
          />
          <Dropdown
            label="Выполнить в течение"
            value={task.task.plan.groupType}
            options={
              [
                { value: "day", label: "Дня" },
                { value: "week", label: "Недели" },
                { value: "month", label: "Месяца" },
                { value: "year", label: "Года" },
              ] satisfies { value: RecurringPlan["groupType"]; label: string }[]
            }
            onChange={(value) => {
              updatePlan({ groupType: value as RecurringPlan["groupType"] });
            }}
          />
          {futureEvents.length > 0 && (
            <div className={styles.futureEvents}>
              <div className={styles.futureEventsTitle}>Будущие события:</div>
              <div className={styles.futureEventsList}>
                {futureEvents.map((event, index) => (
                  <div key={index} className={styles.futureEvent}>
                    {event.toFormat("dd.MM.yyyy")}-
                    {event.plus(duration ?? {}).toFormat("dd.MM.yyyy")}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
