import clsx from "clsx";
import { range } from "remeda";

import styles from "./time-column.module.scss";

interface TimeColumnProps {
  className?: string;
}

export function TimeColumn({ className }: TimeColumnProps) {
  return (
    <div className={clsx(styles.timeColumn, className)}>
      {range(0, 24).map((hour) => (
        <div key={hour} className={styles.hourRow}>
          <div className={styles.hour}>
            {hour.toString().padStart(2, "0")}:00
          </div>
        </div>
      ))}
    </div>
  );
}
