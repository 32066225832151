import { observer } from "mobx-react-lite";
import { useState } from "react";

import { ZonesStore } from "entities/zones";

import { useDi } from "shared/di";
import { Tags } from "shared/ui";

import styles from "./zones.module.scss";

import { ZoneInfo } from "./zone-info";

export const Zones = observer(function Zones() {
  const zonesStore = useDi().get(ZonesStore);

  const [selectedZoneId, setSelectedZoneId] = useState<string | undefined>(
    undefined,
  );

  const selectedZone =
    selectedZoneId && zonesStore.zoneById.has(selectedZoneId)
      ? zonesStore.zoneById.get(selectedZoneId)
      : zonesStore.zones[0];

  return (
    <div className={styles.zones}>
      <Tags
        value={selectedZone?.id}
        onChange={setSelectedZoneId}
        onAdd={async () => await zonesStore.createEmptyZone()}
      >
        {zonesStore.zones.map((zone) => (
          <Tags.Tag key={zone.id} value={zone.id}>
            {zone.name}
          </Tags.Tag>
        ))}
      </Tags>
      <div className={styles.zone}>
        <div>{selectedZone && <ZoneInfo selectedZone={selectedZone} />}</div>
      </div>
    </div>
  );
});
