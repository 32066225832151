import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { comparer, computed, makeAutoObservable, runInAction } from "mobx";
import { v4 } from "uuid";

import { JournalTaskEntity, TaskEntity, database } from "shared/database";

import { TaskModel } from "./task-model";

@injectable()
export class TasksStore {
  public taskById = new Map<TaskEntity["id"], TaskModel>();

  public constructor(
    @inject("Factory<TaskModel>")
    private readonly taskFactory: (task: TaskEntity) => TaskModel,
  ) {
    makeAutoObservable(this, {
      roots: computed({ equals: comparer.shallow }),
    });
  }

  public get roots() {
    return this.tasks.filter((t) => t.parentId === null);
  }

  private get rootsByLocation() {
    const rootsByLocation = new Map<TaskEntity["location"], TaskModel[]>();

    this.roots.forEach((t) => {
      if (!rootsByLocation.has(t.location)) rootsByLocation.set(t.location, []);

      rootsByLocation.get(t.location)?.push(t);
    });

    return rootsByLocation;
  }

  public getRootsInLocation(location: TaskEntity["location"]) {
    return this.rootsByLocation.get(location) ?? [];
  }

  public get tasks() {
    return Array.from(this.taskById.values()).sort((a, b) => a.order - b.order);
  }

  private get tasksByLocation() {
    const tasksByLocation = new Map<TaskEntity["location"], TaskModel[]>();

    this.tasks.forEach((t) => {
      if (!tasksByLocation.has(t.location)) tasksByLocation.set(t.location, []);

      tasksByLocation.get(t.location)?.push(t);
    });

    return tasksByLocation;
  }

  private get tasksByParentId() {
    const tasksByParentId = new Map<TaskEntity["parentId"], TaskModel[]>();

    this.tasks.forEach((t) => {
      if (!tasksByParentId.has(t.parentId)) tasksByParentId.set(t.parentId, []);

      tasksByParentId.get(t.parentId)?.push(t);
    });

    return tasksByParentId;
  }

  public getTasksInLocation(location: TaskEntity["location"]) {
    return this.tasksByLocation.get(location) ?? [];
  }

  public getTasksByParentId(parentId: TaskEntity["parentId"]) {
    return this.tasksByParentId.get(parentId) ?? [];
  }

  public getTasksInLocationAndParentId(
    location: TaskEntity["location"],
    parentId: TaskEntity["parentId"],
  ) {
    return (
      this.tasksByParentId
        .get(parentId)
        ?.filter((t) => t.location === location) ?? []
    );
  }

  public *loadTasks() {
    const tasks = (yield database.tasks.toArray()) as TaskEntity[];

    tasks.forEach((t) => {
      this.taskById.set(t.id, this.taskFactory(t));
    });
  }

  public async createTask(
    location: "inbox" | "outline",
    name: string,
    parentId: string | null,
    order: number,
  ) {
    const task = await database.transaction(
      "readwrite",
      [database.tasks],
      async () => {
        const task: TaskEntity = {
          id: v4(),
          name,
          order,
          parentId,
          duration: 60,
          collapsed: false,
          location,
          priorityGroupId: null,
        };
        await database.tasks.add(task);

        runInAction(() => {
          this.taskById.set(task.id, this.taskFactory(task));
        });

        await this.reorderTaskChildren(location, parentId, task.id, order);

        return task;
      },
    );

    return task;
  }

  public async reorderTaskChildren(
    location: "inbox" | "outline" | "journal",
    parentId: string | null,
    taskId?: string,
    taskSlot?: number,
  ) {
    const tasksToReorder = this.getTasksInLocationAndParentId(
      location,
      parentId,
    );

    let taskOrder = 0;
    const tasksOrders = tasksToReorder.map((t) => {
      if (taskOrder === taskSlot) taskOrder++;

      return {
        task: t,
        order: t.id === taskId ? taskSlot! : taskOrder++,
      };
    });

    const updates = tasksOrders.map(({ task, order }) => ({
      key: task.id,
      changes: {
        order,
      },
    }));

    await database.tasks.bulkUpdate(updates);

    runInAction(() => {
      tasksOrders.forEach(({ task, order }) => {
        task.task.order = order;
      });
    });
  }

  public *journalCompleted() {
    const inputTasks = this.getTasksInLocation("inbox");
    const outlineTasks = this.getTasksInLocation("outline");

    const unjournaledTasks = [...inputTasks, ...outlineTasks].filter(
      (t) => t.isCompleted,
    );

    const oldLocations = unjournaledTasks.map((t) => ({
      task: t,
      location: t.task.location,
    }));

    try {
      unjournaledTasks.forEach((t) => {
        t.task.location = "journal";
        (t.task as JournalTaskEntity).loggedAt = DateTime.now().toJSDate();
      });

      yield database.tasks.bulkUpdate(
        unjournaledTasks.map((t) => ({
          key: t.id,
          changes: {
            location: "journal",
            loggedAt: DateTime.now().toJSDate(),
          },
        })),
      );
    } catch (error) {
      oldLocations.forEach(({ task, location }) => {
        task.task.location = location;
        delete (task.task as { loggedAt?: Date }).loggedAt;
      });
      throw error;
    }
  }

  public *remove(task: TaskModel) {
    const taskId = task.id;
    const parentId = task.parentId;
    const location = task.location;
    const children = this.getTasksByParentId(taskId);
    const sessionIds = task.task.plan?.sessions.map((s) => s.id) ?? [];

    try {
      this.taskById.delete(taskId);

      yield database.transaction(
        "readwrite",
        [database.tasks, database.events, database.activities],
        async () => {
          await Promise.all(children.map((c) => this.remove(c)));

          await database.events.where("sessionId").anyOf(sessionIds).delete();
          await database.activities
            .where("sessionId")
            .anyOf(sessionIds)
            .delete();

          await database.tasks.delete(taskId);

          await this.reorderTaskChildren(location, parentId);
        },
      );
    } catch (error) {
      this.taskById.set(taskId, task);
      throw error;
    }
  }

  public *resetZoneIdsForTasksInZone(zoneId: string) {
    const tasks = this.tasks.filter((t) => t.zoneId === zoneId);

    try {
      yield database.tasks.bulkUpdate(
        tasks.map((t) => ({
          key: t.id,
          changes: { zoneId: undefined },
        })),
      );
    } catch (error) {
      tasks.forEach((t) => t.update({ zoneId }));
      throw error;
    }
  }
}
