import { ReactNode, createContext, useContext, useState } from "react";

interface TagsContextProps<T = unknown> {
  value: T | null;
  setValue: (value: T) => void;
}

const TagsContext = createContext<TagsContextProps | undefined>(undefined);

interface TagsProviderProps<T> {
  children: ReactNode;
  initialValue?: T | null;
}

export function TagsProvider<T>({
  children,
  initialValue = null,
}: TagsProviderProps<T>) {
  const [value, setValue] = useState<T | null>(initialValue);

  return (
    <TagsContext.Provider value={{ value, setValue } as TagsContextProps}>
      {children}
    </TagsContext.Provider>
  );
}

export function useTagsContext<T>(): TagsContextProps<T> {
  const context = useContext(TagsContext);

  if (context === undefined) {
    throw new Error("useTagsContext must be used within a TagsProvider");
  }

  return context as TagsContextProps<T>;
}
