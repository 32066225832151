import { DateTime } from "luxon";

import { TimeMapEntity } from "shared/database";
import { roundMinutesToCeilingSlot } from "shared/libs/time";

import { getWeekdayName } from "./get-weekday-name";

const dayBoundariesCache = new Map<
  string,
  { start: DateTime; end: DateTime }
>();

export function getDayBoundaries(
  timeMap: TimeMapEntity,
  day: DateTime,
  now: DateTime,
) {
  const cacheKey = `${day.toISODate()}-${now.toISO()}`;
  if (dayBoundariesCache.has(cacheKey))
    return dayBoundariesCache.get(cacheKey)!;

  const dayTimeSlot = timeMap[getWeekdayName(day)];

  const dayBoundaries = {
    start: DateTime.max(
      now.startOf("minute").set({
        minute: roundMinutesToCeilingSlot(now.minute),
      }),
      day.plus({ minute: dayTimeSlot.startTimeSlot * 15 }),
    ),
    end:
      dayTimeSlot.endTimeSlot <= dayTimeSlot.startTimeSlot
        ? day.plus({ day: 1, minute: dayTimeSlot.endTimeSlot * 15 })
        : day.plus({ minute: dayTimeSlot.endTimeSlot * 15 }),
  };

  dayBoundariesCache.set(cacheKey, dayBoundaries);

  return dayBoundaries;
}
