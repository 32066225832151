import {
  Instruction,
  attachInstruction,
  extractInstruction,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DateTime } from "luxon";
import { runInAction } from "mobx";
import { useEffect, useState } from "react";

import { TaskModel } from "entities/tasks";

interface UseTaskDragProps {
  task: TaskModel;
  index: number[];
  isLast: boolean;
}

export function useTaskDrag({ task, index, isLast }: UseTaskDragProps) {
  const [lastDragOver, setLastDragOver] = useState<DateTime | null>(null);
  const [instruction, setInstruction] = useState<Instruction | null>(null);
  const [taskElement, setTaskElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!taskElement) return;

    return combine(
      draggable({
        element: taskElement,
        getInitialData: () => ({ type: "task", id: task.id, task }),
      }),
      dropTargetForElements({
        element: taskElement,
        getIsSticky: () => true,
        getData: ({ input, element }) =>
          runInAction(() =>
            attachInstruction(
              {},
              {
                input,
                element,
                currentLevel: index.length - 1,
                indentPerLevel: 0,
                mode: isLast
                  ? "last-in-group"
                  : task.isCollapsed
                    ? "standard"
                    : "expanded",
              },
            ),
          ),
        onDrag: ({ self }) => {
          setInstruction(extractInstruction(self.data));

          runInAction(() => {
            if (
              lastDragOver &&
              DateTime.now().diff(lastDragOver).milliseconds > 1000 &&
              task.isParent &&
              task.isCollapsed
            )
              void task.expand();
          });
        },
        onDragEnter: ({ self }) => {
          setInstruction(extractInstruction(self.data));
          setLastDragOver(DateTime.now());
        },
        onDragLeave: () => {
          setInstruction(null);
          setLastDragOver(null);
        },
        onDrop: ({ source }) => {
          runInAction(() => {
            const sourceTask = source.data.task as TaskModel;
            if (!sourceTask) return;

            const taskParent = task.parent ?? null;

            const taskSiblings = task.getSiblingsByLocation(task.location);

            const taskSiblingIndex = taskSiblings.findIndex(
              (t) => t.id === task.id,
            );

            const sourceTaskSiblingIndex = taskSiblings.findIndex(
              (t) => t.id === sourceTask.id,
            );

            const isSourceTaskEarlierThanTarget =
              sourceTaskSiblingIndex !== -1 &&
              sourceTaskSiblingIndex < taskSiblingIndex;

            switch (instruction?.type) {
              case undefined:
                break;
              case "make-child":
                if (task?.id === sourceTask.id) {
                  setInstruction(null);
                  return;
                }

                void sourceTask.move(task.location, task, 0);
                break;
              case "reorder-above":
                void sourceTask.move(
                  task.location,
                  taskParent,
                  taskSiblingIndex - (isSourceTaskEarlierThanTarget ? 1 : 0),
                );
                break;
              case "reorder-below":
                void sourceTask.move(
                  task.location,
                  taskParent,
                  taskSiblingIndex +
                    1 -
                    (isSourceTaskEarlierThanTarget ? 1 : 0),
                );
                break;
              default:
                throw new Error(
                  `Invalid instruction type ${instruction?.type}`,
                );
            }

            setInstruction(null);
            setLastDragOver(null);
          });
        },
      }),
    );
  }, [
    index.length,
    instruction,
    isLast,
    lastDragOver,
    task,
    task.isCollapsed,
    taskElement,
  ]);

  return { instruction, setTaskElement };
}
