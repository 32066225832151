import { Container, Factory } from "inversify";
import { configure } from "mobx";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "reflect-metadata";
import { useRegisterSW } from "virtual:pwa-register/react";

import { routes } from "app/routing";

import { Scheduler } from "features/scheduler";
import { TaskTreeStore } from "features/task-tree";

import { ActivitiesStore } from "entities/activities";
import { EventsStore } from "entities/events";
import { Notifications } from "entities/notifications/model/notifications";
import { TaskModel, TasksStore } from "entities/tasks";
import { TimeMapsStore } from "entities/time-maps";
import { ZonesStore } from "entities/zones";

import { TaskEntity } from "shared/database";
import { DiContext } from "shared/di";
import { useUpdateChecker } from "shared/libs/pwa";

import "./app.scss";

export function App() {
  useRegisterSW({ immediate: true });
  useUpdateChecker();

  configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
  });

  const di = new Container();
  di.bind(TasksStore).toSelf().inSingletonScope();
  di.bind<Factory<TaskModel, [TaskEntity]>>("Factory<TaskModel>").toFactory(
    (context) => (task: TaskEntity) =>
      new TaskModel(
        context.get(TasksStore),
        context.get(ZonesStore),
        context.get(EventsStore),
        context.get(ActivitiesStore),
        task,
      ),
  );
  di.bind(ZonesStore).toSelf().inSingletonScope();
  di.bind(EventsStore).toSelf().inSingletonScope();
  di.bind(ActivitiesStore).toSelf().inSingletonScope();
  di.bind(Scheduler).toSelf().inSingletonScope();
  di.bind(TaskTreeStore).toSelf().inTransientScope();
  di.bind(TimeMapsStore).toSelf().inSingletonScope();
  di.bind(Notifications).toSelf().inSingletonScope();

  const notifications = di.get(Notifications);
  void notifications.observe();

  return (
    <DiContext.Provider value={di}>
      <RouterProvider router={createBrowserRouter(routes)} />
    </DiContext.Provider>
  );
}
