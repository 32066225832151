import { DateTime } from "luxon";
import { Valid } from "luxon/src/_util";
import { prop, sortedIndexBy, sortedIndexWith } from "remeda";

import { getDayFromDatetime } from "entities/zones";

import { Session, TimeMapEntity } from "shared/database";

import { TimeGridEvent } from "./time-grid-event";

export class EventsStore {
  private events: Map<string, TimeGridEvent[]> = new Map();
  private sessionEvents: Map<string, TimeGridEvent> = new Map();

  public constructor(private readonly timeMap: TimeMapEntity) {}

  public add(event: TimeGridEvent) {
    const day = getDayFromDatetime(this.timeMap, event.startDate);

    if (!this.events.has(day.toISODate()!))
      this.events.set(day.toISODate()!, []);

    const index = sortedIndexBy(
      this.events.get(day.toISODate()!)!,
      event,
      prop("startDate"),
    );
    this.events.get(day.toISODate()!)!.splice(index, 0, event);
    this.sessionEvents.set(event.sessionId, event);
  }

  public addEvents(events: TimeGridEvent[]) {
    for (const event of events) this.add(event);
  }

  public getAll() {
    return Array.from(this.events.values()).flat();
  }

  public getForDay(day: DateTime<Valid>) {
    return this.events.get(day.toISODate()!) ?? [];
  }

  public hasScheduledSession(session: Session) {
    return this.sessionEvents.has(session.id);
  }

  public removeForSession(session: Session) {
    const sessionEvent = this.sessionEvents.get(session.id);
    if (!sessionEvent)
      throw new Error(`Session event not found for session ${session.id}`);

    const day = getDayFromDatetime(this.timeMap, sessionEvent.startDate);

    if (!this.events.has(day.toISODate()!))
      throw new Error(`Events not found for day ${day.toISODate()}`);

    const events = this.events.get(day.toISODate()!)!;

    const index = sortedIndexWith(
      events,
      (e) => e.startDate < sessionEvent.startDate,
    );

    if (index === events.length || events[index].sessionId !== session.id)
      throw new Error(
        `Session event not found in day ${day.toISODate()} for session ${session.id}`,
      );

    this.sessionEvents.delete(session.id);
    const [event] = events.splice(index, 1);

    return event;
  }
}
