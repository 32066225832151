import { useClickOutside, usePrevious } from "@react-hookz/web";
import { clsx } from "clsx";
import { Transition, motion } from "framer-motion";
import mergeRefs from "merge-refs";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactNode, forwardRef, useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./task-details.module.scss";

import { TaskTreeStore } from "../../model";

interface Props {
  store: TaskTreeStore;
  isSelected: boolean;
  isEditing?: boolean;
  onEditToggle?: () => void;
  onSelect: () => void;
  onDeselect: () => void;
  isDragging: boolean;
  className?: string;
  onDelete: () => void;
  children: ReactNode;
}

export const TaskDetails = observer(
  forwardRef<HTMLDivElement, Props>(function TaskDetails(
    {
      store,
      isSelected,
      isEditing,
      onEditToggle,
      onSelect,
      onDeselect,
      isDragging,
      className,
      onDelete,
      children,
    }: Props,
    forwardedRef,
  ) {
    const prevIsEditing = usePrevious(isEditing);

    const ref = useRef<HTMLDivElement>(null);

    useClickOutside(ref, () => isEditing && onEditToggle?.());

    useEffect(() => {
      if (isSelected && !isEditing) ref.current?.focus();
    }, [isSelected, isEditing]);

    const deleteHotkeyRef = useHotkeys<HTMLDivElement>(
      ["Delete", "Backspace"],
      onDelete,
      {
        preventDefault: true,
      },
    );

    const transition: Transition = {
      duration: 0.4,
      ease: "circOut",
      backgroundColor: {
        ease: "circOut",
        duration: prevIsEditing !== isEditing ? 0.4 : 0,
      },
    };

    const currentVariant = isEditing
      ? "editing"
      : isSelected
        ? "selected"
        : "default";

    return (
      <motion.div
        ref={mergeRefs(ref, forwardedRef, deleteHotkeyRef)}
        tabIndex={0}
        layout="position"
        style={{ borderRadius: 5 }}
        className={styles.details}
        initial={store.isTaskNew ? "default" : false}
        animate={currentVariant}
        variants={{
          default: {
            backgroundColor: "var(--color-background)",
            margin: 0,
            boxShadow: "none",
          },
          selected: {
            backgroundColor: "var(--color-selection)",
            margin: 0,
            boxShadow: "none",
          },
          editing: {
            backgroundColor: "var(--color-background)",
            margin: "24px -6px",
            boxShadow: "var(--color-shadow) 0 0 5px 1px",
          },
        }}
        transition={transition}
        onFocus={() => runInAction(onSelect)}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget as Node))
            runInAction(onDeselect);
        }}
        onDoubleClick={() => !isEditing && onEditToggle?.()}
        onAnimationComplete={() => {
          if (isSelected && !isDragging)
            ref.current?.scrollIntoView({
              block: "nearest",
              behavior: "instant",
            });
        }}
        onLayoutAnimationComplete={() => {
          if (isSelected && !isDragging)
            ref.current?.scrollIntoView({
              block: "nearest",
              behavior: "instant",
            });
        }}
      >
        <motion.div
          transition={transition}
          variants={{
            default: { margin: 0 },
            selected: { margin: 0 },
            editing: { margin: "12px 6px 12px" },
          }}
          className={clsx(styles.children, className)}
        >
          {children}
        </motion.div>
      </motion.div>
    );
  }),
);
