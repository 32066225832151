import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";

import { useActivityColor } from "entities/activities";
import { ActivityModel } from "entities/activities";

import { getColor } from "shared/colors";
import { Popover } from "shared/ui/popover";

import styles from "./activity.module.scss";

import { ActivityPopup } from "./activity-popup";

interface Props {
  day: DateTime;
  activity: ActivityModel;
  height: number;
}

export const Activity = observer(function Activity({
  activity,
  day,
  height,
}: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const activityRef = useRef<HTMLDivElement>(null);

  const todayStartDateTime = DateTime.max(
    activity.startDateTime,
    day.startOf("day"),
  );

  const startSecondOfTheDay = todayStartDateTime.diff(day, "seconds").seconds;

  const duration = activity.getActualDurationInDay(day).as("seconds");
  const overtimeDuration =
    "overtimeDuration" in activity
      ? activity.overtimeDuration.as("seconds")
      : 0;

  const activityHeight = (duration / 60 / 60 / 24) * height;

  const color = useActivityColor({ activity });

  const redColor = getColor("red");

  const background = `linear-gradient(
    to bottom,
    ${color.clone().setAlpha(0.8).toHex8String()} 0%,
    ${color.clone().setAlpha(0.8).toHex8String()} ${((duration - overtimeDuration) / duration) * 100}%,
    ${redColor.clone().setAlpha(0.8).toHex8String()} ${((duration - overtimeDuration) / duration) * 100}%,
    ${redColor.clone().setAlpha(0.8).toHex8String()} 100%)`;

  const borderColor = `linear-gradient(
    to bottom,
    ${color.toHex8String()} 0%,
    ${color.toHex8String()} ${((duration - overtimeDuration) / duration) * 100}%,
    ${redColor.toHex8String()} ${((duration - overtimeDuration) / duration) * 100}%,
    ${redColor.toHex8String()} 100%)`;

  if (activity.type === "away") return null;

  return (
    <div
      ref={activityRef}
      className={styles.activity}
      style={{
        top: `${(startSecondOfTheDay / 60 / 60 / 24) * height}px`,
        height: `${activityHeight}px`,
        left: 0,
        width: "100%",
      }}
    >
      <div
        className={styles.container}
        style={{
          background: background,
          color: color.clone().monochromatic(10)[5].toHexString(),
          borderColor: color.toHexString(),
          borderLeft: `4px solid ${color.toHexString()}`,
        }}
        onClick={() => setIsPopoverOpen(true)}
      >
        <div className={styles.title}>
          {activityHeight > 20 && (
            <span title={activity.name}>{activity.name}</span>
          )}
        </div>
      </div>
      <div className={styles.border}>
        <div
          className={styles.borderInner}
          style={{
            background: borderColor,
          }}
        />
      </div>
      <Popover
        targetElement={activityRef.current}
        isOpen={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        className={styles.activityPopover}
      >
        <ActivityPopup activity={activity} />
      </Popover>
    </div>
  );
});
