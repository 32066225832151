import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";

import styles from "./current-time-day-bar.module.scss";

import { estimateCurrentTimeBarHeight } from "../../model";

interface CurrentTimeDayBarProps {
  height: number;
}

export const CurrentTimeDayBar = observer(function CurrentTimeDayBar({
  height,
}: CurrentTimeDayBarProps) {
  const currentHeight = estimateCurrentTimeBarHeight(height);

  return <motion.div className={styles.dayNow} style={{ y: currentHeight }} />;
});
