import { DateTime } from "luxon";

export function getWeekdayName(day: DateTime) {
  const weekDay = day.weekday;
  switch (weekDay) {
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    case 7:
      return "sunday";
    default:
      throw new Error(`Invalid weekday: ${weekDay}`);
  }
}
