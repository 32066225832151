import { AnimatePresence, motion } from "framer-motion";
import ls from "localstorage-slim";
import { flowResult } from "mobx";
import { useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  BsClockHistory,
  BsFillPieChartFill,
  BsInbox,
  BsListNested,
  BsListTask,
  BsPlayCircle,
  BsSortUp,
} from "react-icons/bs";
import { LiaUtensilsSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

import { Calendar } from "features/calendar";
import { ExecutionPanel } from "features/execution";
import { InboxPanel } from "features/inbox";
import { Outline } from "features/outline";
import { Priorities } from "features/priorities";
import { ScheduleButton } from "features/scheduler";
import { SuggestionsButton } from "features/suggestions";
import { TimeMaps } from "features/time-maps";
import { Zones } from "features/zones";

import { ActivitiesStore } from "entities/activities";
import { EventsStore } from "entities/events";
import {
  ExportButton,
  ImportButton,
  QuickAddButton,
  TasksStore,
} from "entities/tasks";
import { TimeMapsStore } from "entities/time-maps";
import { ZonesStore } from "entities/zones";

import { useDi } from "shared/di";
import { routePaths } from "shared/router";
import { ControlPanel, Layout } from "shared/ui";

import styles from "./tasks-page.module.scss";

export enum PanelType {
  INBOX = "inbox",
  OUTLINE = "outline",
  PRIORITIES = "priorities",
  ZONES = "zones",
  TIME_MAPS = "time-maps",
  EXECUTION = "execution",
}

export function TasksPage() {
  const navigate = useNavigate();
  const di = useDi();

  const tasksStore = di.get(TasksStore);
  const timeMapsStore = di.get(TimeMapsStore);
  const zonesStore = di.get(ZonesStore);
  const eventsStore = di.get(EventsStore);
  const activitiesStore = di.get(ActivitiesStore);

  useEffect(() => {
    async function load() {
      await flowResult(timeMapsStore.loadTimeMaps());
      await flowResult(zonesStore.loadZones());
      await flowResult(tasksStore.loadTasks());
      await flowResult(activitiesStore.loadActivities());
      await flowResult(eventsStore.loadEvents());
    }

    load();
  }, []);

  const [activePanel, setActivePanel] = useState<PanelType>(
    () => ls.get<PanelType>("tasks-page:active-panel") ?? PanelType.OUTLINE,
  );
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>();
  const [calendarMode, setCalendarMode] = useState<"events" | "activities">(
    "events",
  );

  function showTaskInOutline(taskId: string) {
    setSelectedTaskId(taskId);
    handlePanelChange(PanelType.OUTLINE);
  }

  const currentPanel = useMemo(() => {
    switch (activePanel) {
      case PanelType.INBOX:
        setCalendarMode("events");
        return {
          title: "Входящее",
          component: <InboxPanel />,
        };
      case PanelType.PRIORITIES:
        setCalendarMode("events");
        return {
          title: "Приоритезация",
          component: <Priorities />,
        };
      case PanelType.ZONES:
        setCalendarMode("events");
        return {
          title: "Зоны",
          component: <Zones />,
        };
      case PanelType.TIME_MAPS:
        setCalendarMode("events");
        return {
          title: "Временные карты",
          component: <TimeMaps />,
        };
      case PanelType.EXECUTION:
        setCalendarMode("activities");
        return {
          title: "Исполнение",
          component: <ExecutionPanel onShowTaskInOutline={showTaskInOutline} />,
        };
      case PanelType.OUTLINE:
      default:
        setCalendarMode("events");
        return {
          title: "Планирование",
          component: (
            <Outline
              taskIdToSelect={selectedTaskId}
              onTaskSelected={() => setSelectedTaskId(undefined)}
            />
          ),
        };
    }
  }, [activePanel, selectedTaskId]);

  useHotkeys("Meta+Shift+Y", () => tasksStore.journalCompleted());

  const handlePanelChange = (panel: PanelType) => {
    setActivePanel(panel);
    ls.set("tasks-page:active-panel", panel);
  };

  return (
    <div>
      <Layout>
        <Calendar mode={calendarMode} onShowTaskInOutline={showTaskInOutline} />
        <div className={styles.panel}>
          <div className={styles.header}>
            <AnimatePresence mode="wait">
              <motion.div
                key={currentPanel.title}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.1 }}
              >
                {currentPanel.title}
              </motion.div>
            </AnimatePresence>
          </div>
          {currentPanel.component}
        </div>
      </Layout>
      <ControlPanel>
        <ControlPanel.Button
          onClick={() => handlePanelChange(PanelType.EXECUTION)}
          icon={BsPlayCircle}
          label="Исполнение"
          hotkey="Meta+1"
        />
        <ControlPanel.Button
          onClick={() => handlePanelChange(PanelType.OUTLINE)}
          icon={BsListNested}
          label="План"
          hotkey="Meta+2"
        />
        <ControlPanel.Button
          onClick={() => handlePanelChange(PanelType.PRIORITIES)}
          icon={BsSortUp}
          label="Приоритеты"
          hotkey="Meta+3"
        />
        <ControlPanel.Button
          onClick={() => handlePanelChange(PanelType.INBOX)}
          icon={BsInbox}
          label="Входящее"
          hotkey="Meta+4"
        />
        <ControlPanel.Button
          onClick={() => handlePanelChange(PanelType.TIME_MAPS)}
          icon={BsClockHistory}
          label="Временные карты"
          hotkey="Meta+5"
        />
        <ControlPanel.Button
          onClick={() => handlePanelChange(PanelType.ZONES)}
          icon={BsFillPieChartFill}
          label="Зоны"
          hotkey="Meta+6"
        />
        <ScheduleButton />
        <QuickAddButton />
        <ControlPanel.Divider />
        <SuggestionsButton
          onShowTaskFromSuggestions={(taskId) => {
            setSelectedTaskId(taskId);
            handlePanelChange(PanelType.OUTLINE);
          }}
        />
        <ControlPanel.Divider />
        <ExportButton />
        <ImportButton />
        <ControlPanel.Divider />
        <ControlPanel.Button
          icon={BsListTask}
          onClick={() => navigate(routePaths.TASKS)}
          label="Задачи"
        />
        <ControlPanel.Button
          icon={LiaUtensilsSolid}
          onClick={() => navigate(routePaths.MEALS)}
          hotkey="Meta+M"
          label="Питание"
        />
      </ControlPanel>
    </div>
  );
}
