import { clsx } from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./icon-button.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "danger" | "secondary" | "warning";
  onClick: () => void;
  hotkey?: string;
}

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  function IconButton(
    { className, variant = "primary", hotkey, onClick, ...props },
    ref,
  ) {
    useHotkeys(hotkey ?? "", onClick, {
      preventDefault: true,
      enabled: !!hotkey,
    });

    return (
      <button
        ref={ref}
        className={clsx(styles.iconButton, styles[variant], className)}
        title={hotkey ? `${props.title || ""} (${hotkey})` : props.title}
        onClick={onClick}
        {...props}
      />
    );
  },
);
