import { DateTime } from "luxon";
import { now } from "mobx-utils";

export function estimateCurrentTimeBarHeight(height: number) {
  const nowDateTime = DateTime.fromMillis(now());

  const currentMinuteOfTheDay = nowDateTime.diff(
    nowDateTime.startOf("day"),
    "minutes",
  ).minutes;

  return (currentMinuteOfTheDay / 60 / 24) * height;
}
